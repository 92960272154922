import React, {useState} from 'react';
import {Button} from "antd";
import {DeleteOutlined, UploadOutlined} from "@ant-design/icons";

import './TabUpdateFile.scss';
import {convertToBase64} from "../../../../../../utils";

const TabUpdateFile = ({
 t,
 updateFile,
 user,
 value,
 folderId,
 getFolderFiles
}) => {

  const [uploadFile, setUploadFile] = useState(null)

  const handlerUpdateFiles = async () => {
    const convertedFile = await convertToBase64(uploadFile);

    updateFile({
      name: uploadFile?.name,
      fileId: value.key,
      file: convertedFile,
      folderId: folderId,
      author: user?.data?.display_name,
    }).then(() => getFolderFiles({ folderId }));
  }

  const handlerAddFiles = (e) => {
    setUploadFile(e.target.files?.[0]);
  }

  return (
    <div className={'file__update'}>
      <p>{t('Bestandsnaam')}</p>
      <div className={'upload'}>
        <input
          type="file"
          multiple={true}
          onChange={handlerAddFiles}
          className={'file__input'}
        />
        <div className={'file__upload--block'}>
          <UploadOutlined/>
          {t('upload_file')}
        </div>
      </div>

      <div className={'action__block'}>
        <div className={'file__info'}>
          <p>{uploadFile?.name || ''}</p>
          {uploadFile && <DeleteOutlined onClick={() => setUploadFile(null)} />}
        </div>
        <Button
          type="primary"
          onClick={handlerUpdateFiles}
          disabled={!uploadFile}
        >{t('export_button')}</Button>
      </div>
    </div>
  );
};

export default TabUpdateFile;