import React, {useEffect, useMemo, useState} from "react";
import {message, Radio, Select, Tabs, Modal} from "antd";
import {withTranslation} from 'react-i18next'

import "./ComparisonGroupBuilder.scss";
import {Button} from "reactstrap";
import {api} from "../../services";
import OrganisationGroupDropdown from "../../components/Organisations/OrganisationGroupDropdown/OrganisationGroupDropdown";
import SelectComparisonGroup from "./components/SelectComparisonGroup";
import {ModalComparisonGroup} from "../../components";

const ComparisonGroupBuilder = ({
  t,
  pageId,
  staticPage,
  allOrganization,
  getBenchmarkOrganization,
  onChangeSelect,
  benchmarkForms,
  multiple=false
}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [comparisonGroup, setComparisonGroup] = useState(null);
  const [modalEdit, setModalEdit] = useState(false);
  const [comparisonGroupId, setComparisonGroupId] = useState(null)
  const [editGroupName, setEditGroupName] = useState(null);
  const [defaultActiveGroup, setDefaultActiveGroup] = useState(null);
  const [organizationsInEditGroup, setOrganizationsInEditGroup] = useState(null);

  useEffect(() => {
    if (!allOrganization) {
      getBenchmarkOrganization();
    }
    if (!staticPage) {
      api.report_blocks.getReportBlockById(pageId)
        .then((response) => {
          const { data, status } = response;

          if (status === 200) {
            setDefaultActiveGroup(data?.node?.default_comparison_group);
            onChangeSelect(multiple ? [data?.node?.default_comparison_group] : data?.node?.default_comparison_group);
          }
        })
    } else {
      api.static_pages.getStaticPageById(pageId)
        .then((response) => {
          const { data, status } = response;

          if (status === 200) {
            setDefaultActiveGroup(data?.node?.default_comparison_group);
            onChangeSelect(multiple ? [data?.node?.default_comparison_group] : data?.node?.default_comparison_group);
          }
        })
    }
  }, [isOpen]);

  useEffect(() => {
    getComparisonGroups();
  }, []);

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
    setModalEdit(false);
    setEditGroupName(null);
    setOrganizationsInEditGroup(null);
  }

  const getComparisonGroups = () => {
    api.benchmark.getComparisonGroups()
      .then(({ data, status }) => {
        if (status < 300) {
          setComparisonGroup(data.comparison_groups);
        }
      });
  }

  const handleActionWithGroup = () => {
    getComparisonGroups();
  }

  const handleModalEdit = (item) => {
    setOrganizationsInEditGroup(item.organizations);
    setIsOpen(true);
    setModalEdit(true);
    setEditGroupName(item.name);
    setComparisonGroupId(item.id);
  }

  const selectGroups = (ids) => {
    onChangeSelect(ids)
  }

  const selectGroup = (id) => {
    onChangeSelect(id)
  }

  return (
    <>
      <OrganisationGroupDropdown
        availableGroups={comparisonGroup}
        defaultGroup={defaultActiveGroup}
        handleAfterComparisonGroupDeleted={handleActionWithGroup}
        handleComparisonGroupEditClicked={handleModalEdit}
        handleSelectedComparisonGroupsChanged={multiple ? selectGroups : selectGroup}
        multiple={multiple}
      />
      <Button
        color='blue'
        size='sm'
        style={{ padding: '11px 12px' }}
        onClick={openModal}
      >{t('Vergelijkingsgroep aanmaken')}</Button>
      <Modal
        style={{top: 0, maxWidth: '100vw'}}
        width={'100%'}
        footer={null}
        open={isOpen}
        onCancel={closeModal}
        title={modalEdit ? `${t('edit_group')} ${editGroupName}` : t('create_new_group')}
      >
        <ModalComparisonGroup
          visible={isOpen}
          modalEdit={editGroupName}
          closeModal={closeModal}
          comparisonGroupEditId={comparisonGroupId}
          reportPage={true}
          organizationsInEditGroup={organizationsInEditGroup}
          handleActionWithGroup={handleActionWithGroup}
          benchmarkForms={benchmarkForms}
        />
      </Modal>
    </>
  );
}

export default withTranslation('benchmark')(ComparisonGroupBuilder);
