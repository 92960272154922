import React, {useEffect, useState} from "react";

import Highcharts from "highcharts/highmaps";
import HighchartsReact from "highcharts-react-official";
import {api} from "../../../../../../services";
import {Spin} from "antd";

const Kart = ({
  mapFile,
  t,
  getBenchmarkMap,
  startDate,
  endDate,
  settingsBenchmark,
  indicator,
  activeOrganization,
  drillDownGroups: groups,
  modalVisible,
  mapData,
  isFetching,
  mapOrgData
}) => {

  const [mapDataIE, setMapDataIE] = useState(null);
  const [options, setOptions] = useState(null);
  const [dataForOptions, setDataForOptions] = useState(null);
  const [maxValue, setMaxValue] = useState('');
  const [minValue, setMinValue] = useState('');

  useEffect(() => {
    if (mapFile) {
      api.benchmark.getMapFile(mapFile)
        .then((response) => {
          const { status, data } = response;

          if (status === 200) {
            setMapDataIE(data);
          }
        })
        .catch((e) => console.error(e));
    }
  }, [])

  useEffect( () => {
    setDataForOptions(null);
    if (modalVisible) {
      getBenchmarkMap({
        batch: true,
        indicator,
        groups: groups,
        organization: activeOrganization?.id,
        period_start: startDate,
        period_end: endDate,
        calculation_types: Object.keys(settingsBenchmark?.extra_columns),
      })
    }
  }, [indicator, modalVisible])

  const sortOptionsData = () => {
    const valueData = [];
    const data = mapData?.full?.filter(item => {

      if (item.countryCode) {
        let result = 0;
        let validResult = true;

        if (item.result == 0)
          result = '';
        else if (item.result.split('.').length > 1) 
          result = Number(item.result.split('.')?.join('')?.split(',')?.join('.'));
        else if (item.result.split(',').length > 1)
          result = Number(item.result?.split(',')?.join('.')) === 0
            ? ''
            : Number(item.result?.split(',')?.join('.'));
        else if (Number(item.result))
          result = item.result;
        else 
          validResult = false;

        valueData.push(result);
        item.value = item?.result ? result : '';
        return validResult && item;
      }
    })
    const max = Math.max(...valueData);
    const min = Math.min(...valueData);
    setMaxValue(max);
    setMinValue(min);
    setDataForOptions(data);
  }

  useEffect(() => {
    if (mapData) {
      sortOptionsData();
    }
  }, [mapData])

  useEffect(() => {
    if (mapDataIE && dataForOptions) {
      const mapOptions = {
        chart: {
          height: '700'
        },
        title: {
          text: ''
        },
        // credits: {
        //   enabled: false
        // },
        mapNavigation: {
          enabled: true
        },
        colorAxis: {
          min: !!minValue ? minValue : null,
          max: maxValue,
          type: 'logarithmic',
          minColor: '#efecf3',
          maxColor: '#FD6420',
        },
        tooltip: {
          useHTML: true,
          pointFormatter: function() {
            const currentItems = dataForOptions.filter(item => item.countryCode === this.countryCode)
            const renderItems = () => {
              let str = '';
              const sortItems = currentItems?.forEach(item => str += `<p>${item.orgName} - ${item.result}</br></p>`);
              return str;
            }

            return `
              <div>
                <p>${this.properties.gemeentenaam}</p>
                ${renderItems()}
              </div>
            `
          } ,
        },
        series: [{
          data: dataForOptions,
          mapData: mapDataIE,
          // name: 'Population density',
          key: ['code', 'countryCode'],
          joinBy: ['code', 'countryCode'],
          allowPointSelect: true,
          cursor: 'pointer',
          events: {
            click: function (e) {
              e.point.zoomTo();
            }
          },
          states: {
            hover: {
              color: '#a4edba'
            }
          },
        }]
      }
      setOptions(mapOptions);
    }
  }, [mapDataIE, dataForOptions, indicator])

  return (
    <div>
      <div className={'tab__header'}>
        <div className={'header__info'}>
          <div className={'info'}>
            <p className={'text'}>{t('your_value')}</p>
            <p className={'value'}>{mapOrgData || '-'}</p>
          </div>
          <div className={'info'}>
            <p className={'text'}>{t('number_of_values')}:</p>
            <p className={'value'}>{dataForOptions?.length || '-'}</p>
          </div>
        </div>
      </div>
      {
        isFetching
        ? <div className={'loader__block'}>
            <Spin className={'loader'} />
          </div>
        : <HighchartsReact
            constructorType={'mapChart'}
            highcharts={Highcharts}
            options={options}
          />
      }

    </div>

  )
};

export default Kart;
