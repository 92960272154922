import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import thunk from 'redux-thunk'
import logger from 'redux-logger'

import rootReducer from './reducers'

const middlewars = [thunk]

if (process.env.NODE_ENV === 'development') {
  middlewars.push(logger)
}

const composeEnhancers = composeWithDevTools({
})

export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(...middlewars)
  )
)
