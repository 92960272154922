import { connect } from 'react-redux';
import Charts from './Charts';
import actions from "../../../../../../store/actions";

const mapStateToProps = (state) => ({
  settingsBenchmark: state.benchmark.benchmarkSettings,
  isFetching: state.benchmark.isFetching,
});

const mapDispatchToProps = (dispatch) => ({
  getBenchmarkChartData: (params) => dispatch(actions.benchmark.getBenchmarkChartData(params)),
})


export default connect(mapStateToProps, mapDispatchToProps)(Charts)

