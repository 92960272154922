import React from "react";

import './ModalEdit.scss';
import {Tabs} from "antd";
import {withTranslation} from "react-i18next";
import {TabHistory, TabUpdateFile} from "./Tabs";

const { TabPane } = Tabs;

const ModalEdit = ({
 t,
 folderId,
 value,
}) => {

  return (
    <div className={'modal__edit'}>
      <Tabs defaultActiveKey={'edit'}>
        <TabPane tab={t('edit')} key={'edit'}>
          <TabUpdateFile t={t} value={value} folderId={folderId} />
        </TabPane>
        <TabPane tab={t('history')} key={'history'}>
          <TabHistory t={t} value={value} folderId={folderId} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default withTranslation('file_manager')(ModalEdit);