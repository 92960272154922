import React from "react";

export default ({ color }) => {
  return (
      color !== 'blue'
      ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="12.314" height="10.573" viewBox="0 0 12.314 10.573">
            <g id="streamline-icon-camera-settings-focus_140x140" data-name="streamline-icon-camera-settings-focus@140x140" transform="translate(-31.582 -28.667)">
              <path id="Path_226" data-name="Path 226" d="M34.694,30.907a.925.925,0,0,0,.681-.359l.7-1.022a.923.923,0,0,1,.681-.359h1.977a.923.923,0,0,1,.68.359l.7,1.022a.925.925,0,0,0,.681.359h1.305A1.454,1.454,0,0,1,43.4,32.371v5.063a1.305,1.305,0,0,1-1.305,1.305h-8.7a1.305,1.305,0,0,1-1.305-1.305V32.371a1.454,1.454,0,0,1,1.305-1.464Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
              <path id="Path_227" data-name="Path 227" d="M55.417,54.676A2.176,2.176,0,1,0,57.592,52.5,2.176,2.176,0,0,0,55.417,54.676Z" transform="translate(-19.853 -19.852)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
            </g>
          </svg>
        )
      : (
          <svg xmlns="http://www.w3.org/2000/svg" width="16.41" height="14.039" viewBox="0 0 16.41 14.039">
            <g id="streamline-icon-camera-settings-focus_140x140" data-name="streamline-icon-camera-settings-focus@140x140" transform="translate(-31.582 -28.667)">
              <path id="Path_226" data-name="Path 226" d="M35.639,31.537a1.26,1.26,0,0,0,.927-.49l.948-1.392a1.258,1.258,0,0,1,.927-.49h2.693a1.258,1.258,0,0,1,.926.49l.948,1.392a1.26,1.26,0,0,0,.928.49h1.778a1.981,1.981,0,0,1,1.778,1.994v6.9a1.778,1.778,0,0,1-1.778,1.778H33.861a1.778,1.778,0,0,1-1.778-1.778v-6.9a1.981,1.981,0,0,1,1.778-1.994Z" transform="translate(0 0)" fill="none" stroke="#1e3968" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
              <path id="Path_227" data-name="Path 227" d="M55.417,55.463A2.963,2.963,0,1,0,58.38,52.5,2.963,2.963,0,0,0,55.417,55.463Z" transform="translate(-18.593 -18.592)" fill="none" stroke="#1e3968" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
            </g>
          </svg>
        )

  )
}