import { sortByField } from "../../../views/ReportBenchmark/utils";
import "./IndividualOrganisationDropdown.scss";
import { DownOutlined } from "@ant-design/icons";
import { Checkbox, message, Popover, Tooltip } from "antd";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';


export default function IndividualOrganisationDropdown({availableOrganisations, handleSelectedOrganisationsChanged}) {
  const[organisations, setOrganisations] = useState([]);
  const[popoverVisible, setPopoverVisible] = useState(false);
  const {t, i18n} = useTranslation('individual_organisations');

  const popoverContent = (
    <div className={"individual-organisation-dropdown_popover-content-wrapper"}>
      <div className={"individual-organisation-dropdown_popover-content"}>
        <p className={"individual-organisation-dropdown_popover-subtitle"}>
          {t('individual_organizations_subtitle')}
        </p>
        <div className={"individual-organisation-dropdown_popover-checkboxes"}>
          <Checkbox.Group value={organisations}>
            {sortOrganisations()?.map((item) => (
                <Tooltip placement="left" title={item.title} zIndex={20}>
                  <Checkbox
                    onChange={handleSelectOrganisation}
                    value={item.id}
                    key={item.id}>
                    {item.title}
                  </Checkbox>
                </Tooltip>
              ))}
          </Checkbox.Group>
        </div>
        <button className={"individual-organisation-dropdown_popover-action-button"} onClick={handleApplyIndividualOrganisations}>
          {t('apply')}
        </button>
      </div>
    </div>
  );

  function handleSelectOrganisation(e) {
    const selectedOrganisations = [...organisations];
    const isOrganisationSelected = selectedOrganisations?.find((item) => item === e.target.value);

    if (isOrganisationSelected) {
      const newArray = selectedOrganisations.filter((item) => item !== e.target.value);
      setOrganisations(newArray);
    } 
    else {
      selectedOrganisations.push(e.target.value);
      setOrganisations(selectedOrganisations);
    }
  };

  function handleApplyIndividualOrganisations() {
    const selectedOrganisations = [];
    const sortOutData = organisations?.forEach((item) =>
      availableOrganisations?.filter((value) => {
        if (value.id === item)
          selectedOrganisations.push(value);
      })
    );

    setPopoverVisible(false);
    handleSelectedOrganisationsChanged(selectedOrganisations);
    message.success(`Filter ${t('apply')}!`);
  }

  function sortOrganisations() {
    return availableOrganisations?.sort((x, y) => x.title.localeCompare(y.title));
  };

  return (
    <>
      <Popover
        className={"individual-organisation-dropdown_popover"}
        content={popoverContent}
        onOpenChange={setPopoverVisible}
        open={popoverVisible}
        placement="bottomLeft"
        trigger={"click"}>
        <div className={"individual-organisation-dropdown_outside-wrapper"}>
          {t("select_individual_organizations")}
          <DownOutlined />
        </div>
      </Popover>
    </>
  )
}