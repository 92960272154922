import React from "react";
import {withTranslation} from "react-i18next";
import {Spin} from "antd";
import LeftOutlined from "@ant-design/icons/lib/icons/LeftOutlined";

import './RulesPage.scss';
import history from "../../../../history";

const RulesPage = ({
  t,
  isFetching,
  uploadDescription,
}) => {

  return (
    isFetching
    ? <Spin
        style={{
          paddingTop: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        size={'large'}
      />
    : (
      <div className={'rules__page'}>
        <div className={'rules__header'} onClick={() => history.goBack()}>
          <LeftOutlined />
          <p>{t('back_to_prev')}</p>
        </div>
        {uploadDescription?.value && (
          <div
            className={'rule__content'}
            dangerouslySetInnerHTML={{__html:`${uploadDescription?.value}`}}
          />
        )}
      </div>
    )
  );
};

export default withTranslation('file_manager')(RulesPage);