import {connect} from "react-redux";

import EditPersonalAccount from './EditPersonalAccount'
import actions from '../../../../store/actions';

const mapStateToProps = state => ({
  isFetching: state.profile.isFetching,
  personalUserInfo: state.user.data?.data,
})

const mapDispatchToProps = (dispatch) => ({
  updatePersonalUserInfo: (data) => dispatch(actions.profile.updatePersonalUserInfo(data)),
  changeUserPassword: (data) => dispatch(actions.profile.changeUserPassword(data)),
  getUser: () => dispatch(actions.user.getUser()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPersonalAccount)




