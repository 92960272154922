import http from './http'

const getReportsByWebformId = webformId => {
  return http({
    url: `/api/v1/webform/${webformId}/reports`,
    method: 'GET'
  })
}

const getReports = () => {
  return http({
    url: '/api/v1/report/list?_format=json',
    method: 'GET'
  })
}

const getFullReports = () => {
  return http({
    url: '/api/v1/report/full-list?_format=json',
    method: 'GET'
  })
}

const getReportById = reportId => {
  return http({
    url: `/api/v1/report/${reportId}?_format=json`,
    method: 'GET'
  })
}

export {
  getReportsByWebformId,
  getReports,
  getFullReports,
  getReportById
}
