import React, { Component } from 'react'

/**
 * Renders the preloader
 */
class Loader extends Component {
  render () {
    return (
      <div className='preloader'>
        <div className='status'>
          <div className='spinner-border avatar-sm text-primary m-2' role='status' />
        </div>
      </div>
    )
  }
}

export default Loader
