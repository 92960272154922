import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { withTranslation } from "react-i18next";

function SelectSegmentationFilter(props) {
  const { filterChanged, filterState, item, popoverId, t } = props;

  const onSelectChanged = (values) => {
    filterChanged({
      ...filterState,
      searchValues: values
    });
  }

  return (
    <>
      <Select
        allowClear={true}
        getPopupContainer={() => document.getElementById(popoverId)}
        mode='multiple'
        onChange={onSelectChanged}
        placeholder={t("select")}
        value={filterState.searchValues}
      >
        {Object.entries(item.options).map((option, index) => (
          <Select.Option value={option[0]} key={index}>
            {option[1]}
          </Select.Option>
        ))}
      </Select>
    </>
  );
}

export default withTranslation("benchmark")(SelectSegmentationFilter);
