import React, { useEffect, useMemo, useState } from "react";
import {withTranslation} from "react-i18next";
import { Checkbox, Input, message, Select, Switch, Popover, Spin, Tooltip } from "antd";
import ArrowLeftOutlined from "@ant-design/icons/lib/icons/ArrowLeftOutlined";
import './ModalComparisonGroup.scss';
import {api} from "../../services";
import {NoRecords} from "../index";
import {sortByField} from "../../views/ReportBenchmark/utils";
import SegmentationFilters from "../../views/ReportBenchmark/components/SegmentationFilters";
import { useParams } from "react-router-dom";

const { Option } = Select;
const { Search } = Input;

const ModalComparisonGroup = ({
  modalEdit,
  t,
  organization,
  closeModal,
  getBenchmarkComparisonGroups,
  getFilteredOrganizationForBenchmark,
  comparisonGroupEditId,
  settingsBenchmark,
  indicatorFilterData,
  visible,
  appSettings,
  searchByAllOrganizations,
  isSearchLoader,
  reportPage = false,
  benchmarkForms,
  handleActionWithGroup,
  activeOrganization,
  organizationsInEditGroup,
}) => {
  const { id } = useParams();
  const [comparisonGroup, setComparisonGroup] = useState([]);
  const [comparisonGroupName, setComparisonGroupName] = useState(null);
  const [selectedOrganisations, setSelectedOrganisations] = useState([]);
  const [loadingOrganizations, setLoadingOrganizations] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectData, setSelectData] = useState([]);
  const [indicatorsData, setIndicatorsData] = useState([]);
  const [filterSteps, setFilterSteps] = useState(0);
  const [reportPageIndicatorData, setReportPageIndicatorData] = useState(null);
  const [reportPageLoader, setReportPageLoader] = useState(false);
  const [numberOfGroup, setNumberOfGroup] = useState(settingsBenchmark?.minimum_number_organisations);
  const [levelFilters, setLevelFilters] = useState(null);
  const [selectLevelFilters, setSelectLevelFilters] = useState([]);

  useEffect(() => {
    setComparisonGroupName(modalEdit || null);
    handlerAddGroups();

    if (benchmarkForms?.length === 1) {
      setNextStep(2, benchmarkForms?.[0]?.id)
    }

    api.benchmark.getBenchmarkLevels()
      .then((response) => {
        const { data: { terms } } = response;
        setLevelFilters(terms);
      })
      .catch((e) => console.error(e))
  }, [modalEdit])

  useEffect(() => {
    if (modalEdit && organizationsInEditGroup?.length) {
      const checkedGroupData = organizationsInEditGroup?.map(item => {
        return {
          'id': item.id,
          'title': item.name,
        }
      });

      setComparisonGroup(checkedGroupData);
    }
  }, [organizationsInEditGroup])

  useEffect(() => {
    searchByOrganizations('');
  }, [])

  useEffect(() => {
    if (!visible) {
      setSelectData([])
      searchByOrganizations('');
      setSearchValue('');
      setComparisonGroup([]);
      handlerSelectAllOrganization(false);
    }
  }, [visible])

  // useEffect(() => {
  //   const data = organization?.sort((x, y) => x.title.localeCompare(y.title))?.map((item) => {
  //     return {label: item?.title, value: item?.id};
  //   });

  //   setOptions(data);
  // }, [organization]);

  const handlerChecked = (v) => {
    if (v) {
      const hasValue = selectedOrganisations?.find(item => item === v);
      let newData;
      if (hasValue)
        newData = selectedOrganisations.filter(item => item !== hasValue);
      else
        newData = [...selectedOrganisations, v];

      setSelectedOrganisations(newData);
    }
  }

  const setNextStep = (step, id = false) => {
    setFilterSteps(step);
    if (id) {
      setReportPageLoader(true);
      api.questionnaires.getSegmentationElements(id)
        .then((response) => {
          const { status, data } = response;
          if (status === 200) {
            setReportPageIndicatorData(data?.webform);
          }
        })
        .catch((e) => console.error(e))
        .finally(() => setReportPageLoader(false));
    } else {
      setReportPageIndicatorData(null);
    }
  }

  const popoverReportPageContent = (
    <div className={'popover__filter'}>
      <p className={'popover__title'}>{t('filter')}</p>
      {
        filterSteps === 2 && benchmarkForms?.length > 1
        ? <ArrowLeftOutlined
            style={{ margin: '10px 0', cursor: 'pointer' }}
            onClick={() => setNextStep(1)}
          />
        : null
      }
      {
        reportPageLoader
        ? <Spin style={{ padding: '10px 0'}} size={'large'} />
        : <ul className={'popover__list'}>
            {
              filterSteps === 2
                ? reportPageIndicatorData?.elements?.length
                ? reportPageIndicatorData?.elements?.map(item => (
                  <li key={item.indicator} onClick={() => addFilterSelect(item)}>{item.title}</li>
                ))
                : <NoRecords />
                : benchmarkForms?.length && benchmarkForms?.map(item => (
                <li key={item.id} onClick={() => setNextStep(2, item.id)}>{item.title}</li>
              ))
            }
          </ul>
      }
    </div>
  )

  const checkLevel = (id) => {
    if (selectLevelFilters?.length) {
      const hasLevel = selectLevelFilters?.find(item => item?.id === id);

      if (!hasLevel) {
        const dataSelect = [...selectLevelFilters];
        const isSelect = levelFilters?.find(item => item?.id === id);
        dataSelect.push(isSelect)
        setSelectLevelFilters(dataSelect);
        searchByOrganizations(searchValue, indicatorsData, dataSelect);
      }
    } 
    else {
      const isSelect = levelFilters?.find(item => item?.id === id);
      const obj = {
        id: isSelect?.id,
        name: isSelect?.name
      };

      setSelectLevelFilters([obj]);
      searchByOrganizations(searchValue, indicatorsData, [obj]);
    }
  }

  const deleteLevel = (id) => {
    const filterLevel = selectLevelFilters?.filter(item => item?.id !== id);
    setSelectLevelFilters(filterLevel);
    searchByOrganizations(searchValue, indicatorsData, filterLevel);
  }

  const popoverLevelContent = (
    <div className={'popover__filter'}>
      <p className={'popover__title'}>{t('level')}</p>
      {
        reportPageLoader
          ? <Spin style={{ padding: '10px 0'}} size={'large'} />
          : <ul className={'popover__list'}>
            {
              levelFilters?.length && levelFilters?.map(item => (
                <li key={item.id} onClick={() => checkLevel(item.id)}>{item.name}</li>
              ))
            }
          </ul>
      }
    </div>
  )

  const popoverContent = (
    <div className={'popover__filter'}>
      <p className={'popover__title'}>{t('filter')}</p>
      <ul className={'popover__list'}>
        {indicatorFilterData?.elements?.length && indicatorFilterData?.elements?.map(item => (
          <li key={item.indicator} onClick={() => addFilterSelect(item)}>{item.title}</li>
        ))}
      </ul>
    </div>
  );

  const getOrganisations = () => {
    let organizations = options
      .filter(x => x.id != activeOrganization.id) // Skip own organisation
      .sort((x, y) => x.title.localeCompare(y.title)); // Sort by title

    if(searchValue)
      organizations = organizations?.filter((item) => item.title.search(new RegExp(searchValue, 'i')) !== -1);

    return organizations;
  };

  const renderCheckBoxItems = useMemo(() => {
    return (
      <Checkbox.Group
        className={'checkbox-group__comparison'}
        // onClick={(e) => handlerChecked(e.target.value)}
        // options={options}
        value={selectedOrganisations}
      >
        {getOrganisations()?.map((item) => (
          <Tooltip key={item.id} placement="left" title={item.title} zIndex={20}>
            <Checkbox
              onChange={(e) => handlerChecked(e.target.value)}
              value={item.id}
              key={item.id}>
              {item.title}
            </Checkbox>
          </Tooltip>
        ))}
      </Checkbox.Group>
    )
  }, [selectedOrganisations, options]);

  const handlerCloseModal = () => {
    searchByOrganizations('');
    setComparisonGroup([]);
    setSearchValue('');
    setSelectedOrganisations([]);
    handlerSelectAllOrganization(false);
    closeModal();
  }

  const comparisonGroupActions = (url, id = '', data, messageText) => {
    const currentOrganizations = comparisonGroup?.map(item => item.id);

    if (comparisonGroupName && (currentOrganizations?.length >= numberOfGroup)) {
      id
        ? url(id, data).then((response) => {
          const { status } = response;

          if (status < 300) {
            getBenchmarkComparisonGroups();
            message.success(messageText)
          }
        })
          .finally(() => {
            setComparisonGroupName(null);
            setComparisonGroup(organization);
            handlerCloseModal();
            reportPage && handleActionWithGroup();
          })
        : url(data).then((response) => {
          const { status } = response;

          if (status < 300) {
            getBenchmarkComparisonGroups();
            message.success(messageText)
          }
        })
          .finally(() => {
            setComparisonGroupName(null);
            setComparisonGroup(organization);
            handlerCloseModal();
            reportPage && handleActionWithGroup();
          })
    } 
    else {
      message.error(`My comparison group's should be more than or equal to ${numberOfGroup}`);
    }
  }

  const createComparisonGroup = () => {
    comparisonGroupActions(
      api.benchmark.createComparisonGroup,
      null,
      { organisations: comparisonGroup?.map(item => item.id + ''), name: comparisonGroupName },
      `Successfully created new comparison group ${comparisonGroupName}!`);
  };

  const editComparisonGroup = () => {
    comparisonGroupActions(
      api.benchmark.updateComparisonGroup,
      comparisonGroupEditId,
      {ids: comparisonGroup?.map(item => item.id + ''), name: comparisonGroupName},
      `Successfully edited comparison group ${comparisonGroupName}!`
    );
  };

  const deleteCheckedGroup = (value) => {
    const newComparisonGroup = comparisonGroup?.filter(item => item.id !== value.id);
    setComparisonGroup(newComparisonGroup);
  }

  const handlerAddGroups = () => {
    let currentComparisonGroup = comparisonGroup;

    const data = organization?.map((item) => {
      const current = selectedOrganisations?.find(value => value === item?.id);
      if (!current) return current;
      const existing = currentComparisonGroup.find(org => org.id === item?.id);
      if (existing) return current;
      return current && currentComparisonGroup.push(item);
    });

    setComparisonGroup(currentComparisonGroup);
    setSelectedOrganisations([]);
  };

  const handlerReplaceGroups = () => {
    let currentComparisonGroup = [];

    const data = options?.map((item) => {
      const current = selectedOrganisations?.find(value => value === item?.id);
      return current && currentComparisonGroup.push(item);
    });

    setComparisonGroup(currentComparisonGroup);
    setSelectedOrganisations([]);
  }

  const handlerSelectAllOrganization = (checked) => {
    if (checked)
      setSelectedOrganisations(options?.map(item => item.id));
    else
      setSelectedOrganisations([]);
  }

  const handlerSelectIndicatorData = (value, item) => {
    const currentData = indicatorsData;
    const hasKey = indicatorsData?.find(key => Object.keys(key)[0] === item.id);

    if (!hasKey) {
      const obj = {};
      obj[item.id] = value;
      currentData.push(obj);
    } 
    else {
      currentData.forEach(c => {
        if (Object.keys(c)[0] === item.id) {
          c[item.id] = value;
        }
      })
    }

    setIndicatorsData([...currentData]);
    searchByOrganizations(searchValue, currentData);
  };

  const renderFiltersSelect = useMemo(() => {
    return selectData?.length
      ? selectData?.map(item => (
        <div className="select-group" key={item.id}>
          <p className="label">{item.title}</p>
          <div className={'select__block'}>
            <Select
              defaultValue={t('select')}
              onChange={(value) => handlerSelectIndicatorData(value, item)}
            >
              {item?.options?.map((option, index) => (
                <Option value={option[0]} key={index}>{option[1]}</Option>
              ))}
            </Select>
            <div className={'delete-group'} onClick={() => deleteSelectFilter(item.id)} />
          </div>
        </div>
      ))
      : null

  }, [selectData]);

  const renderFilterLevel = useMemo(() => {
    return selectLevelFilters?.length
      ? selectLevelFilters?.map(item => (
        <div className="select-group level-group" key={item.id}>
          <div className={'select__block'}>
            <p>{item.name}</p>
            <div className={'delete-group'} onClick={() => deleteLevel(item.id)} />
          </div>
        </div>
      ))
      : null;
  }, [selectLevelFilters])

  const addFilterSelect = (value) => {
    const hasSelect = selectData?.find(item => item.id === value.indicator);

    if (!hasSelect) {
      const obj = {
        title: value.title,
        id: value.indicator,
        options: Object.entries(value.options),
      };

      let result = selectData;
      result.push(obj);
      setSelectData([...result]);
    }
  }

  const deleteSelectFilter = (id) => {
    const currentIndicatorsData = indicatorsData.filter(item => Object.keys(item)[0] !== id);
    const newSelectData = selectData?.filter(item => item?.id !== id);

    setIndicatorsData([...currentIndicatorsData]);
    setSelectData([...newSelectData]);

    searchByOrganizations(searchValue, currentIndicatorsData);
  }

  async function onSegmentationFiltersChanged(activeFilters) {
    setLoadingOrganizations(true);

    await getFilteredOrganizationForBenchmark(id, activeFilters)
        .then((response) => {
          setOptions(response.data.values);
        })
        .catch((e) => console.error(e))
        .finally(() => setLoadingOrganizations(false));
  };

  const searchByOrganizations = (value, indicators = [], levels = []) => {
    setSearchValue(value);

    let newIndicatorsData = {};

    indicators.forEach(item => {
      for (let key in item) {
        newIndicatorsData[`${key}`] = item[key];
      }
    });

    const organisationLevel = levels?.map(item => item.id);

    const params = {
      site_id: appSettings.site_id,
      url: appSettings.calculation_url,
      q: value,
      indicators: newIndicatorsData,
      organisation_levels: organisationLevel,
    }
    searchByAllOrganizations(params);
  }

  const handlerInputSearchData = (e) => {
    setSearchValue(e.target.value);
  }

  return (
    <>
      <div className={'organization-tab__group'}>
        <div className={'organization-tab__item'}>
          <p className="title"><span>1.</span> {t('organization_search')}</p>
          <Search
            allowClear={true}
            className={'organization-block__content input'}
            onChange={handlerInputSearchData}
            onSearch={(value) => searchByOrganizations(value, indicatorsData)}
            placeholder={t('search')}
            size={'large'}
            value={searchValue}
          />

          <p style={{marginTop: "1em"}}>{t('filter')}</p>
          <div id={'segmentation-filters-comparison-group'} style={{maxHeight: "600px", overflowY: 'auto'}}>
            <SegmentationFilters onActiveFiltersChanged={onSegmentationFiltersChanged} popoverId={'segmentation-filters-comparison-group'} segmentationFilters={indicatorFilterData} />
          </div>

          {/* <div className="filters__block">{renderFiltersSelect}</div>
          {(indicatorFilterData?.elements?.length || (reportPage && benchmarkForms?.length))
            ? settingsBenchmark?.show_level_filters === 'yes' &&
            <Popover
              placement="bottomLeft"
              content={reportPage ? popoverReportPageContent : popoverContent}
              trigger="click"
            >
              <div className={'filter'}>
                <div className={'filter__add'}>+</div>
                <span className={'filter__title'}>{t('add_filter')}</span>
              </div>
            </Popover>
            : null
          } */}

          {/* {renderFilterLevel}
          {levelFilters?.length
            ? settingsBenchmark?.show_level_filters === 'yes' &&
            <Popover
              placement="bottomLeft"
              content={popoverLevelContent}
              trigger="click"
            >
              <div className={'filter'}>
                <div className={'filter__add'}>+</div>
                <span className={'filter__title'}>{t('level')}</span>
              </div>
            </Popover>
            : null
          } */}
        </div>

        <div className="organization-tab__item">
          <p className="title"><span>2.</span> {t('search_result')}</p>
          <p className="subtitle" style={{display: "flex", justifyContent: "space-between"}}>
            <span>{options?.length} {t('organizations')}</span>
            <span>{selectedOrganisations?.length} {t('selected')}</span>
          </p>
          <div className={'switch__block'}>
            <Switch onChange={handlerSelectAllOrganization} />
            <span>{t('select_all')}</span>
          </div>
          {loadingOrganizations ? <Spin size={'large'} style={{ padding: '20px' }} /> : renderCheckBoxItems}
          <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <button className={'add-organization'} onClick={handlerAddGroups}>{t('add_to_comparison_groups')}</button>
            <button className={'add-organization'} onClick={handlerReplaceGroups}>{t('replace_comparison_groups')}</button>
          </div>
        </div>
        <div className="organization-tab__item comparison">
          <p className="title"><span>3.</span> {t('my_comparison_group')}</p>
          <p className="subtitle">{comparisonGroup?.length} {t('organizations')}</p>
          {
            comparisonGroup?.length > 0
            && <span
              className={'clear__all'}
              onClick={() => setComparisonGroup([])}
            >{t('сlear_all')}</span>
          }
          {
            <ul className={'comparison-group__list'}>
              {comparisonGroup?.sort((x, y) => x.title.localeCompare(y.title)).map((item, index) => (
                <li key={index}>
                  <div className={'delete-group'} onClick={() => deleteCheckedGroup(item)} />
                  <span>{item.title}</span>
                </li>
              ))}
            </ul>
          }
        </div>
        <div className="organization-tab__item">
          <p className="title"><span>4.</span> {t('beepal_comparison_group_name')}</p>
          <div className="organization-block__content">
            <p className={'description'}>{t('name_in_reports')}</p>
            <input
              value={comparisonGroupName || ''}
              placeholder={t('comparison_group')}
              className={'comparison-group__input input'}
              onChange={(e) => setComparisonGroupName(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className={'button-block__modal'}>
        <div className={'modal__buttons'}>
          <button className={'button__cancel'} onClick={handlerCloseModal}>{t('cancel')}</button>
          {
            modalEdit
              ? <button
                className={'button__action'}
                disabled={!comparisonGroupName}
                onClick={editComparisonGroup}
              >{t('save_group')}</button>
              : <button
                className={'button__action'}
                disabled={!comparisonGroupName}
                onClick={createComparisonGroup}
              >{t('save_group')}</button>
          }
        </div>
      </div>
    </>
  );
};

export default withTranslation('benchmark')(ModalComparisonGroup);