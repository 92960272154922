import Help from "../../../Help";
import More from "../../../More";
import React, { useEffect, useState } from "react";

export default function TwigElement({active, element, loadingSubmission, submission}) {
    const [data, setData] = useState(submission?.data?.find((d) => d.key === element.webform_key) ?? null);
    const [value, setValue] = useState(submission?.data?.find((d) => d.key === element.webform_key)?.value);

    useEffect(() => {
        if (!loadingSubmission) {
            setData(submission?.data?.find((d) => d.key === element.webform_key) ?? null);
            setValue(submission?.data?.find((d) => d.key === element.webform_key)?.value);
        }
    }, [loadingSubmission, submission?.data?.find((d) => d.key === element.webform_key)?.value]);

    const showHelpComponent = !!!(submission?.parent_organisation);
    const showMoreComponent = !!!(submission?.parent_organisation);

    return (
        (!element.hide_empty || value) && (
            <div className="qs-area">
                {showHelpComponent && (
                    <Help active={active} help={element.help} required={element.required} title={element.title} nvh={data?.nvh} nvt={data?.nvt}/>
                )}

                <legend>{value}</legend>

                {showMoreComponent && <More more={element.more} moreTitle={element.more_title} />}
            </div>
        )
    );
}