import React, { Component, Fragment } from 'react'
import { ClockCircleOutlined, CloseOutlined, RightOutlined } from '@ant-design/icons';
import { Card, Progress, Tag, Tooltip, Typography, Button, Select, message } from 'antd';
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { api } from '../../services'

import moment from 'moment'
import history from '../../history'

import chatText from "../../assets/images/svg/chat-text.svg";
import iconLock from '../../assets/images/svg/streamline-icon-lock.svg'

const { Paragraph } = Typography
const { Option } = Select
const { Meta } = Card

class CardWrapper extends Component {
  state = {
    viewSideBar: true,
    mainVisible: false,
    fetch: false
  }

  handleIconClick = (e, url) => {
    e.stopPropagation()
    window.open(url)
  }

  test = () => {
    this.setState({
      viewSideBar: !this.state.viewSideBar
    })
  }

  onSetVisible = () => {
    this.setState({
      mainVisible: !this.state.mainVisible
    })
  }

  render() {
    const {
      item,
      type,
      t,
      withTitle,
      updateActiveOrganisation,
      app: {
        settings: {
          site_logo: logo
        }
      },
      ...rest
    } = this.props

    const {
      title,
      request,
      organisation_title: orgTitle,
      organisation_id: orgId,
      permissions = {}
    } = item

    const { fetch, report } = this.state

    const props = {
      hoverable: true,
      style: { marginBottom: 24 },
      onClick: () => {
        if (type === 'questionnaire') {
          return Promise.resolve()
            .then(() => {
              if (withTitle) {
                return updateActiveOrganisation({ organisation: orgId })
              }
              return null
            })
            .then(() => {
              history.push(`/dashboard/${item.id}`)
            })
        } else {
          this.setState({
            mainVisible: !this.state.mainVisible
          })
        }

      }
    }

    const reportCard = (
      <>
        {request !== undefined && <div className='containerReport'>
          <div>
            <img className='imageContainerReport' src={iconLock} />
          </div>
          <Paragraph>
            <Tag color='orange' style={{ textTransform: 'uppercase' }}>{t(`statuses:${request.status}`)}</Tag>
          </Paragraph>
          <Paragraph>
            <div className='reportTitle'>{title}</div>
          </Paragraph>
          <Paragraph>
            <div className='dateCreate'>
              <span>
                <ClockCircleOutlined style={{ marginRight: '10px' }} />
                {t('reports:created_on')} {moment(request.updated_at).format('DD-MM-YYYY')}
              </span>
              <RightOutlined />
            </div>
          </Paragraph>
        </div>
        }
      </>
    )

    return (
      <div>
        <Card {...props} {...rest} >
          {type === 'questionnaire' && <Fragment>
            <Paragraph ellipsis={{ rows: 3 }} >
              <div dangerouslySetInnerHTML={{ __html: item.description }} />
            </Paragraph>
            <Paragraph>
              <div className='questions'><ClockCircleOutlined style={{ marginRight: '10px' }} />Due on 12-05-2020</div>
            </Paragraph>
            <Paragraph>
              <span><img src={chatText} style={{ marginRight: '5px' }} />24 questions</span>
            </Paragraph>
            <Paragraph strong>
              {item.status !== 'empty' && (
                  <Progress
                      percent={item.percent || 0}
                  />
              )}
            </Paragraph>
          </Fragment>}

          {type === 'report' && reportCard}

        </Card>
        {this.state.mainVisible &&
          <div className='CardModal'>
            <div className='modalHeader'>
              <div className='logo-box'>
                <Link to='/' className='logo text-center'>
                  <span className='logo-lg'>
                    <img style={{ display: 'block' }} src={logo} alt='' height='70' />
                  </span>
                </Link>
              </div>
              <div className='contentHeader'>
                <div className='modalTitle'>{item && item.title}</div>
                <div className='containerButton'>
                  {item && ((item.request.result.docx_url || item.request.result.pdf_url) && <div className='selectDownload'>
                    <div style={{ width: '82px' }}>{t('reports:download_as')}</div>
                    <Select placeholder='...' style={{ borderRadius: 3, border: 'solid 2px #a0a7b7', marginLeft: 10, minWidth: '75px' }}>
                      {item.request.result.pdf_url && <Option value='PDF'><a href={item.request.result.pdf_url} download>PDF</a></Option>}
                      {item.request.result.docx_url && <Option value='DOXS'><a href={item.request.result.docx_url} download>DOCX</a></Option>}
                    </Select>
                  </div>)}
                  <Button onClick={this.onSetVisible} type='link' style={{ display: 'flex', alignItems: 'center', border: 'none' }}><CloseOutlined style={{ color: '#21416f', fontSize: 20 }} /></Button>
                </div>
              </div>
            </div>
            <div style={{ height: 'calc(100% - 72.5px)', background: '#f9fcfe' }}>
              {/* <>
                <div className='buttonRight' onClick={this.test} style={{ display: this.state.viewSideBar ? 'none' : 'flex', zIndex: 9999 }}>
                  <Icon type='right' style={{ fontSize: 18, color: '#134176' }} />
                </div>
                <div className='containerSidebar' style={{ display: this.state.viewSideBar ? 'block' : 'none' }}>
                  <div className='showSidebar' onClick={this.test}>
                    <span>Contents</span>
                    <Icon type='left' style={{ fontSize: 18, color: '#134176' }} />
                  </div>
                  <div className='listLink'>
                    <Link to='#'>Section name</Link>
                    <Link to='#'>Section name</Link>
                    <Link to='#'>Section name</Link>
                    <Link to='#'>Section name</Link>
                    <Link to='#'>Section name</Link>
                    <Link to='#'>Section name</Link>
                  </div>
                </div>
              </> */}
              <div className='containerContent'>
                {item && item.request.result && <div className='containerIframe' style={{ height: (item.request.result.docx_url || item.request.result.pdf_url) ? 'calc(100% - 150px)' : 'calc(100% - 40px)' }}>
                  <iframe title='' src={item.request.result.html_url} id='iframe' style={{ width: '100%', height: '100%', border: '1px solid #ddd', borderBottom: (item.request.result.docx_url || item.request.result.pdf_url) ? 'none' : '1px solid #ddd', padding: '30px' }} />

                  {(item.request.result.docx_url || item.request.result.pdf_url) && <div className='footerIframe'><div className='selectDownload'>
                    {t('reports:download_as')}
                    <Select placeholder='...' style={{ borderRadius: 3, border: 'solid 2px #a0a7b7', marginLeft: 10, minWidth: '75px' }}>
                      {item.request.result.pdf_url && <Option value='PDF'><a href={item.request.result.pdf_url} download>PDF</a></Option>}
                      {item.request.result.docx_url && <Option value='DOCX'><a href={item.request.result.docx_url} download>DOCX</a></Option>}
                    </Select>
                  </div></div>}

                  {/* <div className='containerSwitch'>
                    <div className='switchItem'>
                      <Icon type='left' style={{ marginRight: 11, color: '#134176' }} />
                      <div className='switchItem_text'>
                        <span className='title'>Previous report</span>
                        <span>Analyse loonkosten sociale recherche</span>
                      </div>
                    </div>
                    <div className='switchItem'>
                      <div className='switchItem_text' style={{ textAlign: 'right' }}>
                        <span className='title'>Next report</span>
                        <span>Personeel arbeidsparticipatie</span>
                      </div>
                      <Icon type='right' style={{ marginLeft: 11, color: '#134176' }} />
                    </div>
                  </div> */}
                </div>
                }

              </div>
            </div>
            {/* <div className='containerRelated'>
              <span className='title'>Related reports</span>
              <div className='containerRelatedCard'>
                <Card {...props} style={{ width: '100%', background: '#f9fcfe', marginRight: 76 }}>
                  {reportCard}
                </Card>
                <Card {...props} style={{ width: '100%', background: '#f9fcfe' }}>
                  {reportCard}
                </Card>
              </div>
            </div> */}

          </div>
        }
      </div>
    );
  }
}

export default withTranslation()(CardWrapper)
