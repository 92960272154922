import history from '../../../../history'

let refreshToken = ''
let clientId = process.env.DRUPAL_API_CLIENT_ID
let clientSecret = process.env.DRUPAL_API_CLIENT_SECRET

export default (instance) => {
  instance.interceptors.response.use(null, err => {
    if (err.response && err.response.status === 403) {
      refreshToken = window.localStorage.getItem('refreshToken')
      return instance({
        method: 'POST',
        url: '/oauth/token?refresh',
        data: {
          "grant_type": "refresh_token",
          "refresh_token": refreshToken,
          "client_id": 'react_client',
          "client_secret": 'z*kFbbx*!kbGVRW7R2^0CJzs2#Eziz'
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
        .then(({ data }) => {
          window.localStorage.setItem('accessToken', data.access_token)
          window.localStorage.setItem('refreshToken', data.refresh_token)
          window.localStorage.setItem('userLogin', !!data)
          return instance(err.config)
        })
        .catch((err) => {
          window.localStorage.clear()
          history.push('/login')
        })
    }
    return Promise.reject(err)
  })
}
