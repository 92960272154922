import { connect } from "react-redux";

import actions from '../../../../store/actions';
import RulesPage from "./RulesPage";


const mapStateToProps = (state) => ({
  isFetching: state.app.isFetching,
  uploadDescription: state.app.settings.upload_description,
});

const mapDispatchToProps = (dispatch) => ({
  getFolder: (params) => dispatch(actions.fileManager.getFolder(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RulesPage);
