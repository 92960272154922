import http from './http'

const getReportBlocks = () => {
  return http({
    url: '/api/v1/basic-page-report-blocks/list?_format=json',
    method: 'GET'
  })
}

const getReportBlockById = (id, comparisonGroupID = false, organisationID = false) => {
  return http({
    url:
      `/api/v1/basic-page-report-blocks/${id}${comparisonGroupID ? `?comparison_group=${comparisonGroupID}` : ''}${organisationID ? `${comparisonGroupID ? '&' : '?'}organisation_id=${organisationID}` : ''}`,
    method: 'GET'
  })
}

const getReportBlockHtmlById = (id, startdate = false, enddate = false, comparisonGroupIDs = false, organisations = []) => {
  var startParam = startdate ? `startdate=${startdate}` : '';
  var endParam = enddate ? `enddate=${enddate}` : '';
  var groupParam = comparisonGroupIDs && comparisonGroupIDs.filter(x => x != null && x != '').length > 0 ? `comparison_group=${comparisonGroupIDs.join(',')}` : '';
  var orgsParam = organisations.length > 0 ? `organisations=${organisations.join(',')}` : '';

  var params = [startParam, endParam, groupParam, orgsParam].filter(x => x != '');
  var strParams = `?${params.join('&', params)}`;

  return http({
    url: `/api/v1/basic-page-report-blocks/${id}/html${strParams}`,
    method: 'GET'
  })
}

const getReportBlockDocxById = (id) => {
  return http({
    url: `/api/v1/basic-page-report-blocks/${id}/docx`,
    method: 'GET'
  })
}

export {
  getReportBlocks,
  getReportBlockById,
  getReportBlockDocxById,
  getReportBlockHtmlById
}