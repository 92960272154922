import React from "react";

import "./Markup.scss";

export default function MarkupElement({element}) {
    return (
        <>
            <div className="titleBlock">
                <div className="content" dangerouslySetInnerHTML={{ __html: element.markup }}/>
            </div>
        </>
    );
}