import {
  SETTINGS_FETCHING,
  SETTINGS_SUCCESS,
  TAGS_FETCHING,
  TAGS_SUCCESS
} from '../types'

const initialState = {
  settings: {},
  tags: [],
  isFetching: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      }

    case SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.payload
      }

    case TAGS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      }

    case TAGS_SUCCESS:
      return {
        ...state,
        tags: action.payload
      }

    default:
      return state
  }
}
