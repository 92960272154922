// APP
export const SETTINGS_FETCHING = 'SETTINGS_FETCHING'
export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS'
export const TAGS_FETCHING = 'TAGS_FETCHING'
export const TAGS_SUCCESS = 'TAGS_SUCCESS'

// UsersList
export const AUTH_FETCHING = 'AUTH_FETCHING'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const GUEST_SUCCESS = 'GUEST_SUCCESS'
export const AUTH_FAILED = 'AUTH_FAILED'
export const UPDATE_ORGANISATION_FETCHING = 'UPDATE_ORGANISATION_FETCHING'
export const UPDATE_ORGANISATION_SUCCESS = 'UPDATE_ORGANISATION_SUCCESS'

// Organisations
export const GET_ORGANISATIONS_FETCHING = 'GET_ORGANISATIONS_FETCHING'
export const GET_ORGANISATIONS_SUCCESS = 'GET_ORGANISATIONS_SUCCESS'

// Benchmark
export const BENCHMARK_GET_DATA = 'BENCHMARK_GET_DATA'
export const BENCHMARK_FETCHING = 'BENCHMARK_FETCHING'
export const BENCHMARK_GET_SETTINGS = 'BENCHMARK_GET_SETTINGS'
export const BENCHMARK_GET_FORMS = 'BENCHMARK_GET_FORMS'
export const BENCHMARK_GET_COMPARISON_GROUP = 'BENCHMARK_GET_COMPARISON_GROUP'
export const BENCHMARK_GET_ALL_ORGANIZATION = 'BENCHMARK_GET_ALL_ORGANIZATION'
export const BENCHMARK_GET_MAP_DATA = 'BENCHMARK_GET_MAP_DATA'
export const BENCHMARK_GET_SAVED_VIEW = 'BENCHMARK_GET_SAVED_VIEW'
export const BENCHMARK_SEARCH_START = 'BENCHMARK_SEARCH_START'
export const BENCHMARK_SEARCH_ORGANIZATION_DATA = 'BENCHMARK_SEARCH_ORGANIZATION_DATA'
export const BENCHMARK_SEARCH_END = 'BENCHMARK_SEARCH_END'

//File-manager
export const FILE_FETCHING = 'FILE_FETCHING'
export const FILE_FETCHING_HISTORY = 'FILE_FETCHING_HISTORY'
export const FILE_GET_FOLDER = 'FILE_GET_FOLDER'
export const FILE_GET_FILES = 'FILE_GET_FILES'
export const FILE_GET_FILES_OVERVIEW = 'FILE_GET_FILES_OVERVIEW'
export const FILE_GET_HISTORY_FILES = 'FILE_GET_HISTORY_FILES'

// Profiles
export const PROFILE_FETCHING = 'PROFILE_FETCHING'
export const PROFILE_LIST = 'PROFILE_LIST'
export const PROFILE_SET_ROLES = 'PROFILE_SET_ROLES'
export const PROFILE_SET_USER_INFO = 'PROFILE_SET_USER_INFO'
export const PROFILE_SET_ALL_ORGANIZATIONS = 'PROFILE_SET_ALL_ORGANIZATIONS'