import React, { useMemo } from "react";
import { useParams } from 'react-router-dom';
import { Select } from "antd";

import './FormInfo.scss';

import history from "../../../../history";
import { Loading } from "../../../../components";

const { Option } = Select;

const FormInfo = ({
    activeForm,
    forms,
    setHandlerChangeForm
}) => {
    const { formTitle } = useParams();

    const handleChangeForm = (value) => {
        const currentFormId = forms.find(item => item.title === value)?.id;
        setHandlerChangeForm(value);
        history.push(`/benchmark/${currentFormId}&title=${formTitle}`);
    }

    const renderFormsSelect = useMemo(() => {
        if (activeForm) {
            return (
                <Select
                    defaultValue={activeForm?.title}
                    bordered={false}
                    onChange={handleChangeForm}
                    className={'header'}
                >
                    {forms?.map((form) => (<Option value={form.title} key={form.id}>{form.title}</Option>))}
                </Select>)
        } else {
            return <Loading />
        }
    }, [activeForm])

    return (<>
        {renderFormsSelect}
    </>)
};

export default FormInfo;
