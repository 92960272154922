import { Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

function Help({active, help, onClick, required, title, nvh, nvt}) {   
    const style = {
        alignItems: "center",
        borderBottom: "none",
        color: "#000",
        display: "flex",
        fontSize: "18px",
        justifyContent: "flex-start"
    };

    if (active)
        style.color = "#1369EA";

    return (
        <>
            <legend className={required ? "required" : ""} onClick={onClick} style={style}>
                <div style={{alignItems: "center", display: "flex", justifyContent: "space-between", width: "100%"}}>
                    <div>
                        <div style={{alignItems: "top", display: "flex", justifyContent: "center"}}>
                            {title}
                        </div>

                        {help && (
                            <Popover content={<div dangerouslySetInnerHTML={{ __html: help }} />}>
                                <QuestionCircleOutlined style={{ cursor: "help", marginRight: 4 }}/>
                            </Popover>
                        )}
                    </div>

                    <div className="extendedTags">
                        {!!nvh && <div className='extendedTag'>N.V.H.</div>}
                        {!!nvt && <div className='extendedTag'>N.V.T.</div>}
                    </div>
                </div>
            </legend>
        </>
    );
}

export default withTranslation('questionnaire')(Help);