import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons/lib/icons";

import "./SearchInput.scss";

export default function SearchInput({ text, value, onSearch, realtime = false}) {
  const [searchValue, setSearchValue] = useState(value);

  useEffect(() =>{
    if(realtime) searchReport();
  }, [searchValue]);

  const searchReport = () => {
    if (searchValue) {
      const value = searchValue.replace(/#/gi, "");
      onSearch(value);
    } else {      
      onSearch(null);
    }
  };

  const searchInputValue = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && searchValue) {
      searchReport();
    }
  };

  return (
    <Input
      placeholder={text}
      value={searchValue}
      onKeyDown={handleKeyDown}
      className={"custom__input"}
      onChange={searchInputValue}
      suffix={
        <SearchOutlined style={{ cursor: "pointer" }} onClick={searchReport} />
      }
    />
  );
}
