import ComparisonGroupBuilder from './ComparisonGroupBuilder'
import {connect} from "react-redux";
import actions from "../../store/actions";

const mapStateToProps = state => ({
    app: state.app,
    user: state.user,
    settingsBenchmark: state.benchmark.benchmarkSettings,
    allOrganization: state.benchmark.allOrganization,
})

const mapDispatchToProps = dispatch => ({
    getBenchmarkOrganization: () => dispatch(actions.benchmark.getBenchmarkOrganization()),
    getBenchmarkComparisonGroup: () => dispatch(actions.benchmark.getBenchmarkComparisonGroup()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComparisonGroupBuilder)
