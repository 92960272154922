import React, {useEffect, useState} from "react";
import moment from "moment";
import {Spin, Table} from "antd";
import FileTextOutlined from "@ant-design/icons/lib/icons/FileTextOutlined";

import './TabHistory.scss';

const TabHistory = ({
  t,
  fileHistory,
  value,
  folderId,
  isHistoryFetching,
  historyFile
}) => {
  const [dataSource, setDataSource] = useState([])

  const columnFiles = [
    {
      title: t('upload_date'),
      dataIndex: 'last_update',
      key: 'last_update',
      render: text => <p>{text}</p>,
    },
    {
      title: t('author'),
      dataIndex: 'author',
      key: 'author',
      render: text => <p>{text}</p>,
    },
    {
      title: t('filename'),
      dataIndex: 'filename',
      key: 'filename',
      sorter: (a, b) => a.filename.localeCompare(b.filename),
      render: text => (
        <div className={'folder__block'}>
          <FileTextOutlined />
          <p>{text}</p>
        </div>
      )
    }
  ];

  useEffect(() => {
    fileHistory({
      folderId,
      fileId: value?.key
    })
  }, [value])

  useEffect(() => {
    if (historyFile?.length) {
      const historyFileSource = historyFile?.map(file => {
        return {
          key: file?.id,
          filename: file?.name,
          last_update: moment(`${file?.created_at}`).format('YYYY-MM-DD HH:mm:ss'),
          author: file?.author
        }
      });

      setDataSource(historyFileSource)
    } else {
      setDataSource([]);
    }
  }, [historyFile])

  return (
    <div className={'file__history'}>
      {
        isHistoryFetching
        ? <Spin />
        : <Table
            dataSource={dataSource}
            columns={columnFiles}
            pagination={{ defaultPageSize: 7, defaultCurrent: 1, showSizeChanger: false }}
          />
      }
    </div>
  )
};

export default TabHistory;