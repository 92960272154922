import React from "react";
import LeftOutlined from "@ant-design/icons/lib/icons/LeftOutlined";

import './ArrowBack.scss';
import history from "../../history";

const ArrowBack = ({
  title,
  url
}) => {

  const handlePushToUrl = () => history.push(url);

  return (
    <div className={'arrow__back'} onClick={handlePushToUrl}>
      <LeftOutlined />
      <p className={'text'}>{title}</p>
    </div>
  );
};

export default ArrowBack;
