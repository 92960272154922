import React from 'react';

import './CustomHeader.scss';

const CustomHeader = ({
  headerData
}) => {
  return (
    headerData?.value &&
    <div className={'custom__header'} dangerouslySetInnerHTML={{__html: headerData?.value}} />
  );
};

export default CustomHeader;