import { connect } from "react-redux";

import actions from '../../../../store/actions';
import FolderPage from "./FolderPage";


const mapStateToProps = (state) => ({
  folderData: state.fileManager.folderData,
  isFetching: state.fileManager.isFetching,
});

const mapDispatchToProps = (dispatch) => ({
  getFolder: (params) => dispatch(actions.fileManager.getFolder(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FolderPage);
