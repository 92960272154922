import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

import './UserEdit.scss';
import { HelpCenter } from "../../../../components";
import {UserForm, UserInfoBlock, UserResetPassword} from '../../components';
import {message, Spin} from "antd";

const UserEdit = ({
  t,
  getUserInfo,
  isFetching,
  profileUserInfo,
  updateUserInfo
}) => {
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getUserInfo(id);
    }
  }, [])


  const getUserData = async (value) => {
    await updateUserInfo(id, value)
      .then(async (response) => {
        const { status, data } = response;
        if (status === 200) {
          message.success(
            `${profileUserInfo?.display_name} (UserID ${data?.data?.id}) ${t('successfully_edit')}`
          );
          getUserInfo(id);
        }
        return response;
      })
      .catch((e) => console.error(e));
  }

  return (
    !profileUserInfo && !isFetching
    ? <Spin
        size={'large'}
        style={{
          paddingTop: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      />
    : (
        <div className={'user__edit'} key={profileUserInfo?.id}>
          <UserInfoBlock
            t={t}
            id={id}
            profileUserInfo={profileUserInfo}
            updateUserInfo={updateUserInfo}
          />

          <UserForm profileUserInfo={profileUserInfo} getUserData={getUserData} />
          <UserResetPassword getUserData={getUserData} />
          <HelpCenter style={{ width: '70%' }} />

          <div className={'feedback'}>{t('feedback')}</div>
        </div>
      )
  );
};

export default withTranslation('user')(UserEdit);
