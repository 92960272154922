import { FavoritesGrid } from "./../";
import React from "react";
import { Tooltip } from "antd";

export default function ReportFavorites({
  active_organisation,
  calculation_types,
  comparison_groups,
  elementsData,
  favoriteIndicators,
  gridColumns,
  handleDeleteFavorite,
  individual_organisations,
  period_end,
  period_start,
  settingsBenchmark,
  t
}) {
  return (
    <div className={"report__tabs"}>
      <div className={"tabs__header"}>
        {gridColumns?.map((item, index) => (
          <Tooltip key={item.name + index} placement={"top"} title={item.Tooltip}>
            <div className={"columns"} key={`favorites-tooltip-${item.name}${index}`}>
              {item.title}
            </div>
          </Tooltip>
        ))}
      </div>
      <div className={"report__analyse"}>
        <FavoritesGrid
          active_organisation={active_organisation}
          calculation_types={calculation_types}
          comparison_groups={comparison_groups}
          elementsData={elementsData}
          favoriteIndicators={favoriteIndicators}
          handleDeleteFavorite={handleDeleteFavorite}
          individual_organisations={individual_organisations}
          period_end={period_end}
          period_start={period_start}
          t={t}
        />
      </div>
    </div>
  );
}
