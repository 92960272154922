import {connect} from "react-redux";

import UsersList from './UsersList'
import actions from '../../../../store/actions';

const mapStateToProps = state => ({
  isFetching: state.profile.isFetching,
  profileList: state.profile.profileList,
  profileRoles: state.profile.profileRoles,
})

const mapDispatchToProps = (dispatch) => ({
  getProfileList: (params) => dispatch(actions.profile.getProfileList(params)),
  getProfileRoles: () => dispatch(actions.profile.getProfileRoles(true)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersList)

