import { connect } from 'react-redux'

import Card from './Card'
import actions from '../../store/actions'

const mapStateToProps = state => ({
  app: state.app
})

const mapDispatchToProps = dispatch => ({
  updateActiveOrganisation: data => dispatch(actions.user.updateActiveOrganisation(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Card)
