import {
  SETTINGS_FETCHING,
  SETTINGS_SUCCESS,
  TAGS_FETCHING,
  TAGS_SUCCESS
} from '../types'

import { api } from '../../services'

const getSiteSettings = () => dispatch => {
  dispatch({
    type: SETTINGS_FETCHING,
    payload: true
  })

  return api.app.getSiteSettings()
    .then(({ data }) => {
      if (data.status) {
        window.localStorage.setItem('guest_mode', data.settings?.guest_mode?.is_enabled)

        dispatch({
          type: SETTINGS_SUCCESS,
          payload: data.settings
        })
      }
      return data
    })
    .finally(() => {
      dispatch({
        type: SETTINGS_FETCHING,
        payload: false
      })
    })
}

const getTags = () => dispatch => {
  dispatch({
    type: TAGS_FETCHING,
    payload: true
  })

  return api.app.getTags()
    .then(({ data }) => {
      if (data?.status) {
        dispatch({
          type: TAGS_SUCCESS,
          payload: data.terms
        })
      }
      return data
    })
    .finally(() => {
      dispatch({
        type: TAGS_FETCHING,
        payload: false
      })
    })
}

export {
  getSiteSettings,
  getTags
}
