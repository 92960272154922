import React from "react";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";

import UserImg from '../../../../../../assets/images/svg/userImg.jsx';
import './UserItem.scss';
import history from "../../../../../../history";

const UserItem = ({
  t,
  value,
}) => {

  const pushToEditPage = () => {
    history.push(`/profile/edit/${value.id}`);
  }

  const getFirstLetters = (name) => {
    return name.split(' ').map(item => item?.[0].toUpperCase()).join('');
  }

  const userRoles = () => {
    if (Object.values(value?.roles)?.length) {
      return Object.values(value?.roles)?.length === 1
      ? Object.values(value?.roles)?.[0]
      : `${Object.values(value?.roles)?.[0]} + ${Object.values(value?.roles)?.length - 1} roles`
    } else {
      return '';
    }
  }

  return (
    <div className={'user__item'} onClick={pushToEditPage}>
      <div className={'user__info'}>
        <div className={'user__img'}>
          {
            value?.image
              ? <img src={value?.image} alt="img" />
              : <span>{getFirstLetters(value?.display_name)}</span>
          }
        </div>
        <p className={'user__name'}>{value?.display_name}</p>
      </div>

      <div className={'user__email'}>
        <UserImg />
        <p>{value?.email}</p>
      </div>

      <div className={'user__role'}>
        {userRoles()}
      </div>

      <div className={'user__action'}>
        <RightOutlined />
      </div>
    </div>
  );
};

export default UserItem;