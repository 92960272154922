import React, { Component } from 'react'
import { Col, Row, Spin, Button, Select, Pagination } from 'antd'
import { withTranslation } from 'react-i18next'

import { Card } from '../../components'
import { api } from '../../services'
import history from '../../history'

import helpWheel from '../../assets/images/svg/icon-help-wheel.svg'

const { Option } = Select

class Dashboard extends Component {



  state = {
    reports: [],
    fetch: false,
    minValue: 0,
    maxValue: 9
  }

  fetchData = () => {
    const { user: { isGuest, data: {page_settings} }, app: {settings} } = this.props

    this.setState({
      fetch: true
    })

    const type = new URLSearchParams(history.location.search).get('type')

    return Promise.resolve()
      .then(() => {
        if (type === 'all') {
          return api.reports.getFullReports()
        }
        return api.reports.getReports()
      })
      .then(({ data }) => {
        this.setState({
          fetch: false,
          reports: data.reports
            .filter(i => isGuest ? settings.guest_mode.report?.indexOf(i.id) !== -1 : (page_settings.is_enabled ? page_settings.report?.indexOf(i.id) !== -1 : i.permissions.report_settings === 'show'))
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  componentDidMount() {
    this.fetchData()
  }

  // componentDidUpdate(prevProps) {
  //   const currentUserData = this.props.user.data
  //   const prevUserData = prevProps.user.data
  //
  //   const oldType = new URLSearchParams(prevProps.location.search).get('type')
  //   const type = new URLSearchParams(this.props.location.search).get('type')
  //
  //   if ((oldType !== type) || (prevUserData.organisation !== currentUserData.organisation)) {
  //     this.fetchData()
  //   }
  // }

  handleChange = value => {
    if (value <= 1) {
      this.setState({
        minValue: 0,
        maxValue: 9
      })
    } else {
      this.setState({
        minValue: (value * 9) - 9,
        maxValue: value * 9
      })
    }
  }

  render() {
    const { fetch, reports } = this.state
    const { t, user: { data, isGuest }, app: {settings} } = this.props

    const type = new URLSearchParams(history.location.search).get('type')

    const organisation = isGuest ? false : (data.organisations.find(i => i.id === data.organisation) || '')

    return <div className='page_dashboard'>
      {false && <div className='row'>
        <div className='page-title-box'>
          <div className='selectLeft'>
            <div className='selectQuestionnaires'>
              <Select defaultValue='Tags'>
                <Option value='Tags'>Tags</Option>
              </Select>
            </div>
            <div className='selectQuestionnaires'>
              <Select defaultValue='Status'>
                <Option value='Status'>Status</Option>
              </Select>
            </div>
            <div className='selectQuestionnaires'>
              <Select defaultValue='Year'>
                <Option value='Year'>Year</Option>
              </Select>
            </div>
            <div className='selectQuestionnaires'>
              <Select defaultValue='Quarter'>
                <Option value='Quarter'>Quarter</Option>
              </Select>
            </div>
            <div className='selectQuestionnaires'>
              <Select defaultValue='Month' style={{ fontSize: '14px' }}>
                <Option value='Month'>Month</Option>
              </Select>
            </div>
          </div>

          <div className='selectRight'>
            <div className='selectQuestionnaires'>
              <Select defaultValue='Sort by: Date published' style={{ fontSize: '14px' }}>
                <Option value='Sort by: Date published'>Sort by: Date published</Option>
              </Select>
            </div>
          </div>
        </div>
      </div>
      }
      {fetch
        ? <div className='d-flex justify-content-center'>
          <Spin />
        </div>
        : <>
          <Row gutter={24} type='flex' justify='start' align='top' className='mt-3'>
            {reports.slice(this.state.minValue, this.state.maxValue).map(item =>
              <Col span={8} key={item.id}>
                <Card
                  item={item}
                  type='report'
                  withTitle={type === 'all'}
                  style={{ background: '#f9fcfe', marginBottom: '31px' }}
                />
              </Col>
            )}

          </Row>
          <Pagination
            defaultCurrent={1}
            defaultPageSize={9}
            onChange={this.handleChange}
            total={reports.length}
            style={{ marginTop: 9 }}
          />
        </>
      }
      {false && <div className='containerHelpCenter' style={{ marginTop: 99 }}>
        <div className='containerHelpCenter_text'>
          <span style={{ color: '#000' }}>Any questions? Visit our Help Center.</span>
          <Button
            type='default'
            style={{ borderRadius: '18px', fontSize: '14px !important', width: '146px', height: '35px' }}>Go to
            Help center</Button>
        </div>
        <img src={helpWheel} alt='' />
      </div>
      }
    </div>
  }
}

export default withTranslation('reports')(Dashboard)
