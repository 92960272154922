import { connect } from 'react-redux';
import FormInfo from './FormInfo';
import actions from "../../../../store/actions";

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(FormInfo);
