import React, {useMemo, useState} from "react";
import {withTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import moment from "moment";
import {Spin} from "antd";

import './UserInfoBlock.scss';
import {convertToBase64} from "../../../../utils";
import Camera from "../../../../assets/images/svg/camera";


const UserInfoBlock = ({
  t,
  profileUserInfo,
  updateUserInfo,
  updatePersonalUserInfo,
  isFetching,
  personalPage = false,
}) => {
  const { id } = useParams();

  const [avatarImage, setAvatarImage] = useState(null);

  const addUserAvatar = async (e) => {
    const avatarFile = await convertToBase64(e.target.files[0]);
    setAvatarImage(avatarFile);
    if (personalPage) {
      await updatePersonalUserInfo({user_avatar: avatarFile});
    } else {
      await updateUserInfo(id, {user_avatar: avatarFile});
    }
  }

  const checkUploadImage = useMemo(() => {
    return (
      avatarImage
        ? <img src={avatarImage} alt="user" className={'avatar'} />
        : <div className={'avatar'} />
    )
  }, [avatarImage]);

  const setUserName = useMemo(() => {
    if (profileUserInfo?.first_name || profileUserInfo?.last_name) {
      return `${profileUserInfo?.first_name} ${profileUserInfo?.last_name}`
    } else {
      return profileUserInfo ? `${profileUserInfo?.display_name}` : '';
    }
  }, [profileUserInfo]);

  return (
    isFetching
      ? <Spin
          size={'large'}
          style={{
            paddingTop: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        />
      : (
      <div className={'user__info-block'}>
        <div className={'img__block'}>
          {
            profileUserInfo?.image && !avatarImage
              ? <img src={profileUserInfo?.image} alt="user" className={'avatar'} />
              : checkUploadImage
          }

          <input type="file" onChange={addUserAvatar} />
          <Camera />
        </div>
        <div className={'text__block'}>
          <p className={'name'}>{setUserName}</p>
          <p className={'last__visit'}>
            {
              profileUserInfo?.changed &&
              `
                ${t('last_visit')} 
                ${moment(profileUserInfo?.changed).format('YYYY-MM-DD HH:mm:ss')}
              `
            }
          </p>
        </div>
      </div>
    )

  );
};

export default withTranslation('user')(UserInfoBlock);
