import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'react-hot-loader/patch';

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'

import App from './App'
import { store } from './store'
import { i18n } from './services'
import { AppContainer } from 'react-hot-loader';

import './assets/scss/DefaultTheme.scss'
import 'antd/dist/antd.css'

const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <Component />
        </Provider>
      </I18nextProvider>      
    </AppContainer>,
    document.getElementById('root')
  );
}

render(App);