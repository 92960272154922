export const showConfirm = (params) => {
  const { title, confirm, icon, okFunc, cancelText } = params;
  confirm({
    title: title,
    icon: icon,
    onOk() {
      okFunc();
    },
    cancelText,
  })
}