import { connect } from 'react-redux';
import ReportGrid from './ReportGrid';
import actions from "../../../../store/actions";

const mapStateToProps = (state) => ({
  settingsBenchmark: state.benchmark.benchmarkSettings
});

const mapDispatchToProps = (dispatch) => ({
  
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportGrid);
