import { connect } from 'react-redux'

import Organisations from './Organisations'
import actions from '../../store/actions'

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  getOrganisations: () => dispatch(actions.organisations.getOrganisations()),
  updateActiveOrganisation: data => dispatch(actions.user.updateActiveOrganisation(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Organisations)
