import * as t from '../types';

import {sortByField} from '../../views/ReportBenchmark/utils'
import { api } from '../../services';
import {message} from "antd";
import history from "../../history";

const getProfileList = (params) => (dispatch) => {
  const { page, per_page, roles, search } = params;
  dispatch({
    type: t.PROFILE_FETCHING,
    payload: true,
  })

  return api.profile.getProfilesList(page, per_page, roles, search)
    .then((response) => {
      const { status, data } = response;

      if (status === 200) {
        dispatch({
          type: t.PROFILE_LIST,
          payload: data,
        });

        return response;
      }
    })
    .finally(() => {
      dispatch({
        type: t.PROFILE_FETCHING,
        payload: false,
      })
    })
};

const getProfileRoles = (profile = false) => (dispatch) => {
  dispatch({
    type: t.PROFILE_FETCHING,
    payload: true,
  })

  return api.profile.getProfileRoles(profile)
    .then((response) => {
      const { status, data } = response;

      if (status === 200) {
        dispatch({
          type: t.PROFILE_SET_ROLES,
          payload: data?.roles
        })
      }

      return response;
    })
    .catch((e) => console.error(e))
    .finally(() => {
      dispatch({
        type: t.PROFILE_FETCHING,
        payload: false,
      })
    });
}

const createUserProfile = (data) => (dispatch) => {
  dispatch({
    type: t.PROFILE_FETCHING,
    payload: true,
  })

  return api.profile.createUserProfile(data)
    .then((response) => {
      const { status, data } = response;

      if (status === 200) {
        message.success(`Gebruiker met id ${data?.data?.id} gemaakt succesvol`);
        history.push('/profile');

      } else if (status === 403) {
        message.error(`${data?.errors?.[0]}`);
      }

      return response;
    })
    .catch((e) => console.error(e))
    .finally(() => {
      dispatch({
        type: t.PROFILE_FETCHING,
        payload: false,
      })
    });
};

const getUserInfo = (id) => (dispatch) => {
  dispatch({
    type: t.PROFILE_FETCHING,
    payload: true,
  });

  return api.profile.getUserInfo(id)
    .then((response) => {
      const { status, data } = response;

      if (status === 200) {
        dispatch({
          type: t.PROFILE_SET_USER_INFO,
          payload: data?.data?.[0],
        })
      }

      return response;
    })
    .catch((e) => {
      console.error(e);
      history.push('/profile');
    })
    .finally(() => {
      dispatch({
        type: t.PROFILE_FETCHING,
        payload: false,
      })
    });
}

const updateUserInfo = (id, data) => (dispatch) => {
  dispatch({
    type: t.PROFILE_FETCHING,
    payload: true,
  });

  return api.profile.updateUserInfo(id, data)
    .then((response) => {
      return response;
    })
    .catch((e) => console.error(e))
    .finally(() => {
      dispatch({
        type: t.PROFILE_FETCHING,
        payload: false,
      })
    });
}

const deleteUser = (id) => (dispatch) => {
  dispatch({
    type: t.PROFILE_FETCHING,
    payload: true,
  });

  return api.profile.deleteUser(id)
    .then((response) => {
      const { status, data } = response;

      if (status === 200) {
        message.success(`${data?.message}`);
        history.push('/profile');
      }
      return response;
    })
    .catch((e) => console.error(e))
    .finally(() => {
      dispatch({
        type: t.PROFILE_FETCHING,
        payload: false,
      })
    });
};

const updatePersonalUserInfo = (data) => (dispatch) => {
  dispatch({
    type: t.PROFILE_FETCHING,
    payload: true,
  });

  return api.user.updatePersonalUserInfo(data)
    .then((response) => {
      const { status } = response;
      if(status === 200) {
        return response;
      }
    })
    .catch((e) => console.error(e))
    .finally(() => {
      dispatch({
        type: t.PROFILE_FETCHING,
        payload: false,
      })
    });
}

const changeUserPassword = (data) => (dispatch) => {
  dispatch({
    type: t.PROFILE_FETCHING,
    payload: true,
  });

  return api.user.changeUserPassword(data)
    .then((response) => {
      const { status } = response;
      if(status === 200) {
        return response;
      }
    })
    .catch((e) => console.error(e))
    .finally(() => {
      dispatch({
        type: t.PROFILE_FETCHING,
        payload: false,
      })
    });
};

const getAllOrganizationForProfile = (profile = false) => (dispatch) => {
  dispatch({
    type: t.PROFILE_FETCHING,
    payload: true,
  });

  return api.organisations.getAllOrganization(profile)
    .then((response) => {
      const { status, data } = response;

      if (status === 200) {
        dispatch({
          type: t.PROFILE_SET_ALL_ORGANIZATIONS,
          payload: data?.organisations.sort(sortByField('title')),
        })
      }

      return response;
    })
    .catch((e) => console.error(e))
    .finally(() => {
      dispatch({
        type: t.PROFILE_FETCHING,
        payload: false,
      });
    })
}


export {
  getProfileList,
  getProfileRoles,
  createUserProfile,
  getUserInfo,
  updateUserInfo,
  deleteUser,
  updatePersonalUserInfo,
  changeUserPassword,
  getAllOrganizationForProfile
}
