import React, { useMemo, Component } from "react";
import { withTranslation } from "react-i18next";
import { message, Select, Spin, Upload } from "antd";

import { api } from "../../services";

import "./StaticPages.scss";
import { Button } from "reactstrap";
import UploadOutlined from "@ant-design/icons/lib/icons/UploadOutlined";
import {convertToBase64} from "../../utils";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";
import Iframe from "../../components/Iframe/Iframe";
import { ComparisonGroupBuilder } from "../index";

const { Option } = Select;
class StaticPage extends Component {
  state = {
    blockUrl: null,
    fetch: true,
    error: null,
    allowUploads: false,
    iFrameReady: false,
    fileData: null,
    fileCategory: "BUS",
    groupsOrganisations: [],
    activeGroupId: null,
    benchmarkForms: null,
  };

  fetchData = (groupID = false) => {
    this.setState({
      allowUploads: false,
      blockUrl: null,
      fetch: true,
      error: null,
      iFrameReady: false,
    });

    return api.static_pages
      .getStaticPageById(
        this.props.match.params.id,
        groupID,
        this.props.user.data.organisation
      )
      .then(({ data }) => {
        if (data) {
          this.setState({
            allowUploads: data["node"].allow_uploads,
            blockUrl: data["node"].url,
            fetch: false,
            activeGroupId: groupID || data["node"].default_comparison_group,
            benchmarkForms: data["node"].linked_webforms,
          });
        } else {
          console.log("throwing: ", data.error);
          throw new Error(data.error);
        }
      })
      .catch((error) => {
        console.log("inside catch: ", error);
        this.setState({
          error: error.response.data.errors[0],
          fetch: false,
        });
      });
  };

  handleFrameLoaded = () => {
    this.setState({ iFrameReady: true });
  };

  onChangeSelect = (id) => {
    this.setState({ activeGroupId: id });
    this.fetchData(id);
  };

  onFileChange = (e) => {
    this.setState({fileData: e.target.files});
  };

  onFileCategoryChange = (e) => {
    this.setState({fileCategory: e.target.value});
  };

  onValidSubmit = async (e) => {
    e.preventDefault();

    var formId = 0;
    if (this.state.benchmarkForms?.length > 0)
      formId = this.state.benchmarkForms[0].id;

    const organisationResult = api.user.getActiveOrganisation()
      .then(async ({data}) => {
        if (data) {
          const organisationId = data.organisation;

          let filesConvertData = [];
          for (let i = 0; i < this.state.fileData.length; i++) {
            const convertedFile = await convertToBase64(this.state.fileData[i])
            const fileInfo = {
              name: this.state.fileData[i]?.name,
              file: convertedFile
            };
            filesConvertData.push(fileInfo);
          }

          const result = await this.props.addFilesForFileManager({
            author: this.props.user?.data?.data?.display_name,
            category: this.state.fileCategory,
            files: filesConvertData,
            folderId: organisationId,
            formId: formId
          });

          this.setState({fileData: null});

          return result;
        }
      });
  };

  componentDidMount() {
    const {
      user: { isGuest },
      app: {
        settings: {
          guest_mode: { comparison_group },
        },
      },
    } = this.props;
    this.fetchData();

    if (!isGuest) {
      Promise.all([api.benchmark.getComparisonGroups()]).then(([{ data }]) => {
        this.setState({
          groupsOrganisations: data.comparison_groups,
        });
      });
    } else {
      this.setState({
        groupsOrganisations: [comparison_group],
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.fetchData();
    }
  }

  render() {
    const {
      blockUrl,
      fetch,
      error,
      allowUploads,
      iFrameReady,
      activeGroupId,
      groupsOrganisations,
      benchmarkForms,
    } = this.state;

    const {
      t,
      app: { settings },
      user: { isGuest },
    } = this.props;

    return (
      <div style={{ height: "100%" }}>
        {/* Possibly to be used at a later time. */}
        {!isGuest && !settings.hide_comparison_group_select && (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: allowUploads ? "space-between" : "flex-end",
            }}
          >
            {allowUploads && (
              <div>
              <form onSubmit={this.onValidSubmit}>
              <div style={{display:"flex", alignItems:"center"}}>
                <div style={{alignItems:"center", border:"2px solid #A0A7B7", borderRadius:"4px", display:"flex", marginRight:"10px", padding:"11px 12px"}}>
                  <UploadOutlined  style={{marginRight:"5px"}}/> {t('choose_file')}
                </div>
                <p style={{margin:"0 10px 0 0"}}>
                  {this.state.fileData?.length > 0 ? `${this.state.fileData.length} files` : ''}
                </p>
                <input
                  multiple={true}
                  name={t('add_file')}
                  onChange={this.onFileChange}
                  placeholder={t('add_file')}
                  style={{opacity:0, padding:"11px 12px", position:"absolute"}}
                  type="file"                  
                />
                <select onChange={this.onFileCategoryChange} style={{border: "2px solid #A0A7B7", borderRadius: "4px", marginRight: "10px", padding: "11px 12px", position:"relative", zIndex: 10}}>
                  <option value={"BUS"}>BUS</option>
                  <option value={"SRG"}>SRG</option>
                  <option value={"BDFS"}>BDFS</option>
                  <option value={"BAS"}>BAS</option>
                </select>
                <Button color="blue" disabled={!this.state.fileData?.length} type="submit" style={{padding:"11px 12px", position:"relative", zIndex: 10}}> {t('export_button')}</Button>
              </div>
              </form>
            </div>
            )}
            
            <div
              style={{
                textAlign: "right",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                margin: "15px 0",
              }}
            >
              <label style={{ margin: "0 10px 0 0" }}>
                {t("select_group")}
              </label>
              <ComparisonGroupBuilder
                staticPage={true}
                pageId={this.props.match.params.id}
                onChangeSelect={this.onChangeSelect}
                benchmarkForms={benchmarkForms}
              />
            </div>
          </div>
        )}

        {(!iFrameReady || fetch) && (
          <div className="d-flex justify-content-center mt-5">
            <Spin />
          </div>
        )}
        {!error && blockUrl ? (
          <div
            style={{ width: "100%", height: "100%", padding: "15px 0" }}
            className="container"
          >
            <Iframe
              source={
                process.env.REACT_APP_BASE_URL +
                "/" +
                window.localStorage.getItem("langCode") +
                blockUrl +
                (activeGroupId ? "?comparison_group=" + activeGroupId : "")
              }
              iFrameReady={iFrameReady}
              frameLoaded={this.handleFrameLoaded}
            />
          </div>
        ) : (
          <div>{error}</div>
        )}
      </div>
    );
  }
}

export default withTranslation("reports")(StaticPage);
