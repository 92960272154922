import React, {useState} from "react";

import './UserGeneratePassword.scss';
import {withTranslation} from "react-i18next";
import {Checkbox, Input} from "antd";
import {generatePassword} from "../../../../utils";

const UserGeneratePassword = ({
  t,
  getUserData
}) => {
  const [passData, setPassData] = useState(null);
  const [valueCheckbox, setValueCheckbox] = useState(['change_after_login', 'send_password_token']);

  const handlerGeneratePassword = () => {
    const pass = generatePassword();
    setPassData(pass);
  }

  const handlerChangeCheckbox = (value) => {
    setValueCheckbox(value);
  }

  const handlerResetPassword = () => {
    let userData = {
      password: passData,
    };
    valueCheckbox.forEach(item => userData[item] = !!item);
    getUserData(userData);
  }

  const optionsCheckbox = [
    {
      label: t('should_change_password_after_login'),
      value: 'change_after_login',
    },
    {
      label: t('send_password_reset_email'),
      value: 'send_password_token',
    }
  ];

  return (
    <div className={'reset__password'}>
      <div className={'content'}>
        <p className={'title'}>{t('change_password')}</p>
        {/*<p className={'last__change'}>*/}
        {/*  {t('last_change_password')}: 2018-09-10 17:40:10*/}
        {/*</p>*/}
        <p className={'label'}>{t('generate_password')}</p>

        <Input
          className={'password__data'}
          value={passData}
          disabled
        />

        <button
          className={'action__button generate__password'}
          onClick={handlerGeneratePassword}
        >{t('generate')}</button>

        <Checkbox.Group
          onChange={handlerChangeCheckbox}
          options={optionsCheckbox}
          value={valueCheckbox}
        />

        <p className={'notes'}>{t('note_password')}</p>

        <button
          className={'action__button'}
          disabled={!passData}
          onClick={handlerResetPassword}
        >{t('save')}</button>
      </div>
    </div>
  );
};

export default withTranslation('user')(UserGeneratePassword);
