import React from "react";

export default function AutoCalculated({element, user}) {
    return (<></>);
    // const fieldProps = {};

    // if (prefix) 
    //     fieldProps.prefix = prefix;

    // if (suffix) 
    //     fieldProps.suffix = suffix;

    // if (placeholder) 
    //     fieldProps.placeholder = placeholder;

    // const newTitle = `${title} ${!!readonly ? "(Read only)" : ""}`;

    // return (
    //     <Form>
    //         <div className="qs-area">
    //             {showHelpComponent && (
    //                 <HelpComponent title={newTitle} help={help} isActive={isActive} isRequired={required}/>
    //             )}

    //             {!!readonly ? (
    //                 <legend>{displayValue}</legend>
    //             ) : (
    //                 <Form.Item {...props} name={key}>
    //                     {isNumeric ? (
    //                         <CustomInputNumber
    //                             {...fieldProps}
    //                             allowClear
    //                             decimalScale={Number(decimalPlaces)}
    //                             decimalSeparator={decimal}
    //                             disabled={(multiset && defaultValue) || disabled || !!hasAutocalculate || (isBasic && isBasicSubmitted) || isCompleted || isInactiveData}
    //                             key={key}
    //                             onValueChange={(v) => this.debounceDefault(key, parentKey, v.formattedValue.replace(/\./g, ""), organisationID, multiset)}
    //                             placeholder={placeholder}
    //                             thousandSeparator={thousand}
    //                             value={displayValue && displayValue.toString().replace(/\./g, decimal)}
    //                         />
    //                     ) : (
    //                         <Input
    //                             allowClear
    //                             disabled={(multiset && defaultValue) || disabled || !!hasAutocalculate || (isBasic && isBasicSubmitted) || isCompleted || isInactiveData}
    //                             key={key}
    //                             mask={mask}
    //                             onChange={(e) => {
    //                                 const v = e.target.value;
    //                                 this.setState((prev) => ({
    //                                     data: {...prev.data, [key]: v},
    //                                 }));

    //                                 this.debounceDefault(key, parentKey, v, required, organisationID, multiset);
    //                             }}
    //                             value={displayValue}/>
    //                     )}
    //                 </Form.Item>
    //             )}

    //             {showMoreComponent && <MoreComponent more={more} moreTitle={moreTitle} />}
    //         </div>
    //     </Form>
    // );
}