import React, { useState, useCallback } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Select } from 'antd'

const chartTypes = ['line', 'column', 'area', 'pie', 'bar']

export default function (props) {
  const {
    benchmarkData = [],
    selectedGroups = [],
    indicators = [],
    dates = []
  } = props
  const [activeIndicator, setActiveIndicator] = useState(indicators[0].webform_indicator)
  const [activeChartType, setActiveChartType] = useState(chartTypes[0])

  const chartData = benchmarkData.reduce((acc, i) => {
    const groupsIds = selectedGroups.map(i => i.id)
    const indicatorData = i.items ? i.items.find(item => item.indicator === activeIndicator): i

    if (groupsIds.map(groupId => Number(groupId)).includes(Number(indicatorData?.group))) {
      return [...acc, {
        name: selectedGroups.find(g => Number(g.id) === Number(indicatorData?.group)).name,
        data: indicatorData.result.map(el => +el.indicator_value)
      }]
    }
    return acc
  }, [])
  return (
    <>
      <Select value={activeIndicator} onChange={v => setActiveIndicator(v)} style={{ marginRight: 20 }}>
        {indicators.map(i => (
          <Select.Option key={i.webform_indicator} value={i.webform_indicator}>{i.title}</Select.Option>
        ))}
      </Select>

      <Select value={activeChartType} onChange={v => setActiveChartType(v)} style={{ width: 100 }}>
        {chartTypes.map(i => (
          <Select.Option key={i} value={i}>{i}</Select.Option>
        ))}
      </Select>

      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            type: activeChartType
          },
          series: chartData,
          xAxis: {
            categories: dates
          }
        }}
      />
    </>
  )
}