import React, {useEffect, useState} from "react";
import {AutoComplete, Spin, Table} from "antd";

import './ModalDrillDownOrganization.scss';

const ModalDrillDown = ({
  getBenchmarkDrillDownForChildOrganizations,

  indicator,
  organization,
  periodStart,
  periodEnd,
  isFetching,
  settingsBenchmark,
  t,
}) => {

  const [drillDownData, setDrillDownData] = useState(null);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState(null);

  useEffect(() => {
    getBenchmarkDrillDownForChildOrganizations({
      batch: true,
      indicator,
      organizations: [organization?.id],
      period_start: periodStart,
      period_end: periodEnd,
      calculation_types: ['current'],
    })
      .then((response) => {
        setDrillDownData(response.data);
      })
      .catch((e) => console.error(e))
  }, [])

  useEffect(() => {
    const columns = [
      {
        title: t('name'),
        dataIndex: 'name',
        key: 'name',
        render: (text) => <p>{text}</p>
      },
      {
        title: t('result'),
        dataIndex: 'current',
        key: 'current',
        render: (text) => <p>{text}</p>
      }
    ];

    setColumns(columns);
  }, [settingsBenchmark])

  useEffect(() => {
    if (!drillDownData) {
      return;
    }

    const data = [];

    for (let i = 0; i < drillDownData?.values?.current?.full?.length; i++) {
      const sortData = {
        key: i,
        name: drillDownData?.values?.current?.full?.[i]?.orgName,
        current: drillDownData?.values?.current?.full?.[i]?.result,
      }

      data.push(sortData);
    }

    setData(data);
  }, [drillDownData])

  return (
    <div className="modal__wrapper">
      {
        isFetching
        ? <Spin size={'large'} />
        : <>
          <div>{drillDownData?.values?.current?.question}</div>
          <Table columns={columns} bordered dataSource={data} pagination={false} style={{ width: '100%' }} />
        </>
      }
    </div>

  )
};

export default ModalDrillDown;
