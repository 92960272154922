import {
  AUTH_SUCCESS,
  AUTH_FETCHING,
  AUTH_FAILED,
  UPDATE_ORGANISATION_FETCHING,
  UPDATE_ORGANISATION_SUCCESS, GUEST_SUCCESS
} from '../types'

import { api } from '../../services'

const getUser = () => dispatch => {
  dispatch({
    type: AUTH_FETCHING,
    payload: true
  })

  return api.user.getUser()
    .then(({ data }) => {
      if (data.tfa && !data.tfa.status) {
        window.location.href = data.tfa.link
        return
      }
      return Promise.resolve()
        .then(() => {
          if (!data.user.organisation && data.user.organisations.length) {
            return dispatch(updateActiveOrganisation({ organisation: data.user.organisations[0].id }))
          }
          return null
        })
        .then(() => {
          if (data.status) {
            dispatch({
              type: AUTH_SUCCESS,
              payload: data.user
            })
          }
          dispatch({
            type: AUTH_FETCHING,
            payload: false
          })
          return data
        })
    })
    .catch(e => {
      const guestMode = window.localStorage.getItem('guest_mode')
      if (guestMode !== 'true') {
        dispatch({
          type: AUTH_FAILED
        })
      }
      else {
        dispatch({
          type: GUEST_SUCCESS
        })
      }
    })
}

const updateActiveOrganisation = body => dispatch => {
  dispatch({
    type: UPDATE_ORGANISATION_FETCHING,
    payload: true
  })

  return api.user.updateActiveOrganisation(body)
    .then(({ data }) => {
      if (data.status) {
        dispatch({
          type: UPDATE_ORGANISATION_SUCCESS,
          payload: body.organisation
        })
      }
      dispatch({
        type: UPDATE_ORGANISATION_FETCHING,
        payload: false
      })
      return body
    })
}

const getOrganisationInfo = id => () => {
  return api.user.getOrganisationInfo(id);
}

export {
  getUser,
  updateActiveOrganisation,
  getOrganisationInfo
}
