import BenchmarkNew from "./BenchmarkNew";
import { connect } from "react-redux";
import actions from '../../store/actions'

const mapStateToProps = (state) => ({
  user: state.user,
  isFetching: state.benchmark.isFetching,
});

const mapDispatchToProps = (dispatch) => ({
  getBenchmarkSettings: () => dispatch(actions.benchmark.getBenchmarkSettings()),
  getBenchmarkForms: () => dispatch(actions.benchmark.getBenchmarkForms()),
})

export default connect(mapStateToProps, mapDispatchToProps)(BenchmarkNew);