import { connect } from 'react-redux';

import ModalDrillDown from "./ModalDrillDown";
import actions from "../../../../store/actions";

const mapStateToProps = (state) => ({
  settingsBenchmark: state.benchmark.benchmarkSettings,
  isFetching: state.benchmark.isFetching,
});

const mapDispatchToProps = (dispatch) => ({
  getBenchmarkDrillDown: (params) => dispatch(actions.benchmark.getBenchmarkDrillDown(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalDrillDown);


