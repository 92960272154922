import { connect } from "react-redux";

import actions from '../../../../store/actions';
import TableBlock from "./TableBlock";


const mapStateToProps = (state) => ({
  user: state.user.data,
});

const mapDispatchToProps = (dispatch) => ({
  deleteFiles: (params) => dispatch(actions.fileManager.deleteFiles(params)),
  downloadFiles: (params) => dispatch(actions.fileManager.downloadFiles(params)),
  getFolderFiles: (params) => dispatch(actions.fileManager.getFolderFiles(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableBlock);

