import React, { useEffect, useState } from "react";
import SegmentationFilter from "./SegmentationFilter";
import { withTranslation } from "react-i18next";

import "./SegmentationFilters.scss";

function SegmentationFilters(props) {
  const { id, onActiveFiltersChanged, popoverId, segmentationFilters, t } = props;

  const [activeFilters, setActiveFilters] = useState(segmentationFilters?.elements?.map(sf => 
  { 
    return { 
      filter: sf.filters.length > 0 ? sf.filters[0] : '', 
      indicator: sf.indicator, 
      max: null, 
      min: null, 
      search: '', 
      searchValues: [], 
      type: sf.type 
    }
  }) ?? []);

  useEffect(() => {
    onActiveFiltersChanged(activeFilters);
  }, [activeFilters]);

  if ((segmentationFilters?.elements?.length ?? 0) == 0) 
    return null;

  const filterChanged = (filter) => {
    const existingFilter = activeFilters.find(f => f.indicator === filter.indicator);
    if (existingFilter == null)
      setActiveFilters([...activeFilters, filter]);
    else
      setActiveFilters(activeFilters.map(f => {
        if (f.indicator === filter.indicator)
          return filter;
  
        return f;
      }));
  }

  const filterCleared = (filter) => {
    setActiveFilters(activeFilters.map(f => {
      if (f.indicator === filter.indicator) {
        f.max = null;
        f.min = null;
        f.search = '';
        f.searchValues = [];
      }

      return f;
    }));
  }

  return (
    <div className={"segmentationfilters-wrapper"}>
      {segmentationFilters.elements.map((item) => (
        <SegmentationFilter filterChanged={filterChanged} filterCleared={filterCleared} filterState={activeFilters.find(f => f.indicator === item.indicator)} item={item} key={item.indicator} popoverId={popoverId}/>
      ))}
    </div>
  );
}

export default withTranslation("benchmark")(SegmentationFilters);
