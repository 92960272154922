import * as t from '../types';
import {FILE_FETCHING_HISTORY, FILE_GET_HISTORY_FILES} from "../types";

const initialState = {
  isFetching: false,
  folderData: null,
  fileData: null,
  historyFile: null,
  isHistoryFetching: false,
  filesOverview: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case t.FILE_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      }

    case t.FILE_GET_FOLDER:
      return {
        ...state,
        folderData: action.payload
      }

    case t.FILE_GET_FILES:
      return {
        ...state,
        fileData: action.payload
      }

    case t.FILE_FETCHING_HISTORY:
      return {
        ...state,
        isHistoryFetching: action.payload,
      }

    case t.FILE_GET_FILES_OVERVIEW:
      return {
        ...state,
        filesOverview: action.payload,
      }

    case t.FILE_GET_HISTORY_FILES:
      return {
        ...state,
        historyFile: action.payload,
      }

    default:
      return state
  }
}