import {connect} from "react-redux";

import UserEdit from './UserEdit'
import actions from '../../../../store/actions';

const mapStateToProps = state => ({
  isFetching: state.profile.isFetching,
  profileUserInfo: state.profile.profileUserInfo,
})

const mapDispatchToProps = (dispatch) => ({
  getUserInfo: (id) => dispatch(actions.profile.getUserInfo(id)),
  updateUserInfo: (id, data) => dispatch(actions.profile.updateUserInfo(id, data)),
  getAllOrganizationForProfile: () => dispatch(actions.profile.getAllOrganizationForProfile()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserEdit)
