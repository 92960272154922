import http from './http'

const getStaticPages = () => {
  return http({
    url: '/api/v1/static-page/list?_format=json',
    method: 'GET'
  })
}

const getStaticPageById = (id, comparisonGroupID = false, organisationID = false) => {
  return http({
    url: `/api/v1/static-page/${id}${comparisonGroupID ? `?comparison_group=${comparisonGroupID}` : ''}${organisationID ? `${comparisonGroupID ? '&' : '?'}organisation_id=${organisationID}` : ''}`,
    method: 'GET'
  })
}

const getStaticPageDocxById = (id) => {
  return http({
    url: `/api/v1/static-page/${id}/docx`,
    method: 'GET'
  })
}

export {
  getStaticPages,
  getStaticPageById,
  getStaticPageDocxById
}