import {
  AUTH_SUCCESS,
  AUTH_FETCHING,
  AUTH_FAILED,
  UPDATE_ORGANISATION_SUCCESS,
  UPDATE_ORGANISATION_FETCHING,
  GET_ORGANISATIONS_SUCCESS,
  GET_ORGANISATIONS_FETCHING, GUEST_SUCCESS
} from '../types'

const initialState = {
  isAuthenticated: false,
  isGuest: false,
  isFetchingAuth: true,
  isUpdatingOrganisation: false,
  isFetchingOrganisations: false,
  data: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isAuthenticated: true,
        isGuest: false
      }

    case GUEST_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isFetchingAuth: false,
        isGuest: true
      }

    case AUTH_FAILED:
      return {
        ...state,
        isAuthenticated: false
      }

    case AUTH_FETCHING:
      return {
        ...state,
        isFetchingAuth: action.payload
      }

    case UPDATE_ORGANISATION_FETCHING:
      return {
        ...state,
        isUpdatingOrganisation: action.payload
      }

    case UPDATE_ORGANISATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          organisation: action.payload
        }
      }

    case GET_ORGANISATIONS_FETCHING:
      return {
        ...state,
        isFetchingOrganisations: action.payload
      }

    case GET_ORGANISATIONS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          organisations: action.payload
        }
      }
    }

    default:
      return state
  }
}
