import React from 'react'

export default () => <svg xmlns="http://www.w3.org/2000/svg" width="18.742" height="18.742" viewBox="0 0 18.742 18.742">
    <g id="Group_2024" data-name="Group 2024" transform="translate(-2.417 -2.417)">
        <path id="Path_356" data-name="Path 356" d="M20.659,19.887a.771.771,0,0,1-.771.771H3.688a.771.771,0,0,1-.771-.771V6.707a.771.771,0,0,1,.226-.545L6.162,3.143a.771.771,0,0,1,.545-.226h13.18a.771.771,0,0,1,.771.771Z" fill="none" stroke="#21416f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_357" data-name="Path 357" d="M21.188,72.917H32.759a.771.771,0,0,1,.771.771V81.4H20.417V73.688A.771.771,0,0,1,21.188,72.917Z" transform="translate(-15.186 -60.743)" fill="none" stroke="#21416f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_358" data-name="Path 358" d="M32.083,2.917H42.111V7.545a.771.771,0,0,1-.771.771H32.855a.771.771,0,0,1-.771-.771Z" transform="translate(-25.31)" fill="none" stroke="#21416f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_359" data-name="Path 359" d="M84.583,14.583h1.543V16.9H84.583Z" transform="translate(-70.867 -10.124)" fill="none" stroke="#21416f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_360" data-name="Path 360" d="M37.917,90.417h5.014" transform="translate(-30.372 -75.929)" fill="none" stroke="#21416f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_361" data-name="Path 361" d="M37.917,107.917H46.4" transform="translate(-30.372 -91.115)" fill="none" stroke="#21416f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    </g>
</svg>
