import { api } from "../../services";

const getSubmissionPeriods = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore().app.settings;
  const params = {
    site_id: settings.site_id,
    url: settings.calculation_url,
    ...data,
  };

  return api.questionnaires.getSubmissionPeriods(params);
};

export {
  getSubmissionPeriods,
};