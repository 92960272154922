import React from 'react'

export default ({ setActive }) => <svg xmlns="http://www.w3.org/2000/svg" width="18.321" height="18.322" viewBox="0 0 18.321 18.322">
  <g id="streamline-icon-paper-write_140x140" data-name="streamline-icon-paper-write@140x140" transform="translate(-2.417 -2.411)">
    <path id="Path_43" data-name="Path 43" style={{ fill: 'none', stroke: setActive ? '#000000' : '#21416f', strokeLinecap: 'round', strokeLinejoin: 'round' }} d="M16.472,32.089v2.636a.753.753,0,0,1-.753.753H3.67a.753.753,0,0,1-.753-.753V21.17a.753.753,0,0,1,.753-.753H5.929" transform="translate(0 -15.246)" />
    <path id="Path_44" data-name="Path 44" style={{ fill: 'none', stroke: setActive ? '#000000' : '#21416f', strokeLinecap: 'round', strokeLinejoin: 'round' }} d="M84.583,20.417h2.259a.753.753,0,0,1,.753.753v2.636" transform="translate(-71.124 -15.246)" />
    <path id="Path_45" data-name="Path 45" style={{ fill: 'none', stroke: setActive ? '#000000' : '#21416f', strokeLinecap: 'round', strokeLinejoin: 'round' }} d="M46.667,4.04a1.13,1.13,0,1,0,1.13-1.13A1.13,1.13,0,0,0,46.667,4.04Z" transform="translate(-38.102 0)" />
    <path id="Path_46" data-name="Path 46" style={{ fill: 'none', stroke: setActive ? '#000000' : '#21416f', strokeLinecap: 'round', strokeLinejoin: 'round' }} d="M31.081,14.583h1.947a.753.753,0,0,1,.753.753v.753a.753.753,0,0,1-.753.753H27a.753.753,0,0,1-.753-.753v-.753A.753.753,0,0,1,27,14.583H28.95" transform="translate(-20.321 -10.166)" />
    <path id="Path_47" data-name="Path 47" style={{ fill: 'none', stroke: setActive ? '#000000' : '#21416f', strokeLinecap: 'round', strokeLinejoin: 'round' }} d="M20.417,55.411h9.037" transform="translate(-15.241 -45.722)" />
    <path id="Path_48" data-name="Path 48" style={{ fill: 'none', stroke: setActive ? '#000000' : '#21416f', strokeLinecap: 'round', strokeLinejoin: 'round' }} d="M20.417,78.744h7.129" transform="translate(-15.241 -66.043)" />
    <path id="Path_49" data-name="Path 49" style={{ fill: 'none', stroke: setActive ? '#000000' : '#21416f', strokeLinecap: 'round', strokeLinejoin: 'round' }} d="M20.417,102.077h6.012" transform="translate(-15.241 -86.364)" />
    <path id="Path_50" data-name="Path 50" style={{ fill: 'none', stroke: setActive ? '#000000' : '#21416f', strokeLinecap: 'round', strokeLinejoin: 'round' }} d="M81.386,56.361l-2.636.753.753-2.636,4.5-4.5a1.331,1.331,0,1,1,1.883,1.883Z" transform="translate(-66.043 -40.647)" />
  </g>
</svg>
