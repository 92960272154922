import { Button, Checkbox, Form, Spin } from "antd";
import Help from "../../../Help";
import More from "../../../More";
import React, { useEffect, useState } from "react";

import "./CheckboxGroup.scss";

export default function CheckboxGroupElement({active, disabled, element, isChild = false, loadingSubmission, onChange, submission}) {
    const [data, setData] = useState(submission?.data?.find((d) => d.key === element.webform_key) ?? null);
    const [value, setValue] = useState(submission?.data?.find((d) => d.key === element.webform_key)?.value);

    useEffect(() => {
        if (!loadingSubmission) {
            setData(submission?.data?.find((d) => d.key === element.webform_key) ?? null);
            setValue(submission?.data?.find((d) => d.key === element.webform_key)?.value);
        }

    }, [loadingSubmission, submission?.data?.find((d) => d.key === element.webform_key)?.value]);

    const isCalculation = !!element.is_autocalculated;
    const isOverwritten = !!(data?.is_overwritten);
    const isPropagation = !!element.is_multilevel && !!element.is_propagation && !!submission && !!submission?.child_organisations?.length && !isOverwritten;
    const multiset = !!(submission?.parent_organisation);
    const readonly = Object.hasOwn(element, 'readonly') ? !!element.readonly : false;
    const autoCalculation = isPropagation || isCalculation;
    const isDisabled = (multiset && data?.is_default_value) || readonly || (submission?.completed && !isChild) || disabled;

    return (
        <>
            {loadingSubmission ? (<Spin/>) : (
                <Form>
                    <div className="qs-area">
                        {!isChild && (
                            <Help active={active} help={element.help} required={element.required} title={element.title} nvh={data?.nvh} nvt={data?.nvt}/>
                        )}

                        <Form.Item>
                            <Checkbox.Group 
                                className="questionnaireCheckboxes"
                                defaultValue={value}
                                disabled={isDisabled || data?.nvh || data?.nvt}
                                key={element.webform_key}
                                onChange={(e) => {setValue(e); onChange(submission.organisation_id, element.webform_key, e);}}
                                options={element.options?.map((i) => ({label: i.value, value: String(i.key)}))}
                                value={value}/>
                        </Form.Item>

                        {!readonly && autoCalculation && (
                            <Button>Waarde berekenen</Button>
                        )}

                        {!isChild && <More more={element.more} moreTitle={element.more_title} />}
                    </div>
                </Form>   
            )} 
        </>
    );
}