import React, {useMemo, useState} from 'react';
import {useParams} from "react-router-dom";
import {withTranslation} from 'react-i18next';
import {Button, Input, Select } from "antd";
import UploadOutlined from "@ant-design/icons/lib/icons/UploadOutlined";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";

import './Header.scss';
import {convertToBase64} from "../../../../utils";

const { Search } = Input;
const { Option } = Select;

const Header = ({
 t,
 showUploadButton = true,
 addFilesForFileManager,
 user,
 handlerSearch,
 getFolderFiles,
 downloadAllFiles,
}) => {

  const [fileData, setFileData] = useState(null);
  const [orgId, setOrgId] = useState(null);

  const { id } = useParams();

  const onSearch = (value) => {
    handlerSearch(value);
  }

  const addFiles = (e) => {
    setFileData(e.target.files);
  }

  const fileUpload = async (file) => {
    let filesConvertData = [];
    for (let i = 0; i < file.length; i++) {
      const convertedFile = await convertToBase64(file[i])
      const fileInfo = {
        name: file[i]?.name,
        file: convertedFile
      };
      filesConvertData.push(fileInfo);
    }

    return await addFilesForFileManager({
      files: filesConvertData,
      folderId: orgId ? orgId : id,
      author: user?.data?.display_name,
    }).finally(() => getFolderFiles({ folderId: id }));
  }

  const handlerSelectOrg = (value) => {
    setOrgId(value);
  }

  const sendFile = async (e) => {
    e.preventDefault();
    await fileUpload(fileData);
  }

  const setFileNames = useMemo(() => {
    if (fileData?.length > 1) {
      return <p className={'upload__name'}>{fileData?.length} files</p>
    } else if (fileData?.length === 1) {
      return <p className={'upload__name'}>{fileData[0]?.name}</p>
    } else {
      return ''
    }
  }, [fileData]);

  const handlerDownloadAllFiles = () => {
    downloadAllFiles({ folderId: id })
  }

  return (
    <header className={'file-manager__header'}>
      <Search
        placeholder={t('input_search')}
        className={'search__block'}
        style={{ marginLeft: 'auto' }}
        onSearch={onSearch}
        allowClear={true}
        enterButton
      />
      {showUploadButton && (
        <div className={'form__block'}>
          <form onSubmit={sendFile}>
            <div className={'import__file'}>
              <div className={'custom__input'}>
                <UploadOutlined /> {t('choose_file')}
              </div>
              {setFileNames}
              <input
                type="file"
                onChange={addFiles}
                multiple={true}
                name={t('add_file')}
                placeholder={t('add_file')}
              />
              <Button disabled={!fileData?.length} htmlType={'submit'}> {t('export_button')}</Button>
            </div>
          </form>
          <div className={'actions__block'}>
            <Select
              placeholder={t('organization')}
              className={'select__organization'}
              onChange={handlerSelectOrg}
            >
              {user?.organisations?.map(item => (
                <Option key={item.id} value={item.id}>{item.title}</Option>

              ))}
            </Select>
            <Button
              type={'primary'}
              className={'download__all'}
              onClick={handlerDownloadAllFiles}
            >
              <DownloadOutlined />
              {t('download_all_files')}
            </Button>
          </div>
        </div>
      )}
    </header>
  )
};

export default withTranslation('file_manager')(Header) ;