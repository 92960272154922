import React from 'react'
import ReactDOM from 'react-dom'

class Iframe extends React.Component {
  render () {
    const { frameLoaded, iFrameReady } = this.props
    return <iframe
      src={this.props.source}
      srcDoc={this.props.srcDoc}    
      height='100%'
      style={{ width: '100%', border: 'none', overflow: 'visible', visibility: iFrameReady ? 'visible' : 'hidden', minHeight: '80vh' }}
      onLoad={(e) => {
        setTimeout(() => {
          const obj = ReactDOM.findDOMNode(this)
          frameLoaded()
        }, 50)
      }} />
  }
}

export default Iframe
