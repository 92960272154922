import { connect } from 'react-redux'

import Dashboard from './Dashboard'

const mapStateToProps = state => ({
  user: state.user,
  app: state.app
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
