import FavoritesGridRow from "./FavoritesGridRow";
import { NoRecords } from "../../../../components";
import React from "react";

export default function FavoritesGrid({
  active_organisation,
  calculation_types,
  comparison_groups,
  elementsData,
  favoriteIndicators,
  getBenchmarkValuesForIndicator,
  handleDeleteFavorite,
  individual_organisations,
  period_end,
  period_start,
  settingsBenchmark,
  t,
  user,
}) {
  return (
    <>
      {!!favoriteIndicators ? (
        favoriteIndicators?.sort((a, b) => a.indicator.localeCompare(b.indicator))?.map((item, index) => (
          <FavoritesGridRow
            active_organisation={active_organisation}
            calculation_types={calculation_types}
            category={item.category}
            comparison_groups={comparison_groups}
            description={elementsData?.find(element => element.webform_indicator === item.indicator)?.description || ""}
            getBenchmarkValuesForIndicator={getBenchmarkValuesForIndicator}
            handleDeleteFavorite={handleDeleteFavorite}
            index={index}
            indicator={item.indicator}
            individual_organisations={individual_organisations}
            key={`favorites-gridrow-${item.indicator}`}
            period_end={period_end}
            period_start={period_start}
            settingsBenchmark={settingsBenchmark}
            t={t}
            title={item.title}
            user={user}
          />
        ))
      ) : (
        <NoRecords />
      )}
    </>
  );
}
