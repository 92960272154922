import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import {Checkbox, Input, AutoComplete, Pagination} from "antd";
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import LeftOutlined from "@ant-design/icons/lib/icons/LeftOutlined";

import SearchCollapse from "./SearchCollapse";
import './SearchPage.scss';
import { NoRecords, Loading } from "../../../../components";
import {withTranslation} from "react-i18next";
import history from "../../../../history";

const PAGE_SIZE = 7;

const SearchPage = ({
  t,
  searchBenchmarkData,
}) => {
  const { id, formTitle, value } = useParams();

  const checkBoxData = [
    { label: t('category'), value: t('category') },
    { label: t('subcategory'), value: t('subcategory') },
    { label: t('indicators'), value: t('indicators') },
    { label: t('exact_match'), value: t('exact_match') },
  ];

  const checkedData = [
    t('category'),
    t('subcategory'),
    t('indicators')
  ];

  const [searchValue, setSearchValue] = useState(value || null);
  const [searchResultValue, setSearchResultValue] = useState(value || null);
  const [paginationData, setPaginationData] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState(checkedData);
  const [dataSearch, setDataSearch] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    totalPage: 0,
    minIndex: 0,
    maxIndex: 0
  });

  const generateSearchData = (elements) => {
    const data = [];
    const result = elements?.map(item => {
      const obj = {
        data: item
      };
      data.push(obj);
      return item;
    })
    return data;
  };

  const fetchSearchData = async () => {
    const searchParams = {
      formId: id,
      q: searchValue,
      is_category: +!!(checkboxValues?.find(item => item === t('category'))),
      is_sub_category: +!!(checkboxValues?.find(item => item === t('subcategory'))),
      is_indicator: +!!(checkboxValues?.find(item => item === t('indicators'))),
      is_exact: +!!(checkboxValues?.find(item => item === t('exact_match'))),
    };

    setShowLoader(true);
    await searchBenchmarkData(searchParams)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          setDataSearch(data?.elements);
          setPagination({
            current: 1,
            totalPage: generateSearchData(data?.elements)?.length / PAGE_SIZE,
            minIndex: 0,
            maxIndex: PAGE_SIZE
          })
        }
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setShowLoader(false);
      });
  };

  useEffect(  () => {
    fetchSearchData();
  }, [checkboxValues]);

  useEffect(() => {
    generatePaginationData();
  }, [pagination.current, pagination.maxIndex, dataSearch]);

  const handlerChangeCheckbox = async (value) => {
    const checkboxData = value?.map(item => item)
    setCheckboxValues(checkboxData);
  }

  const handlerInputSearchValue = (e) => {
    setSearchValue(e.target.value);
  }

  const activeSearch = async () => {
    setSearchResultValue(searchValue);
    if (searchValue) {
      const value = searchValue.replace(/#/gi, '');
      history.push(`/benchmark/${id}&title=${formTitle}/searchValue=${value}`)
      await fetchSearchData();
    }
  }

  const handleChangePage = (page) => {
    setPagination({
      current: page,
      totalPage: generateSearchData(dataSearch)?.length / PAGE_SIZE,
      minIndex: (page - 1) * PAGE_SIZE,
      maxIndex: page * PAGE_SIZE
    });
  }

  const handlerComeBack = () => {
    history.push(`/benchmark/${id}&title=${formTitle}`)
  }

  const generatePaginationData = () => {
    const result = []
    const data = generateSearchData(dataSearch)?.map((item, index) =>
      index >= pagination?.minIndex &&
      index < (pagination?.maxIndex || PAGE_SIZE) && result.push(item));

    setPaginationData(result);
  }

  const handlerSelectSearchValue = async (value) => {
    setSearchValue(value)
    await activeSearch();
  }

  return (
    <div className={'search-page'}>
      {showLoader && <Loading />}
      <div className={'come__back'} onClick={handlerComeBack}>
        <LeftOutlined />
        <span>{t('come_back')} to benchmark page</span>
      </div>
      <div className={'title'}>{formTitle}</div>
      <div className={'search-result'}>{generateSearchData(dataSearch)?.length } result for
        <span> {value}</span>
      </div>

      <div className={'input__block'}>
        <AutoComplete
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          onSelect={handlerSelectSearchValue}
        >
          <Input
            defaultValue={searchValue || ''}
            placeholder={'Search...'}
            allowClear
            suffix={<SearchOutlined onClick={activeSearch} />}
            onChange={handlerInputSearchValue}
            onPressEnter={activeSearch}
          />
        </AutoComplete>

        <Checkbox.Group
          className={'checkbox-group'}
          options={checkBoxData}
          value={checkboxValues}
          onChange={handlerChangeCheckbox}
        />
      </div>
      <div className={'search__reports'}>
        {generateSearchData(dataSearch)?.length
          ? paginationData?.map((item, index) => (
              <SearchCollapse
                key={item?.data?.indicator + index}
                searchData={item?.data}
                searchResultValue={searchResultValue}
                periodName={item?.period?.name}
                t={t}
                value={value}
              />
          ))
          : <NoRecords />
        }
      </div>
      {
        generateSearchData(dataSearch)?.length > 7 &&
        <Pagination
          showSizeChanger={false}
          pageSize={PAGE_SIZE}
          current={pagination?.current}
          total={generateSearchData(dataSearch)?.length}
          onChange={handleChangePage}
        />
      }
    </div>
  );
};

export default withTranslation('benchmark')(SearchPage);
