import {Charts, Kart} from "./components";
import React from "react";
import { Tabs } from 'antd';

import './ModalCharts.scss';

const { TabPane } = Tabs;

const ModalCharts = ({
  activeOrganization,
  checkedColumns,
  comparisonGroup,
  decimalPlaces,
  defaultTabKey,
  drillDownGroups,
  endDate,
  indexCharts,
  indicator,
  mapFile,
  mapOrgData,
  modalCharts,
  modalValue,
  modalVisible,
  optionList,
  organisationData,
  periodType,
  settingsBenchmark,
  showMap,
  startDate,
  t
}) => {

  return (
    <div className={'modal__charts'} key={defaultTabKey}>
      <div className={'modal__indicators'}>
        {modalValue?.webform_indicator}
      </div>

      <Tabs defaultActiveKey={defaultTabKey}>
        <TabPane tab={'Grafiek'} key={'charts'}>
          <Charts
            activeOrganization={activeOrganization}
            checkedColumns={checkedColumns}
            comparisonGroup={comparisonGroup}
            element={modalValue}
            endDate={endDate}
            indexCharts={indexCharts}
            indicator={indicator}
            key={'charts'}
            modalCharts={modalCharts}
            modalVisible={modalVisible}
            organisationData={organisationData}
            periodType={periodType}
            settingsBenchmark={settingsBenchmark}
            startDate={startDate}
            t={t}
          />
        </TabPane>

        {showMap && (
          <TabPane tab={'Kaart'} key={'map'}>
            <Kart
              activeOrganization={activeOrganization}
              comparisonGroup={comparisonGroup}
              decimalPlaces={decimalPlaces}
              drillDownGroups={drillDownGroups}
              endDate={endDate}
              indicator={indicator}
              key={'map'}
              mapFile={mapFile}
              mapOrgData={mapOrgData}
              modalVisible={modalVisible}
              optionList={optionList}
              organisationData={organisationData}
              startDate={startDate}
              t={t}
            />
          </TabPane>
        )}
      </Tabs>
    </div>
  )
};

export default ModalCharts;
