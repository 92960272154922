import validator from "validator";

export const validatePhoneNumber = (e) => {
  return (e.target.value = e.target.value.replace(/[^ 0-9+()]/g, ''));
};

export const validatePasswordStrong = (message) => {

  return () => ({
    validator(_rule, value) {
      if (!checkStrong(value)) {
        return Promise.reject(new Error(message || 'Is not a strong password!'));
      }
      return Promise.resolve();
    }
  })
};

export const validatePasswordMatch = (fieldToMatch, message) => {
  return ({ getFieldValue }) => ({
    validator(_rule, value) {
      if (!value || getFieldValue(fieldToMatch) === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(message));
    },
  });
};

export const checkStrong = (value) => {
  if (validator.isStrongPassword(value, {
    minLength: 12,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 1,
  })) {
    return true;
  } else {
    return false;
  }
};