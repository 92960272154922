import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import {Collapse, Spin, Tooltip} from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import SaveOutlined from "@ant-design/icons/lib/icons/SaveOutlined";

import icon_conversation from '../../../../assets/images/svg/conversation-chat-text.svg'
import information_desk from '../../../../assets/images/svg/information-desk.svg'

import './Wrapper.scss'
import moment from "moment";

const { Panel } = Collapse;

class Wrapper extends Component {
  state = {
    visibleInfo: false,
    loading: false,
    daughterOrg: [],
  }

  onSetVisibleInfo = () => {this.setState({visibleInfo: !this.state.visibleInfo})}

  handlerOpenCollapse = async (indicator) => {
    const array = [...this.props.user?.organisation_tree];
    this.setState({loading: true});

    for (let i = 1; i < array?.length; i++) {
      this.props.fetchData(this.props.parentKey, false, array?.[i]?.id)
        .then((response) => {
          const stateData = [...this.state.daughterOrg];
          const hasElementIndicator = response?.find(r => r.element_indicator === indicator.element_indicator)
          if (hasElementIndicator) {
            stateData.push(hasElementIndicator);
            this.setState({daughterOrg: stateData})
          }
        })

      if (array.length - 1 === i)
        this.setState({loading: false})
    }
  }

  render() {
    const {
      content,
      Item,
      Tags,
      options,
      settings,
      user,
      mapElementModal,
      modalElements,
      permissions,
      t,
      fetchData,
      parentKey,
      userInfo,
      updateForm,
      wasChanges,
      handleSubmit,
      activeItemModal
    } = this.props

    const setTooltipText = (id) => (
      <ul className={'tooltip__list'}>
        <li>Last update: {moment(updateForm?.[id]).format('YYYY-MM-DD HH:mm:ss')}</li>
        <li>User name: {userInfo[id]?.name}</li>
        <li>User role: {userInfo[id]?.role?.join(', ')}</li>
      </ul>
    )

    const { visibleInfo, daughterOrg, loading } = this.state;

    return (
      <>
        {
          Item.is_visible
          ? <div className='mainWrapper'>
            <div className='indicator'>
              {Item.webform_indicator}
              {
                (Item.is_autocalculated && wasChanges)
                  ? <SaveOutlined onClick={() => handleSubmit(activeItemModal,modalElements)} />
                  : null
              }
            </div>
            {content}
            {
              !!Item.is_multilevel && !(user?.parent_organisation > 0) && user?.organisation_tree?.length > 1
                ? (
                  <Collapse onChange={() => this.handlerOpenCollapse(Item)}>
                    <Panel
                      header={`${t('multi_organizations')} ${user?.organisation_tree?.length - 1}/${user?.organisation_tree?.length - 1}`}
                      key={'1'}
                    >
                      <div className={'header'}>
                        <p>{t('organizations')}</p>
                        <p>{t('worth')}</p>
                        <p>{t('status')}</p>
                      </div>
                      {loading
                        ? <Spin />
                        : user?.organisation_tree?.slice(1)?.map((item, index) => (
                          <div className={'multi__organizations'} key={item.id}>
                            {
                              daughterOrg[index]
                                ? <Tooltip title={setTooltipText(item.id)} placement={'left'}>
                                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <p className={'title'}>{item.title}</p>
                                    <div className={'content'}>
                                      {mapElementModal(daughterOrg[index], false, false, item.id, true, false)}
                                    </div>
                                    <div className={'status'}><CheckCircleOutlined /></div>
                                  </div>
                                </Tooltip>
                                : <Spin />
                            }
                          </div>
                        ))}
                    </Panel>
                  </Collapse>
                )
                : null
            }
            {(Item.webform_tags.length > 0 || options.show_description_popup) && <div className='footerWrapper'>
              <div className='containerTags'>
                {Item.webform_tags.map(item => Tags.map(i => item === i.id && <div className='tagItem'>{i.name}</div>))}
              </div>
              {permissions.view_comments && options.show_description_popup && <div className='containerButton'>
                <div className='comments' onClick={e => this.props.onClick(Item)}>
                  <img src={icon_conversation} alt='' />
                  <span style={{ marginLeft: 10 }} >{t('comments')}</span>
                </div>
              </div>}
            </div>}
          </div>
          : null
        }
      </>
    )
  }
}

export default withTranslation('questionnaire')(Wrapper)