import React, { useEffect, useMemo, useState } from "react";
import { Input, message, Select, Tooltip, Modal } from "antd";
import { useParams } from "react-router-dom";

import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import QuestionCircleOutlined from "@ant-design/icons/lib/icons/QuestionCircleOutlined";
import BenchmarkSave from "../../../../assets/images/svg/benchmarkSave";

import { showConfirm } from "./confirm";
import history from "../../../../history";

const { confirm } = Modal;
const { Option } = Select;

const ReportFilters = ({
  t,
  periodsData,
  periodsDefault,
  steps,
  activeCategoryTitle,
  category,
  allGroups,
  activeGroupTitle,
  settingsBenchmark,
  savedViews,
  deleteBenchmarkSpecialView,
  getBenchmarkViewList,
  handlePeriodChange,
  handleCategoryChange,
  handlerOpenViewModal,
  handlerChangeSubcategory,
  handlerGetSpecialView,
  handlerSetSteps,
}) => {
  const { id, formTitle } = useParams();

  const [searchValue, setSearchValue] = useState(null);
  const [activeView, setActiveView] = useState("");

  useEffect(() => {handlerGetSpecialView(activeView)}, [activeView]);
  useEffect(() => {getBenchmarkViewList(id)}, [id]);

  const setDrilldownOwnHierarchy = useMemo(() => {
    return (
      settingsBenchmark?.show_drilldown_in_own_hierarchy === "yes" && (
        <>
          <div className={"report__filters--select"}>
            {savedViews?.length > 0 && (
              <Select
                value={activeView}
                optionLabelProp="label"
                onChange={(value) => setActiveView(value)}
              >
                <Option value={""} key={""} label={t("saved_views")}>
                  <Tooltip placement="left" title={t("saved_views")}>
                    <p>{t("saved_views")}</p>
                  </Tooltip>
                </Option>

                {savedViews?.map((item) => (
                  <Option value={item.id} key={item.id} label={item.name}>
                    <div className={"saved__views--option"}>
                      <Tooltip placement="left" title={item.name}>
                        <p>{item.name}</p>
                      </Tooltip>
                      <DeleteOutlined
                        onClick={() => {
                          const params = {
                            title: t("delete_modal_title"),
                            confirm,
                            icon: (
                              <QuestionCircleOutlined
                                style={{ color: "red" }}
                              />
                            ),
                            okFunc: () => handlerDeleteSpecialView(item.id),
                            cancelText: t("cancel"),
                          };
                          showConfirm(params);
                        }}
                      />
                    </div>
                  </Option>
                ))}
              </Select>
            )}
          </div>
          {steps === 4 && (
            <div
              className={"report__saves"}
              onClick={() => handlerOpenViewModal(true)}
            >
              <p className={"report__saves--text"}>{t("save_view")}</p>
              <BenchmarkSave className={"report__saves-img"} />
            </div>
          )}
        </>
      )
    );
  }, [settingsBenchmark, steps, savedViews, activeView]);

  const handlerDeleteSpecialView = (viewId) => {
    deleteBenchmarkSpecialView(viewId)
      .then((response) => {
        const { status } = response;

        if (status === 200) {
          message.success(t("delete_success"));
          getBenchmarkViewList(id);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => {
        if (activeView === viewId) {
          periodsData?.length ? handlerSetSteps(1) : handlerSetSteps(2);
        }

        setActiveView("");
      });
  };

  const searchReport = () => {
    if (searchValue) {
      const value = searchValue.replace(/#/gi, "");
      history.push(`/benchmark/${id}&title=${formTitle}/searchValue=${value}`);
    }
  };

  const searchInputValue = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && searchValue) {
      searchReport();
    }
  };

  return (
    <div className={"report__filters"}>
      <div className={"report__filters-selects"}>
        {periodsData?.length ? (
          <div className={"report__period  report__selects"}>
            <p className="analyse-list__period-title">{t("period")}</p>
            <Select
              value={periodsDefault || t("show_all")}
              className={"analyse-list__select"}
              bordered={false}
              onChange={(value) => handlePeriodChange(value)}
            >
              <Option value={t("show_all")}>{t("show_all")}</Option>
              {periodsData?.map((item) => (
                <Option value={item.name} key={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
        ) : (
          ""
        )}
        {steps > 1 && (
          <div className={"report__category report__selects"}>
            <p>{t("category")}</p>
            <Select
              value={activeCategoryTitle || t("show_all")}
              bordered={false}
              onChange={(value) => handleCategoryChange(value)}
            >
              <Option value={t("show_all")}>{t("show_all")}</Option>
              {category?.length
                ? category?.map((item) => (
                    <Option value={item.title} key={item.element_indicator}>
                      {item.title}
                    </Option>
                  ))
                : null}
            </Select>
          </div>
        )}
        {activeCategoryTitle !== t("show_all") &&
          steps > 2 &&
          allGroups?.length > 0 && (
            <div className={"report__group report__selects"}>
              <p>{t("subcategory")}</p>
              <Select
                value={activeGroupTitle || "No data"}
                bordered={false}
                onChange={(value) => handlerChangeSubcategory(value)}
              >
                <Option value={t("show_all")}>{t("show_all")}</Option>
                {allGroups?.length
                  ? allGroups?.map((item) => (
                      <Option value={item.title} key={item.element_indicator}>
                        {item.title}
                      </Option>
                    ))
                  : null}
              </Select>
            </div>
          )}
      </div>
      <div className={"report__filters-inputs"}>
        <div className={"report__inputs"}>
          <Input
            placeholder={t("search_in_questionnaire")}
            value={searchValue}
            suffix={
              <SearchOutlined
                style={{ cursor: "pointer" }}
                onClick={searchReport}
              />
            }
            onKeyDown={handleKeyDown}
            className={"custom__input"}
            onChange={searchInputValue}
          />
        </div>

        {setDrilldownOwnHierarchy}
      </div>
    </div>
  );
};

export default ReportFilters;
