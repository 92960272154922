import { connect } from 'react-redux';
import SavedView from './SavedView';
import actions from "../../../../store/actions";

const mapStateToProps = (state) => ({
  settingsBenchmark: state.benchmark.benchmarkSettings,
  isFetching: state.benchmark.isFetching,
  forms: state.benchmark.benchmarkForms,
  comparisonGroups: state.benchmark.comparisonGroups || [],
});

const mapDispatchToProps = (dispatch) => ({
  getBenchmarkOrgDataForCategory: (params) => dispatch(actions.benchmark.getBenchmarkOrgDataForCategory(params)),
  getBenchmarkSpecialView: (id) => dispatch(actions.benchmark.getBenchmarkSpecialView(id)),
  getBenchmarkDrillDown: (params) => dispatch(actions.benchmark.getBenchmarkDrillDown(params)),
  getBenchmarkDataForCategory: (params) => dispatch(actions.benchmark.getBenchmarkDataForCategory(params)),
  getBenchmarkSettings: () => dispatch(actions.benchmark.getBenchmarkSettings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SavedView);


