export const setUrlParams = (
  url,
  queryParams
) => {
  const urlParams = new URLSearchParams();

  if (!queryParams) {
    return url;
  }

  Object.keys(queryParams).forEach((param) => {
    const value = queryParams[param];

    if (value) {
      urlParams.append(param, value.toString());
    }
  });

  return `${url}?${urlParams.toString()}`;
};
