import { connect } from 'react-redux';

import ModalDrillDownOrganization from "./ModalDrillDownOrganization";
import actions from "../../../../store/actions";

const mapStateToProps = (state) => ({
  settingsBenchmark: state.benchmark.benchmarkSettings,
  isFetching: state.benchmark.isFetching,
});

const mapDispatchToProps = (dispatch) => ({
  getBenchmarkDrillDownForChildOrganizations: (params) => dispatch(actions.benchmark.getBenchmarkDrillDownForChildOrganizations(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalDrillDownOrganization);


