import React, {Fragment, Component} from 'react'
import { Redirect, Route } from 'react-router-dom'
import { Container } from 'reactstrap'
import { withTranslation } from 'react-i18next'

import { Spin } from 'antd'

import {CustomHeader, Footer, Sidebar, Topbar} from '../'
import './PrivateRoute.scss';

const styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  background: 'rgba(0, 0, 0, 0.05)',
  height: '100vh'
}

class PrivateRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hideMenu: false,
    }
  }

  componentDidMount () {
    Promise.all([
      this.props.getSiteSetting(),
    ])
      .then(this.props.getUser)
      .then(() => {
        this.props.getTags();
        this.props.getBenchmarkSettings();
        const { settings } = this.props;

        if (settings?.css_styles !== '') {
          this.setCSSFromAPI(settings?.css_styles)
        }
      });

  }

  /**
   * Function setCSSFromAPI help set styles from drupal site and added for react site, with help tag link.
   * @param styles takes styles what should be add
   */
  setCSSFromAPI = (styles) => {
    const head = document.getElementsByTagName('head')[0];
    const stylesheet = document.createElement('link');
    stylesheet.rel = 'stylesheet';
    stylesheet.href = 'data:text/css,' + escape(styles);  // IE needs this escaped
    head.appendChild(stylesheet);
  }

  hideMenu = (hide) => {
    this.setState({
      hideMenu: hide,
    })
  }

  render () {
    const {
      component: Component,
      user: { isAuthenticated, isFetchingAuth, isGuest },
      settings,
      ...rest
    } = this.props

    return <Route {...rest}
      render={props =>
          (isFetchingAuth)
          ? <div style={styles}>
            <Spin size='large' />
          </div>
          : ((isAuthenticated && window.localStorage.getItem('accessToken')) || isGuest)
            ? <div className='app'>
              <div id='wrapper'>
                <Fragment>
                  {settings?.site_header?.value   && <CustomHeader />}
                  <Topbar {...this.props} />
                  <Sidebar {...this.props} hideMenu={this.hideMenu}/>
                </Fragment>
                <div className='content-page'>
                  <div
                    className='content'
                    style={{
                      height: `${settings?.site_header?.value ? 'calc(100vh - 140px)' : 'calc(100vh - 70px)'}`
                    }}
                  >
                    <Container
                      className={'content__container'}
                      fluid
                      style={{
                        height: '100%',
                        overflowY: 'auto',
                        padding: '0'
                    }}
                    >
                      <div
                        style={{ marginLeft: `${this.state.hideMenu ? '0' : '252px'}` }}
                      >
                        <Component {...props} {...this.props}  />
                      </div>
                      {settings?.site_footer && <Footer />}
                    </Container>
                  </div>
                </div>
              </div>
            </div>
            : (<Redirect to={{ pathname: '/login' }} />)
      }
    />
  }
}

export default withTranslation()(PrivateRoute)
