export default {
  general: {
    all_questionnaires: 'Alle vragenlijsten',
    all_reports: 'Alle Rapporten',
    apply: 'Toepassen',
    benchmark: 'Benchmark',
    cancel: 'Annuleren',
    dashboard: 'Dashboard',
    edit_organisations: 'Bewerk projecten',
    file_manager: 'Bestandsbeheer',
    file_overview: 'Dossier overzicht',
    home: 'Home',
    latest_questionnaires: 'Recente vragenlijsten',
    latest_reports: 'Recente rapporten',
    logout: 'Uitloggen',
    no: 'Nee',
    ok: 'Ok',
    organisations: 'Projecten',
    question: 'Vraag ',
    questionnaire: 'Vragenlijst',
    questionnaires: 'Vragenlijsten',
    questions: 'Vragen',
    reports: 'Rapporten',
    show_more: 'Laat meer zien',
    users: 'Gebruikers',
    view_all_questionnaires: 'Bekijk alle vragenlijsten',
    view_all_reports: 'Bekijk alle rapporten',
    welcome_back: 'Welkom terug',
    yes: 'Ja',
  },
  benchmark: { 
    add_filter: 'Filter toevoegen',
    add_new_group: 'Groep aanmaken',
    add_to_comparison_groups: 'Toevoegen aan vergelijkingsgroep',
    apply: 'Toepassen',
    average: 'Gemiddelde',
    beepal_comparison_group_name: 'Bepaal naam vergelijkingsgroep',
    cancel: 'Annuleren',
    card: 'Kaart',
    category: 'Categorie',
    characteristic: 'Kenmerk',
    chart: 'Grafiek',
    сlear_all: 'Alles wissen',
    сollapse_all_close: 'Alles uitklappen', 
    сollapse_all_open: 'Alles inklappen',
    come_back: 'Terug',
    compare_to: 'Vergelijk met',
    comparison_group: 'Vergelijkingsgroep',
    comparison_groups: 'Vergelijkingsgroepen',
    create_comparison_group: 'Vergelijkingsgroep aanmaken',
    create_new_group: 'Nieuwe vergelijkingsgroep maken',
    delete: 'Delete',
    delete_comparison_group: 'Vergelijkingsgroep verwijderd!',
    delete_modal_title: 'Wilt u deze weergave verwijderen?',
    delete_success: 'Succes verwijderen!',
    description: "Omschrijving",
    download: 'Downloaden',
    drilldown: 'Waardes vergelijkingsgroep',
    drilldown_organisation: 'Onderliggende organisatie',
    edit_group: 'Vergelijking bewerken',
    exact_match: 'Exacte overeenkomst',
    expand_child_menu: 'Alles uitklappen',
    extra_columns_definition: 'van alle organisaties met ingevulde data voor de geselecteerde periode',
    favorite_indicators: 'Favoriete indicatoren',
    feature: 'Kenmerk',
    fill_single_field_for_specific_search: 'Vul één veld in om op een specifieke waarde te zoeken',
    filter: 'Filter',
    filter_clear: 'Legen',
    filter_type_checkboxes: 'Checkboxes',
    filter_type_number: 'Getal',
    filter_type_select: 'Select',
    filter_type_textfield: 'Tekst',
    indicators: 'Indicatoren',
    individual_organizations_title: 'Beschikbare organisaties',
    individual_organizations_subtitle: 'U kunt maximaal 5 organisaties toevoegen',
    search_in_organizations: 'Zoek in organisaties',
    input_name: 'Vul in...',
    level: 'Peil',
    max: 'Max',
    maximum: 'Maximum',
    median: 'Mediaan',
    min: 'Min',
    minimum: 'Minimum',
    my_comparison_group: 'Mijn vergelijkingsgroep',
    my_organisations: 'Mijn organisaties',
    name: 'Naam',
    name_in_reports: 'Kies een duidelijk herkenbare naam voor deze vergelijkingsgroep. Deze naam komt terug in rapportages.',
    name_of_view: 'Geef de weergave een naam',
    no_permissions: 'Je hebt momenteel geen toegang om deze benchmark te bekijken',
    not_comparison_group: 'Geen vergelijkingsgroep selecteren',
    number_of_values: 'Aantal waarden',
    organization_search: 'Organisaties zoeken',
    organizations: 'organisaties',
    other_organisations: 'Andere organisaties',
    percentile: 'Percentiel',
    period: 'Periode',
    question_group: 'Vragengroep',
    questionnaire: 'Vragenlijst',
    replace_comparison_groups: 'Vervang vergelijkingsgroep',
    result: 'Resultaat',
    save: 'Opslaan',
    save_favorite: 'Favorieten opslaan',
    save_group: 'Groep opslaan',
    save_view: 'Weergave opslaan',
    saved_comparison_groups: 'Opgeslagen vergelijkingsgroepen',
    saved_favorites: 'Opgeslagen favorieten',
    saved_views: 'Opgeslagen weergaven',
    search: 'Zoeken...',
    search_in_questionnaire: 'Zoeken in vragenlijst',
    search_naar: 'Zoeken naar...',
    search_number: 'Zoek getal',
    search_result: 'Zoekresultaat',
    select: 'Selecteer',
    select_all: 'Alles selecteren',
    select_individual_organizations: 'Selecteer individuele organisaties',
    selected: 'geselecteerd',
    show: 'Toon',
    show_all: 'Alles',
    show_more_lists: 'Toon meer lijsten',
    show_statistics: 'Toon statistieken',
    subcategory: 'Subcategorie',
    sum: 'sum',
    table_settings: 'Tabel Instellingen',
    text_filter_contains: 'Komt voor in',
    text_filter_endswith: 'Eindigt met',
    text_filter_startswith: 'Begint met',
    to_show: 'Tonen',
    view_questionnaire: 'Start benchmark',
    view_saved_successfully: 'Weergave succesvol opgeslagen',
    warning_org_text_end: ' organisaties. In de tabel worden statistieken getoond. Als u toch individuele scores wilt bekijken: verklein het aantal organisaties in de vergelijkingsgroep, of kies voor Grafiekweergave.',
    warning_org_text_start: 'De vergelijkingsgroep bestaat uit meer dan ',
    warning_text: 'Mogelijk dubbele telling van resultaten. Pas de vergelijkingsgroep aan.\n' +
      'De volgende resultaten maken op een hoger niveau al onderdeel uit van de vergelijkingsgroep',
    warning_title: 'Let op',
    your_organisation: 'Uw organisatie',
    your_value: 'Uw waarde',
  },
  error: {
    back_home: 'Home',
    back_to: ' of terug naar ',
    back_to_the: 'Terug naar de ',
    prev_page: 'vorige pagina',
    return_text: 'Terug',
    text: 'U heeft geen toestemming om deze pagina te openen. Neem contact op met uw sitebeheerder(s) om toegang aan te vragen.',
    title: 'Toegang geweigerd',
  },
  errors: {
    field_is_invalid: 'Ongeldige invoer'
  },
  file_manager: {
    action: 'Actie',
    add_file: 'Bestand toevoegen',
    author: 'Gebruiker',
    back_to_prev: 'Terug naar de vorige pagina',
    choose_file: 'Kies bestand',
    download_all_files:'Alle bestanden downloaden',
    edit: 'Bewerk',
    edit_file: 'Bestand bewerken',
    export_button: 'Uploaden',
    file: 'Bestand',
    filename: 'Bestandsnaam',
    history: 'Geschiedenis',
    input_search: 'Zoek naar...',
    last_modified: 'Laatst gewijzigd',
    organization: 'Organisatie',
    rules: 'reglement',
    save: 'Opslaan',
    upload_date: 'Datum upload',
    upload_file: 'Upload bestand', 
  },
  header: {
    address: 'adres',
    back_to_list: 'Terug naar Vragenlijsten',
    country_code: 'Landcode',
    email: 'E-mail',
    level: 'Peil',
    logout: 'Uitloggen',
    modal_title: 'Organisatie info',
    phone_number: 'Telefoonnummer',
    search: 'Zoeken',
    title: 'titel',
  },
  help_center: {
    any_questions_block: 'Nog vragen? Bezoek ons ​​Helpcentrum.',
    go_to_help_center: 'Ga naar Helpcentrum',
  }, 
  individual_organisations: {
    apply: 'Toepassen',
    individual_organizations_title: 'Beschikbare organisaties',
    select_individual_organizations: 'Selecteer organisaties'
  },
  loader: {
    text: 'Pagina wordt geladen...',
  },
  login: {
    enter_password: 'Vul uw wachtwoord in',
    enter_username: 'Vul uw gebruikersnaam in',
    forgot_password: 'Uw wachtwoord vergeten?',
    keep_me_logged_in: 'Inloggegevens onthouden?',
    login: 'Inloggen',
    password: 'Wachtwoord',
    please_login_to_continue: 'Log in om verder te gaan.',
    username: 'Gebruikersnaam',
    welcome: 'Welkom.',
  },
  no_records: {
    text: 'Geen resultaten',
  },
  organisations: {
    action: 'Bewerk',
    create: 'Toevoegen',
    create_organisation: 'Toevoegen projecten',
    edit: 'Bewerk',
    edit_organisation: 'Bewerk projecten',
    organisations: 'Projecten',
    title: 'Naam',
  },
  organisation_groups: {
    apply: 'Toepassen',
    comparison_groups: 'Vergelijkingsgroepen',
    filter_applied: 'Filter toegepast',
    group_deleted: 'Groep verwijderd',
    no_comparison_group: 'Geen vergelijksingsgroep',
    saved_comparison_groups: 'Opgeslagen vergelijkingsgroepen'
  },
  questionnaire: {
    add_remark: 'Toevoegen',
    add_users: 'Gebruiker(s) toevoegen',
    all_questionnaires: 'Overzicht van alle vragenlijsten',
    be_careful: '<p>Wees voorzichtig! Door de gegevens te bewerken, maakt u de vorige inzendingen ongedaan.<br />Nieuwe gegevens worden pas na het indienen van de vragenlijst opgeslagen.<br />Weet u zeker dat u verder wilt gaan?</p>',
    changes_will_not_be_saved: 'Weet u het zeker? Wijzigingen die u heeft aangebracht, worden niet opgeslagen.',
    choose_answer: 'Kies antwoord',
    close_categories: 'Alle categorieën sluiten',
    collapse_all: 'Alles inklappen',
    comment: 'Opmerking',
    comments: 'Opmerkingen',
    edit_remark: 'opmerking bewerken',
    error_message: 'Foutmelding',
    error_messages: 'Foutmeldingen',
    error_save: 'U kunt de vragenlijst niet inzenden terwijl deze fouten bevat!',
    expand_all: 'Alles uitklappen',
    filter_by_tag: 'Filteren op',
    filter_by_type: 'Filteren op type',
    group: 'categorie',
    groups: 'categorieën',
    home: 'Home',
    incomplete_message: "Kan vragenlijst niet inzenden als deze nog niet is afgerond",
    index: 'Verbergen',
    last_update_by: 'Laatste bewerking door',
    more: 'andere(n)',
    multi_organizations: 'Ingeleverd',
    next_section: 'Volgende categorie',
    nvh: 'Niet voorhanden',
    nvt: 'Niet van toepassing',
    open_categories: 'Alle categorieën openen',
    organizations: 'Organisaties', 
    previous_section: 'Vorige categorie',
    reopen: 'Heropenen',
    reply: 'Reageer',
    save: 'Opslaan',
    save_close: 'Opslaan en sluiten',
    save_success: 'Vragenlijst succesvol opgeslagen',
    show_categories: 'Categorieën',
    single_questionnaire: 'Overzicht van vragenlijsten van {{org}}',
    start_filling: 'Start met invullen',
    status: 'Status',
    sub_group: 'sub categorie',
    sub_groups: 'sub categorieën',
    submit: 'Inzenden',   
    users: 'gebruikers',
    users_list: 'Gebruikerslijst',
    view_commit_log: 'Bekijk inzendingen log',
    view_indicatordata_log: 'Bekijk vragen data log',
    view_remarks_log: 'Bekijk opmerkingen log',
    warning_message: 'Waarschuwing',
    warning_messages: 'Waarschuwingen',
    webform_save_access_error: 'U heeft geen rechten om deze vragenlijst op te slaan',
    worth: 'Waarde',
    write_a_comment: 'Uw bericht',
  },
  reports: {
    all_reports: 'Overzicht van alle rapporten',
    choose_file: 'Kies bestand',
    created_on: 'Laatst bewerkt op',
    download_as: 'Downloaden als',
    export_button: 'Uploaden',
    export_to_docx: 'Download als docx',
    select_group: 'Vergelijkingsgroep',
    select_period: 'Selecteer periode',
    single_report: 'Overzicht van rapporten van {{org}}',
  },
  statuses: {
    completed: 'Afgerond',
    in_progress: 'In uitvoering',
    open: 'Open',
    pending: 'In behandeling',
    processing: 'In verwerking',
    task_completed: 'Vragen afgerond',
  },
  user: {
    add_profile_picture: 'Voeg profielfoto toe',
    add_user: 'Gebruiker toevoegen',
    apply: 'Toepassen',
    back_to_user_list: 'Terug naar gebruikerslijst',
    can_edit: 'Allow user management?',    
    cancel: 'Annuleren',
    change_password: 'Wachtwoord wijzigen',
    characteristics: 'Eigenschappen',
    confirm: 'Bevestigen',
    confirm_password: 'Bevestig nieuw wachtwoord',
    create_mew_password: 'Nieuw wachtwoord aanmaken',
    delete_user: 'Gebruiker verwijderen',
    edit_profile: 'Pas je profiel aan',
    email: 'E-mail',
    error_edit_personal: 'Er is iets fout gegaan!',
    feedback: 'Feedback',
    filtered_by_roles: 'Gefilterd op rollen',
    first_name: 'Voornaam',
    function: 'Functie',
    generate: 'Genereren',
    generate_password: 'Wachtwoord genereren',
    infixes: 'Tussenvoegsels',
    last_change_password: 'Wachtwoord voor het laatst aangepast op',
    last_name: 'Achternaam',
    last_visit: 'Laatste keer ingelogged op:',
    new_password: 'Nieuw paswoord',
    no_users: 'Geen gebruikers',
    not_strong_password:
    'Een wachtwoord moet voldoen aan de volgende vereisten:\n' +
     'tenminste 12 tekens\n' +
     'tenminste 1 kleine letter\n' +
     'tenminste 1 hoofdletter\n' +
     'tenminste 1 cijfer\n' +
     'tenminste 1 speciaal teken',
    note_password: 'Let op: het wachtwoord wordt pas opgeslagen wanneer u op `Opslaan` klikt',
    old_password: 'Oud wachtwoord',
    organizations: 'Organisaties',
    password: 'Wachtwoord',
    password_not_match: 'De twee wachtwoorden die je hebt ingevoerd komen niet overeen!',
    password_update: 'Wachtwoord update succesvol!',
    phone_number: 'Telefoonnummer',
    please_confirm_new_password: 'Bevestig uw nieuwe wachtwoord!',
    please_enter_new_password: 'Voer uw nieuwe wachtwoord in!',
    please_enter_old_password: 'Vul a.u.b. uw oude wachtwoord in!',
    please_select: 'Selecteer alstublieft',
    rights: 'Rechten',
    role: 'Rollen',
    save: 'Opslaan',
    search_text: 'Zoekopdracht...',
    send_news: 'Ontvang emails voor nieuwsberichten',
    send_password_reset_email: 'Stuur wachtwoordreset-email (token methode)',
    should_change_password_after_login: 'Gebruiker moet wachtwoord wijzigen bij volgende login',
    successfully_edit: 'is succesvol bijgewerkt!',
    user_name: 'Gebruikersnaam',
    user_profile: 'Gebruikers profiel',
    warning_email: 'Vul alstublieft uw e-mailadres in!',
    warning_first_name: 'Gelieve uw achternaam in te Gebruikersnaam!',
    warning_last_name: 'Gelieve uw achternaam in te voeren!',
    warning_password: 'Voer uw wachtwoord in!',
    warning_user_name: 'Vul a.u.b. uw gebruikersnaam in!',
    wrong_old_password: 'Verkeerd oud wachtwoord!',
  },
  welcome_modal: {
    body: `<p>Om te starten met het invullen van de eerste vragenlijst, is het nodig om eerst een project aan te maken waarvoor je de vragenlijst wilt invullen.</p>
    <p>Klik hiervoor in de linker sidebar op het menu item “Bewerk projecten” om vervolgens rechtsboven te klikken op de knop “Toevoegen”.</p>
    <p>Vul vervolgens de projectnaam in en klik op “Toevoegen”.</p>
    <p>Het eerste project is dan aangemaakt en de vragenlijsten voor dit project staan klaar om in te vullen.</p>
    <p>Veel succes!</p>`,
    title: 'Welkom bij de PMK tool!',
  }
}
