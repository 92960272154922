import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import React from "react";
import { withTranslation } from "react-i18next";

import "./CategorySwitch.scss";

function CategorySwitch({next, onClick, previous, t}) {
    return (
        <div className="containerSwitch">
            {!!!previous ? (<div></div>) : (
                <div className="switchItem" onClick={() => onClick(previous?.webform_key)}>
                    <LeftOutlined style={{ marginRight: 11, color: "#134176" }}/>
                    <div className="switchItem_text">
                        <span className="title">{t("previous_section")}</span>
                        <span>{previous?.title}</span>
                    </div>
                </div>
            )}

            {next && (
                <div className="switchItem" onClick={() => onClick(next?.webform_key)}>
                    <div className="switchItem_text" style={{ textAlign: "right" }}>
                        <span className="title">{t("next_section")}</span>
                        <span>{next?.title}</span>
                    </div>
                    <RightOutlined style={{ marginLeft: 11, color: "#134176" }}/>
                </div>
            )}
        </div>
    );
}

export default withTranslation('questionnaire')(CategorySwitch);