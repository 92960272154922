import React from "react";
import InfoCircleOutlined from "@ant-design/icons/lib/icons/InfoCircleOutlined";

import './Warning.scss';

const Warning = ({ t, warningOrg = false, number = false }) => {
  return (
    <div className="warning__wrapper">
      <p className="title">{t('warning_title')}:</p>
      <div className="text">
        <InfoCircleOutlined />
        {warningOrg ? `${t('warning_org_text_start')} ${number}  ${t('warning_org_text_end')}` : t('warning_text')}
      </div>
    </div>
  )
};

export default Warning;