import { api } from '../../services'

const getReportBlockDocxById = data => (dispatch, getFromStore) => {
  const settings = getFromStore().app.settings
  return api.report_blocks.getReportBlockDocxById(data)
}

const getStaticPageDocxById = data => (dispatch, getFromStore) => {
  return api.report_blocks.getStaticPageDocxById(data)
}


export {
  getReportBlockDocxById,
  getStaticPageDocxById
}