import http from './http'

const getOrganisations = () => {
  return http({
    url: '/api/v1/current_user/organisations?_format=json',
    method: 'GET'
  })
}

const getOrganisationForm = () => {
  return http({
    url: '/api/v1/organisation/elements?_format=json',
    method: 'GET'
  })
}

const getAllOrganization = (profile) => {
  return http({
    url: `api/v1/organisation/list${profile ? '?profile=1' : ''}`,
    method: 'GET',
  })
}

const createOrganisation = data => {
  return http({
    url: '/api/v1/organisation/create?_format=json',
    method: 'POST',
    data
  })
}

const updateOrganisationById = (id, data) => {
  return http({
    url: `/api/v1/organisation/${id}?_format=json`,
    method: 'POST',
    data
  })
}

const getOrganisationById = id => {
  return http({
    url: `/api/v1/organisation/${id}?_format=json`,
    method: 'GET'
  })
}

export {
  getOrganisationForm,
  getAllOrganization,
  createOrganisation,
  updateOrganisationById,
  getOrganisationById,
  getOrganisations
}
