import axios from "axios";

import http from "./http";

const getBenchmark = (params) => {
  // eslint-disable-next-line camelcase
  const { url, expression, site_id, organisations, period_start, period_end } =
    params;

  return axios.post(`${url}/benchmark/period`, {
    expression,
    site_id,
    organisations,
    period_start,
    period_end,
  });
};

export function getBenchmarkValuesForIndicator(params) {
  // eslint-disable-next-line camelcase
  const url = params.url;
  delete params.url;

  return axios.post(`${url}/benchmark/values`, params);
}

export function getBenchmarkFavorites(params) {
  // eslint-disable-next-line camelcase
  const url = params.url;
  delete params.url;

  return axios.post(`${url}/benchmark/favorites`, params);
}

export function addBenchmarkFavorite(params) {
  // eslint-disable-next-line camelcase
  const url = params.url;
  delete params.url;

  return axios.put(`${url}/benchmark/favorites`, params);
}

export function removeBenchmarkFavorite(params) {
  // eslint-disable-next-line camelcase
  const { url, site_id, indicator, user_id } = params;
  return axios.delete(
    `${url}/benchmark/favorites?site_id=${site_id}&indicator=${indicator}&user_id=${user_id}`,
    params
  );
}

export function getOrganizationForBenchmark(params) {
  // eslint-disable-next-line camelcase
  const { url, site_id, form_id } = params;
  return axios.get(`${url}/benchmark/${form_id}/organizations?site_id=${site_id}`);
}

export function getFilteredOrganizationForBenchmark(params) {
  // eslint-disable-next-line camelcase
  const { url, site_id, active_filters, form_id } = params;
  return axios.post(`${url}/benchmark/${form_id}/organizations?site_id=${site_id}`, active_filters);
}

export function getBenchmarkFavoriteViews(params) {
  // eslint-disable-next-line camelcase
  const url = params.url;
  delete params.url;

  return axios.get(`${url}/benchmark/favorite_views?${toParams(params)}`);
}

function toParams(params) 
{
  return new URLSearchParams(params).toString();   
}

export function setBenchmarkFavoriteViews(params) {
  // eslint-disable-next-line camelcase
  const url = params.url;
  delete params.url;

  params.indicators = JSON.stringify(params.indicators);

  return axios.post(`${url}/benchmark/favorite_views`, params);
}

const getBenchmarkList = (params) => {
  const { url, expression, site_id, period_start, period_end } = params;

  return axios.get(`${url}/api/v1/comparison_groups/list`, {
    expression,
    site_id,
    period_start,
    period_end,
  });
};

const getBenchmarkPeriods = (params) => {
  const { data, site_id, url, is_full, organisation } = params;
  return axios.get(`
    ${url}/benchmark/periods?form=${data}&site_id=${site_id}&organisation=${organisation}&is_full=${
    is_full ? 1 : 0
  }
  `);
};

const getBenchmarkData = (params) => {
  const {
    url,
    calculation_type,
    groups,
    indicator,
    site_id,
    period_start,
    period_end,
  } = params;

  return axios.post(`${url}/benchmark/groups`, {
    calculation_type,
    groups,
    indicator,
    site_id,
    period_start,
    period_end,
  });
};

const getBenchmarkDataOrg = (params) => {
  const {
    url,
    calculation_type,
    groups,
    indicator,
    site_id,
    period_start,
    period_end,
  } = params;

  return axios.post(`${url}/benchmark/organizations`, {
    calculation_type,
    organizations: [groups],
    indicator,
    site_id,
    period_start,
    period_end,
  });
};

const getBenchmarkDataCategory = (params) => {
  const {
    url,
    calculation_type,
    groups,
    indicator,
    site_id,
    period_start,
    period_end,
  } = params;
  return axios.post(`${url}/benchmark/organizations`, {
    calculation_type,
    expression: `[%[AVG${indicator}]%]`,
    organizations: groups,
    site_id,
    period_start,
    period_end,
  });
};

const getBenchmarkDataForCategory = (params) => {
  const {
    url,
    calculation_types,
    groups,
    indicator,
    site_id,
    period_start,
    period_end,
    period_type,
    batch,
    format = "json",
  } = params;

  return axios.post(
    `${url}/benchmark/${batch ? "batch/" : ""}category/${indicator}?format=${format}`,
    {
      calculation_types,
      groups,
      site_id,
      period_start,
      period_end,
      period_type,
      group_by: "time_period",
    },
    { responseType: format === "json" ? "json" : "batch_xlsx" }
  );
};

const getBenchmarkChartData = (params) => {
  const {
    url,
    calculation_types,
    groups,
    indicator,
    site_id,
    period_start,
    period_end,
    period_type,
    batch,
    organization,
    format = "json",
  } = params;

  return axios.post(
    `${url}/benchmark/${
      batch ? "batch/" : ""
    }indicator/${indicator}?format=${format}`,
    {
      calculation_types,
      groups,
      site_id,
      period_start,
      period_end,
      period_type,
      organization,
      group_by: "time_period",
    },
    { responseType: format === "json" ? "json" : "arraybuffer" }
  );
};

const searchBenchmarkData = (params) => {
  const {
    url,
    site_id,
    formId,
    q,
    is_category,
    is_sub_category,
    is_indicator,
    is_exact,
  } = params;

  return axios.get(`${url}/forms/${formId}/search/elements`, {
    params: {
      q,
      site_id,
      is_category,
      is_sub_category,
      is_indicator,
      is_exact,
    },
  });
};

const getBenchmarkDataForIndicator = (params) => {
  const {
    url,
    calculation_type,
    groups,
    period_type,
    indicator,
    site_id,
    period_start,
    period_end,
    format = "json",
  } = params;

  return axios.post(
    `${url}/benchmark/groups/organizations?format=${format}`,
    {
      calculation_type,
      indicator,
      groups,
      period_type,
      site_id,
      period_start,
      period_end,
      group_by: "organization",
    },
    { responseType: format === "json" ? "json" : "arraybuffer" }
  );
};

const getBenchmarkDataFromExpression = (params) => {
  const {
    url,
    calculation_type,
    groups,
    expression,
    site_id,
    period_start,
    period_end,
    format = "json",
  } = params;

  return axios.post(
    `${url}/benchmark/groups/organizations?format=${format}`,
    {
      calculation_type,
      expression: `[%[${expression}]%]`,
      groups,
      site_id,
      period_start,
      period_end,
      group_by: "organization",
      period_type: "month",
    },
    { responseType: format === "json" ? "json" : "arraybuffer" }
  );
};

const getBenchmarkOrgDataForCategory = (params) => {
  const {
    url,
    calculation_type,
    organization,
    indicator,
    site_id,
    batch,
    period_start,
    period_end,
    period_type,
    calculation_types,
  } = params;
  return axios.post(
    `${url}/benchmark/${batch ? "batch/" : ""}category/${indicator}/organization`,
    {
      calculation_types,
      calculation_type,
      organizations: organization,
      period_type,
      site_id,
      period_start,
      period_end,
      group_by: "time_period",
    }
  );
};

const getBenchmarkOrgDataForIndicator = (params) => {
  const {
    url,
    calculation_type,
    organization,
    indicator,
    site_id,
    period_start,
    period_end,
    period_type,
  } = params;
  return axios.post(`${url}/benchmark/groups/organizations/${organization}`, {
    calculation_type,
    indicator,
    site_id,
    period_start,
    period_end,
    group_by: "organization",
    period_type,
  });
};

const getBenchmarkOrgDataFromExpression = (params) => {
  const {
    url,
    expression,
    calculation_type,
    organization,
    indicator,
    site_id,
    period_start,
    period_end,
    period_type,
  } = params;
  return axios.post(`${url}/benchmark/groups/organizations/${organization}`, {
    calculation_type,
    expression: `[%[${expression}]%]`,
    indicator,
    site_id,
    period_start,
    period_end,
    group_by: "organization",
    period_type,
  });
};

const getBenchmarkDrillDown = (params) => {
  return axios.post(
    `${params.url}/benchmark/${
      params.batch ? "batch/" : ""
    }drilldown/?full=true&format=${params.format}`,
    {
      ...params,
    }
  );
};

const getBenchmarkDrillDownForOrganizations = (params) => {
  return axios.post(
    `${params.url}/benchmark/${params.batch ? "batch/" : ""}drilldown/organizations?full=true&format=${params.format}`,
    {
      ...params,
    }
  );
};

const getBenchmarkDrillDownForGroups = (params) => {
  return axios.post(
    `${params.url}/benchmark/${params.batch ? "batch/" : ""}drilldown/groups?full=true&format=${params.format}`,
    {
      ...params,
    }
  );
};

const getBenchmarkDrillDownForChildOrganizations = (params) => {
  return axios.post(
    `${params.url}/benchmark/${
      params.batch ? "batch/" : ""
    }drilldown/childorganizations?full=true&format=${params.format}`,
    {
      ...params,
    }
  );
};

const getBenchmarkMap = (params) => {
  return axios.post(
    `${params.url}/benchmark/${
      params.batch ? "batch/" : ""
    }map?full=true&format=${params.format}`,
    {
      ...params,
    }
  );
};

// const getDataCategory = params => {
//   const { url, calculation_type, groups, indicator, site_id, period_start, period_end } = params

//   return axios.post(`${url}/benchmark/category/c0043.35723`, {
//     "site_id": "dev_cogniteq",
//     "period_start": "2019-01-04 04:20:33",
//     "period_end": "2021-12-05 14:20:33",
//     "groups": [85, 86, 87],
//     "calculation_type": "maximum",
//     "group_by": "time_period"
//   })
// }

const getMapFile = (url) => {
  return axios.get(`${url}`);
};

const getComparisonGroups = () => {
  return http({
    url: "/api/v1/comparison_groups/list",
    method: "GET",
  });
};

const searchByAllOrganizations = (params) => {
  const { url, site_id, q, indicators, organisation_levels } = params;

  return axios.post(`${url}/organizations/search`, {
    site_id,
    q,
    indicators,
    organisation_levels,
  });
};

const getBenchmarkLevels = () => {
  return http({
    url: "/api/v1/taxonomy_vacabulary/organisation_levels",
    method: "GET",
  });
};

const getComparisonGroupsItem = (id) => {
  return http({
    url: `/api/v1/comparison_groups/${id}`,
    method: "GET",
  });
};

const getBenchmarkSettings = () => {
  return http({
    url: "/api/v1/benchmark/settings",
    method: "GET",
  });
};

const updateComparisonGroup = (id, data) => {
  return http({
    url: `/api/v1/comparison_groups/${id}`,
    method: "PUT",
    data: {
      organisations: data.ids,
      name: data.name,
    },
  });
};

const createComparisonGroup = (data) => {
  return http({
    url: "/api/v1/comparison_groups/create?_format=json",
    method: "POST",
    data,
  });
};

const deleteComparisonGroup = (id) => {
  return http({
    url: `/api/v1/comparison_groups/${id}?_format=json`,
    method: "DELETE",
  });
};

const searchWebform = (params) => {
  return http({
    url: "/api/v1/webform/search",
    method: "GET",
    params,
  });
};

const createNewBenchmarkView = (params, id) => {
  return http({
    url: `/api/v1/benchmark_views/${id}`,
    method: "POST",
    data: { ...params },
  });
};

const getBenchmarkViewList = (id) => {
  return http({
    url: `/api/v1/benchmark_views/list/${id}`,
    method: "GET",
  });
};

const getBenchmarkSpecialView = (id) => {
  return http({
    url: `/api/v1/benchmark_views/${id}`,
    method: "GET",
  });
};

const deleteBenchmarkSpecialView = (id) => {
  return http({
    url: `/api/v1/benchmark_views/${id}`,
    method: "DELETE",
  });
};

export {
  getBenchmark,
  getComparisonGroups,
  getBenchmarkDataOrg,
  getBenchmarkList,
  getComparisonGroupsItem,
  updateComparisonGroup,
  createComparisonGroup,
  deleteComparisonGroup,
  getBenchmarkSettings,
  getBenchmarkData,
  getBenchmarkDrillDown,
  getBenchmarkDrillDownForOrganizations,
  getBenchmarkDrillDownForGroups,
  getBenchmarkDrillDownForChildOrganizations,
  getBenchmarkDataFromExpression,
  getBenchmarkOrgDataFromExpression,
  getBenchmarkDataCategory,
  getBenchmarkDataForIndicator,
  getBenchmarkDataForCategory,
  getBenchmarkOrgDataForIndicator,
  getBenchmarkOrgDataForCategory,
  searchWebform,
  getBenchmarkPeriods,
  searchBenchmarkData,
  getMapFile,
  createNewBenchmarkView,
  getBenchmarkViewList,
  getBenchmarkSpecialView,
  deleteBenchmarkSpecialView,
  searchByAllOrganizations,
  getBenchmarkMap,
  getBenchmarkChartData,
  getBenchmarkLevels,
};
