import moment from "moment";
import { Select } from "antd";
import React from "react";

const { Option } = Select;

export default function PeriodSelector({ loading, period, periodChanged, periods }) {
    const changePeriod = (value) => {
        periodChanged(value);
    };

    return (
        <>
            <Select disabled={loading} onChange={changePeriod} style={{ minWidth: "200px" }} value={period}>
                {periods?.map((p, idx) => (
                    <Option key={idx + p.name} value={moment(p.condition.period_start, "YYYY-MM-DD hh:mm:ss").valueOf() / 1000}>
                        {p.name}
                    </Option>
                ))}
            </Select>
        </>
    );
}