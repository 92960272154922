import { connect } from 'react-redux';
import ReportFilters from './ReportFilters';
import actions from "../../../../store/actions";

const mapStateToProps = (state) => ({
  settingsBenchmark: state.benchmark.benchmarkSettings,
  savedViews: state.benchmark.savedViews,
});

const mapDispatchToProps = (dispatch) => ({
  getBenchmarkViewList: (id) => dispatch(actions.benchmark.getBenchmarkViewList(id)),
  deleteBenchmarkSpecialView: (id) => dispatch(actions.benchmark.deleteBenchmarkSpecialView(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportFilters);
