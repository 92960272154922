import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { Input } from 'antd'

import './CustomInputNumber.scss'

export default function (props) {
  const { prefix = '', suffix = '', ...rest } = props
  return (
    <div className='c-customInputNumber'>
      {prefix && <div className='c-customInputNumber__prefix'>{prefix}</div>}

      <CurrencyFormat customInput={Input}  {...rest} />

      {prefix && <div className='c-customInputNumber__suffix'>{suffix}</div>}
    </div>
  )
}
