import React, { useEffect, useState } from "react";
import { Collapse, Modal, Spin } from "antd";

import { PieChartOutlined, InfoCircleOutlined, BranchesOutlined } from "@ant-design/icons/lib/icons";
import BenchmarkMap from "../../../../assets/images/svg/benchmarkMap";
import { NoRecords } from "../../../../components";
import ModalCharts from "../../modal/ModalCharts";
import ModalDrillDown from "../../modal/ModalDrillDown";

const { Panel } = Collapse;

export default function ReportGrid({
  t,
  data,
  settingsBenchmark,
}) {
  // const [modalVisible, setModalVisible] = useState(false);
  // const [modalDrilldownVisible, setModalDrilldownVisible] = useState(false);
  // const [modalDrilldownMode, setModalDrilldownMode] = useState(true);
  // const [modalTitle, setModalTitle] = useState(null);
  // const [modalValue, setModalValue] = useState(null);
  // const [modalCharts, setModalCharts] = useState(null);
  // const [modalIndicator, setModalIndicator] = useState(null);
  // const [indexCharts, setIndexCharts] = useState(null);
  // const [defaultTabKey, setDefaultTabKey] = useState(null);
  const [collapseActiveKey, setCollapseActiveKey] = useState([]);
  // const [organisationData, setOrganisationData] = useState(null);
  // const [decimalPlaces, setDecimalPlaces] = useState(null);
  // const [descriptionText, setDescriptionText] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  // const [mapOrgData, setMapOrgData] = useState(null);

  const formatIndicatorValue = (value, tableItem) => {
    if (value === "-" || value === -1) {
      return "-";
    }

    let number = value;
    let decimals = tableItem.decimal_places ?? 2;
    let decpoint = tableItem.numeric_decimal ?? ",";
    let thousand = tableItem.numeric_thousand ?? ".";

    let n = Math.abs(number).toFixed(decimals).split(".");
    n[0] = n[0]
      .split("")
      .reverse()
      .map((c, i, a) =>
        i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c
      )
      .reverse()
      .join("");

    return (Math.sign(number) < 0 ? "-" : "") + n.join(decpoint);
  };

  // const closeModal = () => {
  //   setModalVisible(false);
  // };

  // const closeDrilldownModal = () => setModalDrilldownVisible(false);

  // const openModal = (
  //   value,
  //   reportData,
  //   number,
  //   tabKey = "charts",
  //   organisationData = null,
  //   indicator,
  //   mapOrgDataValue = false
  // ) => {
  //   const currentWebform = elementsData?.webform?.elements?.find(
  //     (item) => item.webform_indicator === indicator
  //   );

  //   setModalValue(value);
  //   setModalCharts(reportData);
  //   setIndexCharts(number);
  //   setModalTitle(value?.title);
  //   setModalVisible(true);
  //   setDefaultTabKey(tabKey);
  //   setOrganisationData(organisationData);
  //   setModalIndicator(indicator);
  //   setDecimalPlaces(currentWebform?.decimal_places);
  //   setDescriptionText(value?.description);

  //   if (mapOrgDataValue) {
  //     setMapOrgData(mapOrgDataValue);
  //   }
  // };

  // const openDrilldownModal = (indicator) => {
  //   setModalIndicator(indicator);
  //   setModalDrilldownMode(true);
  //   setModalDrilldownVisible(true);
  // };

  // const openDrilldownOrganisationModal = (indicator) => {
  //   setModalIndicator(indicator);
  //   setModalDrilldownMode(false);
  //   setModalDrilldownVisible(true);
  // };

  // const setShowMap = () => settingsBenchmark?.maps?.show_maps_view === "yes";

  const handlerOpenCollapse = async (value) => {    
    let activeKeys = [...collapseActiveKey];
    if(activeKeys.find((item) => item === value.indicator)) {
      activeKeys = activeKeys.filter((item) => item !== value.indicator);
    } else {
      activeKeys.push(value.indicator);
    }
    setCollapseActiveKey(activeKeys);

    // if (open && isDrillDown && drillDownGroup) {
    //   setIsLoader(true);

    //   await getBenchmarkDrillDown({
    //     batch: true,
    //     indicator: value?.webform_indicator,
    //     group: drillDownGroup,
    //     organization: activeOrganization?.id,
    //     period_start: startDate,
    //     period_end: endDate,
    //     calculation_types: ["current"],
    //   })
    //     .then((response) => {
    //       const { data } = response;
    //       setDrillDownData(data);
    //     })
    //     .catch((e) => console.error("error ", e))
    //     .finally(() => setIsLoader(false));
    // }
  };

  function GridRow({ item, index }) {
    return (
      <Collapse key={item.indicator} activeKey={collapseActiveKey} onChange={() => handlerOpenCollapse(item)}>
        <Panel key={item.indicator} header={item.name} className={"report__item"}>
          {
            isLoader ? (
              <Loader />
            ) : (
              <div className={"report__content-block"} key={index}>
                <div className={"tab__title"}>
                  <div className={"tab__title-text"}>{item.name}</div>
                  <div className={"tab__title-content"}>

                    {/* <div className={"map"} onClick={() => openModal(item, reportData, index, "map", null, item.webform_indicator)}>
                      <BenchmarkMap className={"map__img"} />
                      {t("card")}
                    </div> */}

                    {/* <div className={"chart"} onClick={() => openModal(item, reportData, index, "charts", null, item.webform_indicator)}>
                      <PieChartOutlined />
                      {t("chart")}
                    </div> */}

                    {/* {!!item.is_multilevel && !isDrillDown && settingsBenchmark?.show_drilldown_in_own_hierarchy === "yes" &&
                      (
                        <>
                          <div className={"chart"} onClick={() => openDrilldownModal(item.webform_indicator)} >
                            <BranchesOutlined />
                            {t("drilldown")}
                          </div>
                          <div className={"chart"} onClick={() => openDrilldownOrganisationModal(item.webform_indicator)}>
                            <BranchesOutlined />
                            {t("drilldown_organisation")}
                          </div>
                        </>
                      )
                    } */}

                    <div className={"info"}>
                      {item.indicator}
                      <InfoCircleOutlined />
                    </div>
                  </div>
                </div>

                {
                  item.values.map(
                    (value, index) => (
                      <div className={"report__content"} key={'column_' + index}>
                        {formatIndicatorValue(value || "-", item)}
                      </div>
                    )
                  )
                }
              </div>
            )
          }
        </Panel>
      </Collapse>
    )
  }

  function Grid() {
    if (!(data))
      return (<NoRecords />)

    return data?.rows?.map(
      (item, index) => <GridRow key={item.indicator} item={item} index={index} />
    )
  }

  return (
    <>
      {/* <Modal
        width={"1024px"}
        footer={null}
        open={modalVisible}
        onCancel={closeModal}
        title={modalTitle}
        className={"report__modal"}
      >
        <ModalCharts
          modalVisible={modalVisible}
          defaultTabKey={defaultTabKey}
          optionList={optionList}
          modalValue={modalValue}
          modalCharts={modalCharts}
          indexCharts={indexCharts}
          settingsBenchmark={settingsBenchmark}
          showMap={setShowMap()}
          startDate={startDate}
          endDate={endDate}
          periodType={periodType}
          t={t}
          mapFile={mapFile}
          organisationData={organisationData}
          comparisonGroup={comparisonGroup}
          indicator={modalIndicator}
          activeOrganization={activeOrganization}
          drillDownGroup={drillDownGroup}
          decimalPlaces={decimalPlaces}
          descriptionText={descriptionText}
          mapOrgData={mapOrgData}
        />
      </Modal> */}
      {/* <Modal
        width={"90%"}
        footer={null}
        open={modalDrilldownVisible}
        onCancel={closeDrilldownModal}
        title={t("drilldown")}
        className={"report__modal"}
      >
        <ModalDrillDown
          indicator={modalIndicator}
          group={modalDrilldownMode ? drillDownGroup : null}
          organization={!modalDrilldownMode ? activeOrganization : null}
          periodStart={startDate}
          periodEnd={endDate}
          calculationTypes={checkedList}
          t={t}
        />
      </Modal> */}
      <Grid />
    </>
  );
};

function Loader() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "10px 0",
      }}
    >
      <Spin size={"large"} />
    </div>
  )
}