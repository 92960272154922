import React, { Component } from "react";
import { Select, Spin, DatePicker, message } from "antd";

import { api } from "../../services";

import "./ReportBlockPage.scss";
import Iframe from "../../components/Iframe/Iframe";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";

import { withTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { ComparisonGroupBuilder } from "../index";
import IndividualOrganisationDropdown from "../../components/Organisations/IndividualOrganisationDropdown/IndividualOrganisationDropdown";

const { RangePicker } = DatePicker;
const { Option } = Select;

class ReportBlockPage extends Component {
  state = {
    blockUrl: null,
    fetch: true,
    error: null,
    newDates: null,
    iFrameReady: false,
    reportHtml: null,
    availableOrganisations: [],
    selectedOrganisations: [],
    groupsOrganisations: [],
    activeGroupIds: [],
    benchmarkForms: null,
    comparisonGroupName: null,
  };

   fetchData = async (groupIDs = false) => {
    this.setState({
      blockUrl: null,
      fetch: true,
      error: null,
      reportHtml: null,
      iFrameReady: false,
    });

    return await Promise.all([
      api.organisations.getAllOrganization()
        .then(({data}) => {
          if (data && data.organisations)
            this.setState({availableOrganisations: data.organisations});
        }),
        api.report_blocks
        .getReportBlockById(
          this.props.match.params.id,
          groupIDs,
          this.props.user.data.organisation
        )
        .then(({ data }) => {
          if (data) {
            console.log(data);
            this.setState({
              activeGroupIds: groupIDs || [data["node"].default_comparison_group],
              benchmarkForms: data["node"].linked_webforms,
            });
  
            return api.report_blocks
              .getReportBlockHtmlById(this.props.match.params.id, (this.state.newDates == null ? false : this.state.newDates.startDate), (this.state.newDates == null ? false : this.state.newDates.endDate), (groupIDs || [data["node"].default_comparison_group]), this.state.selectedOrganisations?.map(o => o.id))
              .then(({data}) => {
                if (data) {
                  this.setState({       
                    iFrameReady: true,   
                    fetch: false,
                    reportHtml: data
                      .replaceAll(window.location.origin, process.env.REACT_APP_BASE_URL)
                      .replaceAll(`href="/`, `href="${process.env.REACT_APP_BASE_URL}/`)
                      .replaceAll(`src="/`, `src="${process.env.REACT_APP_BASE_URL}/`)
                      .replaceAll(`href="./`, `href="${process.env.REACT_APP_BASE_URL}/`)
                      .replaceAll(`src="./`, `src="${process.env.REACT_APP_BASE_URL}/`)
                  });
                }
              }
            )
          } else {
            throw new Error(data.error);
          }
        })
        .catch((error) => {
          this.setState({
            error: error?.response?.data.errors[0],
            fetch: false,
          });
        })
    ]); 
  };

  handleFrameLoaded = () => {
    this.setState({ iFrameReady: true });

    var links = document.querySelector("iframe").contentDocument.querySelectorAll("html link");
    var scripts = document.querySelector("iframe").contentDocument.querySelectorAll("html script");
    var imgs = document.querySelector("iframe").contentDocument.querySelectorAll("html img");
    
    links.forEach(element => {
      if (element.href.startsWith(window.location.origin))
        element.href = element.href.replace(window.location.origin, process.env.REACT_APP_BASE_URL);
    });

    scripts.forEach(element => {
      if (element.src.startsWith(window.location.origin))
        element.src = element.src.replace(window.location.origin, process.env.REACT_APP_BASE_URL);
    });

    imgs.forEach(element => {
      if (element.src.startsWith(window.location.origin))
        element.src = element.src.replace(window.location.origin, process.env.REACT_APP_BASE_URL);
    });
  };

  handleChangePeriods = (date, dateString) => {
    const [startDate, endDate] = dateString;
    this.setState({ newDates: { startDate, endDate } });
    this.fetchData(this.state.activeGroupIds ?? false);
  };

  handleDownloadDocx = async () => {
    await this.props
      .getReportBlockDocxById(this.props.match.params.id)
      .then((response) => {
        const { status, data } = response;
        if (status < 200 || status >= 300) return;

        const link = document.createElement("a");
        link.target = "_blank";
        link.href = data?.file_url;
        link.setAttribute("download", `${data?.filename}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((e) => message.error(e.message));
  };

  handleSelectedOrganisationsChanged = (organisations) => {
    this.setState({ selectedOrganisations: organisations });
    this.fetchData(this.state.activeGroupIds);
  };

  onChangeSelectMultiple = (ids) => {
    this.setState({ activeGroupIds: ids });
    this.fetchData(ids);
  }

  onChangeSelect = (id) => {    
    this.setState({ activeGroupIds: (id == null ? [] : [id]) });
    this.fetchData(this.state.activeGroupIds);
  };

  componentDidMount() {
    const {
      user: { isGuest },
      app: {
        settings: {
          guest_mode: { comparison_group, comparison_group_name },
        },
      },
    } = this.props;
    this.fetchData();

    if (!isGuest) {
      Promise.all([api.benchmark.getComparisonGroups()]).then(([{ data }]) => {
        this.setState({
          groupsOrganisations: data.comparison_groups,
        });
      });
    } else {
      this.setState({
        groupsOrganisations: [comparison_group],
        comparisonGroupName: comparison_group_name,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id && !this.state.fetch) {
      this.fetchData();
    }
  }

  render() {
    const {
      blockUrl,
      fetch,
      error,
      iFrameReady,
      reportHtml,
      activeGroupIds,
      availableOrganisations,
      selectedOrganisations,
      groupsOrganisations,
      comparisonGroupName,
      benchmarkForms,
    } = this.state;

    const {
      t,
      app: { settings },
      user: {
        isGuest,
        data: { permissions },
      },
    } = this.props;

    return (
      <div style={{ height: "100%", padding: "15px 0" }}>
        {!isGuest && !settings.hide_comparison_group_select && (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              gap: "2rem",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ marginRight: "auto" }}>
              <Button
                color="blue"
                icon={<DownloadOutlined />}
                onClick={this.handleDownloadDocx}
                size="sm"
                style={{ padding: "11px 12px" }}
              >
                {t("Export to docx")}
              </Button>
            </div>
            <div>
              <label style={{ margin: "0 10px 0 0" }}>
                {t("select_period")}
              </label>
              <RangePicker
                onChange={this.handleChangePeriods}
                picker={"month"}
                style={{
                  border: "2px solid rgb(160, 167, 183)",
                  borderRadius: "4px",
                  padding: "11px 12px",
                }}
              />
            </div>
            <div
              style={{
                textAlign: "right",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <label style={{ margin: "0 10px 0 0" }}>
                {t("select_group")}
              </label>
              <ComparisonGroupBuilder
                pageId={this.props.match.params.id}
                onChangeSelect={this.onChangeSelectMultiple}
                benchmarkForms={benchmarkForms}
                multiple={true}
              />
            </div>
            <div>
              <IndividualOrganisationDropdown 
                availableOrganisations={availableOrganisations} 
                handleSelectedOrganisationsChanged={this.handleSelectedOrganisationsChanged}/>
            </div>
          </div>
        )}

        {isGuest && groupsOrganisations.length > 0 && (
          <div style={{ textAlign: "right", height: "45px" }}>
            <label style={{ margin: "10px 10px 0 0" }}>
              {t("Comparison group")}
            </label>
            <strong>{comparisonGroupName}</strong>
          </div>
        )}

        {(!iFrameReady || fetch) && (
          <div className="d-flex justify-content-center mt-5">
            <Spin />
          </div>
        )}

        {!error && reportHtml ? (
          <div style={{ width: "100%", height: "100%", padding: "15px 0" }}>
            <Iframe
              srcDoc={reportHtml}
              iFrameReady={iFrameReady}
              frameLoaded={this.handleFrameLoaded}
            />
          </div>
        ) : (
          <div>{error}</div>
        )}
      </div>
    );
  }
}

export default withTranslation("reports")(ReportBlockPage);
