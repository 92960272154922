import { connect } from "react-redux";

import actions from '../../../../store/actions';
import Header from "./Header";

const mapStateToProps = (state) => ({
  user: state.user.data,
});

const mapDispatchToProps = (dispatch) => ({
  addFilesForFileManager: (params) => dispatch(actions.fileManager.addFilesForFileManager(params)),
  getFolderFiles: (params) => dispatch(actions.fileManager.getFolderFiles(params)),
  downloadAllFiles: (params) => dispatch(actions.fileManager.downloadAllFiles(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);



