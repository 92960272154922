import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {Select, Spin, DatePicker} from "antd";
import Exporting from "highcharts/modules/exporting";
import {api} from "../../../../../../services";
import { map } from "lodash";

const {Option} = Select;
const {RangePicker} = DatePicker;

Exporting(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const CHARTS = ['bar', 'column', 'area', 'pie', 'scatter'];

const Charts = ({
    activeOrganization,
    checkedColumns,
    comparisonGroup,
    endDate,
    getBenchmarkChartData,
    indicator,
    element,
    modalVisible,
    organisationData,
    periodType,
    settingsBenchmark,
    startDate,
    t
}) => {
    const {id} = useParams();

    const [activeChartType, setActiveChartType] = useState(CHARTS[0]);
    const [chartSelect, setChartSelect] = useState(CHARTS);
    const [isLoading, setIsLoading] = useState(false);
    const [modalCharts, setModalCharts] = useState(null);
    const [periods, setPeriods] = useState(null);
    const [periodsDates, setPeriodsDates] = useState({ startDate, endDate });
    const [remarks, setRemarks] = useState([]);

    useEffect(() => {
        if (settingsBenchmark?.charts?.show_line_charts === 'yes') {
            const chartTypes = [...chartSelect];
            chartTypes.push('line');
            setChartSelect(chartTypes);
        }
    }, []);

    useEffect(() => {
        checkCountOfValues();
    }, [modalCharts])

    useEffect(() => {
        if (!modalVisible) 
            return;

        setIsLoading(true);

        getBenchmarkChartData({
            batch: true,
            calculation_types: Object.entries(settingsBenchmark?.extra_columns)?.filter(c => checkedColumns.includes(c[1]))?.map(c => c[0]) ?? [],
            group_by: 'time_period',
            groups: comparisonGroup?.id ? [comparisonGroup?.id] : [],
            indicator,
            period_start: periodsDates?.startDate,
            period_end: periodsDates?.endDate,
            period_type: periodType,
            organization: activeOrganization?.id
        })
            .then((response) => {
                const {current_value, ...data} = response.data.values;

                if (data['number']) {
                    data[`${activeOrganization?.title}`] = data['number'];
                    delete data['number'];
                }

                console.log(data);
                setModalCharts(data);
            })
            .catch((e) => console.error(e))
            .finally(() => setIsLoading(false))

        if (id) {
            api.questionnaires.getRemarksField(id)
                .then(({data: {remarks}}) => {
                    setRemarks(remarks);
                })
                .catch((e) => console.error(e))
        }
    }, [indicator, modalVisible, periodsDates]);

    useEffect(() => {
        if (modalCharts) {
            const data = [];
            const arrData = Object.values(modalCharts).flat();

            const sort = arrData?.forEach(item => {
                return item?.items?.forEach(value => {
                    return value?.result?.forEach(res => data.push(res?.date))
                })
            })

            setPeriods([...new Set(data)]);
        }
    }, [modalCharts]);

    const checkCountOfValues = () => {
        if (!modalCharts)
            return;

        const result = sortArray(Object.values(modalCharts)?.map(i => i?.[0]?.items));

        if (result?.[0]?.length < 3)
            setActiveChartType('bar');
        else
            setActiveChartType('line');
    }

    const sortArray = (arr) => {
        if (!arr.length)
            return [];

        return arr?.map(item => {
            return item?.find(value => value.indicator === element?.webform_indicator)?.result
        });
    }

    const convertStrToNumber = (str) => {
        const arr = str.split(',');
        if (arr.length)
            return +arr.join('.');
    }

    const renderData = (obj) => {
        if (obj === 'current_value')
            return [convertStrToNumber(modalCharts[`${obj}`])];
        else if (obj === activeOrganization?.title && organisationData)
            return [+organisationData];
        else {
            const data = sortArray(modalCharts[`${obj}`]?.map(i => i?.items));

            if (activeChartType === 'line') {
                return data?.[0]?.map(item => +item?.indicator_value);
            } 
            else {
                const newData = [];
                const sortData = data?.[0]?.forEach(item => newData.push(+item?.indicator_value));

                return newData;
            }
        }
    }

    const calcAverageData = (sort = '') => {
        const averageData = {};
        const res = []

        if (!modalCharts) {
            return null;
        }

        const generateAverageData = Object.keys(modalCharts)?.forEach((item) => {
            const chartDataItem = renderData(item);
            if (!Number.isNaN(chartDataItem[0])) {
                averageData[item] = chartDataItem[0];
                res.push(chartDataItem[0]);
            }
        })

        return res;

        if (averageData && !sort) {
            return averageData?.average;
        } else if (averageData && sort === 'max') {
            return averageData?.maximum;
        } else if (averageData && sort === 'min') {
            return averageData?.minimum;
        } else {
            return 0;
        }
    }

    useEffect(() => {
        calcAverageData();
    }, [modalCharts]);

    const options = {
        chart: {
            type: activeChartType,
        }, 
        title: {
            text: element.title
        },
        xAxis: {
            categories: periods,
        }, 
        yAxis: {
            minorTicks: settingsBenchmark?.charts?.show_minor_ticks_lines === 'yes',
            lineWidth: 1,
            minorGridLineWidth: 0,
            minorTickInterval: 'auto',
            minorTickWidth: 3,
            plotLines: [
                {
                    color: 'gray',
                    width: 2,
                    value: settingsBenchmark?.charts?.show_average_lines !== 'no' ? calcAverageData() : null,
                    label: {
                        text: 'average line', align: 'left', y: 20
                    }
                }, 
                {
                    color: 'gray',
                    width: 2,
                    value: settingsBenchmark?.charts?.show_min_max_lines !== 'no' ? calcAverageData('max') : null,
                    label: {
                        text: 'max line', align: 'center', y: 20
                    }
                }, 
                {
                    color: 'gray',
                    width: 2,
                    value: settingsBenchmark?.charts?.show_min_max_lines !== 'no' ? calcAverageData('min') : null,
                    label: {
                        text: 'min line', align: 'right', y: 20
                    }
                }
            ]
        },
        plotOptions: {
            column: {
                pointPadding: 0.1, borderWidth: 1
            }
        },
        exporting: {
            chartOptions: {
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                }
            }, 
            scale: 3, 
            fallbackToExportServer: false
        }, 
        series: modalCharts && Object.keys(modalCharts)?.map((item, index) => {
            return {
                color: settingsBenchmark?.charts?.[`chart_color_${index + 1}`], data: renderData(item), name: item,
            }
        })
    }

    const handlerChangeTypeChart = (value) => {
        setActiveChartType(value);
    }

    const handlerChangePeriods = (date, dateString) => {
        const [startDate, endDate] = dateString;
        const newDates = {startDate, endDate};
        setPeriodsDates(newDates)
    }

    return (
        <div>
            <div className={'tab__header'}>
                <div className={'header__info'}>
                    <div className={'info'}>
                        <p className={'text'}>{t('your_value')}</p>
                        <p className={'value'}>{organisationData || '-'}</p>
                    </div>

                    <div className={'info'}>
                        <p className={'text'}>{t('number_of_values')}:</p>
                        <p className={'value'}>{comparisonGroup?.organisations_count || '-'}</p>
                    </div>
                </div>

                <div className={'select__header'}>
                    <RangePicker onChange={handlerChangePeriods} picker={periodType}/>
                    <p className={'text'}>{t('show')}</p>
                    <Select value={activeChartType} bordered={false} onChange={handlerChangeTypeChart} style={{width: "100px"}}>
                        {chartSelect?.map((item) => (<Option value={item} key={item}>{item}</Option>))}
                    </Select>
                </div>
            </div>

            {isLoading ? <Spin size="large"/> : <HighchartsReact highcharts={Highcharts} options={options}/>}

            <div className="modal__footer">
                <h4>{t('description')}:</h4>
                <p>{element.description}</p>

                {!!remarks?.length && (
                    <>
                        <h4>{t('remarks')}:</h4>
                        {remarks?.map(item => (
                            <div key={item.id}>
                                <b>{item.user}:</b>
                                <p>{item.text}</p>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </div>
    );
};

export default Charts;
