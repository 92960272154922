import { Button, Upload } from "antd";
import Help from "../../../Help";
import More from "../../../More";
import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons"; 

export default function FileElement({active, disabled, element, isChild = false, submission}) {
    const [data, setData] = useState(submission?.data?.find((d) => d.key === element.webform_key) ?? null);
    
    const multiset = !!(submission?.parent_organisation);
    const submittable = !!submission && submission.progress.basic.filled === submission.progress.basic.count_required;
    const isDisabled = (multiset && data?.is_default_value) || submittable || (submission?.completed && !isChild) || disabled;

    return (
        <>
            <div className="qs-area">
                {!isChild && (
                    <Help active={active} help={element.help} required={element.required} title={element.title} nvh={data?.nvh} nvt={data?.nvt}/>
                )}

                <Upload>
                    <Button>
                        <UploadOutlined disabled={isDisabled || data?.nvh || data?.nvt}/>
                        {" "}
                        Click to Upload
                    </Button>
                </Upload>

                {!isChild && <More more={element.more} moreTitle={element.more_title} />}
            </div>
        </>
    );
}