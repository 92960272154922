import React, {useState} from "react";
import {withTranslation} from "react-i18next";
import {Input, Button} from "antd";

import './ModalSavedView.scss';

const ModalSavedView = ({
  t,
  handlerCancelClick,
  handlerSavedView
}) => {

  const [viewName, setViewName] = useState(null);

  const handlerInputViewName = (e) => {
    setViewName(e.target.value);
  }

  return (
    <div className={'modal__view'}>
      <p className={'view__label'}>{t('name_of_view')}</p>
      <Input
        value={viewName}
        placeholder={`${t('input_name')}...`}
        onChange={handlerInputViewName}
      />
      <div className={'action__block'}>
        <Button
          type={'danger'}
          className={'button button__cancel'}
          onClick={() => {
            setViewName(null);
            handlerCancelClick();
          }}
        >{t('cancel')}</Button>
        <Button
          type={'primary'}
          disabled={!viewName}
          className={'button button__save'}
          onClick={() => {
            handlerSavedView(viewName);
            setViewName(null);
          }}
        >{t('save')}</Button>
      </div>
    </div>
  )
};

export default withTranslation('benchmark')(ModalSavedView);