import React from 'react'
import { Switch, Router, Route, Redirect } from 'react-router-dom'
import history from './history'

import {
  Benchmark,
  BenchmarkNew,
  Dashboard,
    // DashboardCreate,
  ErrorPage,
  FilesOverview,
  Home,
  Login,
  Organisations,
  Questionnaire,
  Questionnaires,
  ReportBenchmark,
  ReportBlockPage,
  SkyQuestionnaire,
  StaticPage
} from './views'

import {
  UsersList,
  UserEdit,
  UserCreate,
  EditPersonalAccount
} from './views/Profile/pages';

import {
  FilePage, FolderPage, RulesPage
} from './views/FileManager/pages';

import {
  SavedView, SearchPage
} from './views/ReportBenchmark/pages';

import {
  PublicRoute,
  PrivateRoute
} from './components'

import './styles/app.scss'
import Axios from 'axios'

export default () => {

  let baseURL = process.env.REACT_APP_BASE_URL

  const langCode = window.localStorage.getItem('langCode')

  if (langCode) {
    baseURL += ('/' + langCode)
  }

  return <Router history={history}>
    <Switch>
      <PrivateRoute path='/' exact component={Home} />
      <PrivateRoute path='/skyquestionnaires/:id' exact component={SkyQuestionnaire} />
      <PrivateRoute path='/questionnaires' exact component={Questionnaires} />
      <PrivateRoute path='/questionnaires/:id' exact component={Questionnaire} />

      <PrivateRoute path='/dashboard' exact component={Dashboard} />
      {/* <PrivateRoute path='/dashboard/new' exact component={DashboardCreate} /> */}
      {/* <PrivateRoute path='/dashboard/:id' exact component={DashboardView} /> */}
      <PrivateRoute path='/report-block/:id' exact component={ReportBlockPage} />

      <PrivateRoute path='/file-manager' exact component={FolderPage} />
      <PrivateRoute path='/file-manager/:name/:id'  component={FilePage} />

      <PrivateRoute path='/file-manager/rules' component={RulesPage} />
      <PrivateRoute path='/file-overview' component={FilesOverview} />

      <PrivateRoute path='/pages/:id' exact component={StaticPage} />

      <PrivateRoute path='/profile' exact component={UsersList} />

      <PrivateRoute path='/profile/edit/:id' component={UserEdit} />

      <PrivateRoute path='/profile/create' exact component={UserCreate} />

      <PrivateRoute path='/profile/personal-edit' component={EditPersonalAccount} />

      <PrivateRoute path='/organisations' exact component={Organisations} />

      {/* <PrivateRoute path='/benchmark-old' exact component={Benchmark} /> */}

      <PrivateRoute path='/benchmark' exact component={BenchmarkNew} />

      <PrivateRoute path='/benchmark/:id&title=:formTitle' exact component={ReportBenchmark}  />

      <PrivateRoute path='/benchmark/:id&title=:formTitle/searchValue=:value' exact component={SearchPage}  />

      {/* <PrivateRoute path='/benchmark/:id/saved-view/:viewId' exact component={SavedView}  /> */}

      {/* <PrivateRoute path='/contact' exact component={Contact} /> */}

      {/* <PrivateRoute path='/test' exact component={Test} /> */}

      <PublicRoute path='/login' exact component={Login} />

      <Route path='/not-found/:id' component={ErrorPage} />

      <Redirect to='/not-found/1' />
    </Switch>
  </Router>
}
