import * as t from '../types'

const initialState = {
  benchmark: null,
  benchmarkSettings: null,
  benchmarkForms: null,
  comparisonGroup: null,
  allOrganization: null,
  searchOrganization: null,
  mapData: null,
  isFetching: false,
  isSearchLoader: false,
  savedViews: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case t.BENCHMARK_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      }

    case t.BENCHMARK_GET_DATA:
      return {
        ...state,
        benchmark: action.payload
      }

    case t.BENCHMARK_GET_SETTINGS:
      return {
        ...state,
        benchmarkSettings: action.payload
      }

    case t.BENCHMARK_GET_FORMS:
      return {
        ...state,
        benchmarkForms: action.payload
      }

    case t.BENCHMARK_GET_COMPARISON_GROUP:
      return {
        ...state,
        comparisonGroups: action.payload
      }

    case t.BENCHMARK_GET_ALL_ORGANIZATION:
      return {
        ...state,
        allOrganization: action.payload
      }

    case t.BENCHMARK_SEARCH_ORGANIZATION_DATA:
      return {
        ...state,
        searchOrganization: action.payload
      }

    case t.BENCHMARK_GET_MAP_DATA:
      return {
        ...state,
        mapData: action.payload
      }

    case t.BENCHMARK_GET_SAVED_VIEW:
      return {
        ...state,
        savedViews: action.payload,
      }

    case t.BENCHMARK_SEARCH_START:
      return {
        ...state,
        isSearchLoader: true,
      }

    case t.BENCHMARK_SEARCH_END:
      return {
        ...state,
        isSearchLoader: false,
      }

    default:
      return state
  }
}
