import axios from 'axios'
import http from './http'

const getSiteSettings = () => {
  const langCode = window.localStorage.getItem('langCode') 
  let baseURL = process.env.REACT_APP_BASE_URL

  if (langCode) {
    baseURL += ('/' + langCode)
  }

  return axios.get(baseURL + '/api/v1/site/settings')
    .then(data => {
      if (data.headers['content-language'] && !langCode) {
        window.localStorage.setItem('langCode', data.headers['content-language'])
      }

      const calculationsURL = process.env.REACT_APP_CALCULATIONS_URL
      if(calculationsURL){
        data.data.settings.calculation_url = calculationsURL;
      }

      return data
    })
}

const getTags = () => {
  return http({
    url: '/api/v1/taxonomy_vacabulary/totta_tags?_format=json',
    method: 'GET'
  })
}

export {
  getSiteSettings,
  getTags
}
