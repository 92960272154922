import React, { useEffect, useMemo, useState } from "react";
import { Select, Tooltip, Modal } from "antd";

import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import QuestionCircleOutlined from "@ant-design/icons/lib/icons/QuestionCircleOutlined";
import BenchmarkSave from "../../../../assets/images/svg/benchmarkSave";
import { ModalSavedView } from "./../../modal";

import { showConfirm } from "./../ReportFilters/confirm";

const { confirm } = Modal;
const { Option } = Select;

export default function ReportFavorites({
  t,

  savedFavorites,
  activeFavoriteView,

  handleSaveView,
  handleDeleteView,
  handleActiveView
}) {
  const [showModal, setShowModal] = useState(false);

  const handlerOpenViewModal = (bool) => setShowModal(bool);
  const handlerCloseModal = () => setShowModal(false);

  // useEffect(() => {
  //   window.sessionStorage.setItem('savedFavorites', JSON.stringify(savedFavorites));
  // }, [savedFavorites]);

  // useEffect(() => {
  //   window.sessionStorage.setItem('activeView', JSON.stringify(activeView));
  // }, [activeView]);

  const handlerDeleteSpecialView = (viewId) => {
    handleDeleteView(viewId);
    if (activeFavoriteView == viewId) setActiveView("");
  };

  const handlerSavedView = async (name) => {
    handleSaveView(name);
    setActiveView(name);

    handlerCloseModal();
  };

  const setActiveView = (name) => {
    handleActiveView(name);
  }

  return (
    <>
      <Modal
        title={t("saved_favorites")}
        footer={null}
        open={showModal}
        onCancel={handlerCloseModal}
      >
        <ModalSavedView
          handlerCancelClick={handlerCloseModal}
          handlerSavedView={handlerSavedView}
        />
      </Modal>

      <div className={"report__filters"}>
        <div className={"report__filters-selects"}></div>
        <div className={"report__filters-inputs"}>
          <div className={"report__filters--select"}>
            <Select
              value={activeFavoriteView}
              optionLabelProp="label"
              onChange={(value) => setActiveView(value)}
            >
              <Option value={""} key={""} label={t("saved_favorites")}>
                <Tooltip placement="left" title={t("saved_favorites")}>
                  <p>{t("saved_favorites")}</p>
                </Tooltip>
              </Option>

              {savedFavorites?.map((item) => (
                <Option value={item.id} key={item.id} label={item.name}>
                  <div className={"saved__views--option"}>
                    <Tooltip placement="left" title={item.name}>
                      <p>{item.name}</p>
                    </Tooltip>
                    <DeleteOutlined
                      onClick={() => {
                        const params = {
                          title: t("delete_modal_title"),
                          confirm,
                          icon: (
                            <QuestionCircleOutlined style={{ color: "red" }} />
                          ),
                          okFunc: () => handlerDeleteSpecialView(item.id),
                          cancelText: t("cancel"),
                        };
                        showConfirm(params);
                      }}
                    />
                  </div>
                </Option>
              ))}
            </Select>
          </div>
          <div
            className={"report__saves"}
            onClick={() => handlerOpenViewModal(true)}
          >
            <p className={"report__saves--text"}>{t("save_favorite")}</p>
            <BenchmarkSave className={"report__saves-img"} />
          </div>
        </div>
      </div>
    </>
  );
}