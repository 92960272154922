import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {Button, Input, Spin, Table} from "antd";

import './ FilesOverview.scss';
import FileTextOutlined from "@ant-design/icons/lib/icons/FileTextOutlined";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";
import moment from "moment/moment";

const { Search } = Input;

const FilesOverview = ({
 getFilesOverview,
 isFetching,
 t,
 user,
 downloadFiles,
 downloadAllFilesForOrganizations,
 downloadAllFiles,
 filesOverview
}) => {

  const [dataColumn, setDataColumn] = useState([]);

  useEffect(() => {
    getFilesOverview();
  }, [])

  const onSearch = (e) => {
    getFilesOverview(e);
  }

  useEffect(() => {
    if (filesOverview?.length) {
      const tableData = filesOverview?.map(item => {
        return {
          key: item.id,
          organization: user?.organisations?.find(org => org?.id === `${item.organization_id}`)?.title,
          filename: item?.name,
          last_update: moment(`${item?.updated_at}`).format('YYYY-MM-DD HH:mm:ss'),
          author: item?.author,
        }
      })

      setDataColumn(tableData);
    } else {
      setDataColumn([]);
    }
  }, [filesOverview]);

  const columns = [
    {
      title: t('organization'),
      dataIndex: 'organization',
      key: 'organization',
      render: text => (
        <p>{text}</p>
      )
    },
    {
      title: t('filename'),
      dataIndex: 'filename',
      key: 'filename',
      // sorter: (a, b) => a.filename.localeCompare(b.filename),
      render: text => (
        <div className={'folder__block'}>
          <FileTextOutlined />
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: t('upload_date'),
      dataIndex: 'last_update',
      key: 'last_update',
      render: text => <p>{text}</p>,
    },
    {
      title: t('author'),
      dataIndex: 'author',
      key: 'author',
      render: text => <p>{text}</p>,
    },
    {
      title: t('action'),
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <div className={'action__block'} style={{ justifyContent: 'center'}}>
          {
            user?.scopes?.find(i => i === 'file_download') &&
            <DownloadOutlined
              style={{ fontSize: '20px', marginRight: 0 }}
              onClick={() => handlerDownloadFile(record)}
            />
          }
        </div>
      )
    },
  ];

  const handlerDownloadFile = (record) => {
    downloadFiles({
      folderId: user?.organisations?.find(org => org.title === record.organization)?.id,
      fileId: record.key,
      fileName: record.filename,
    });
  };

  const handlerDownloadFiles = () => {
    var orgIds = user?.organisations?.map(org => org.id);

    if (orgIds != null)
      downloadAllFilesForOrganizations({
        organizations: orgIds
      });
  };

  const checkPagination = () => {
    if (dataColumn?.length > 12) {
      return { defaultPageSize: 12, defaultCurrent: 1, showSizeChanger: false };
    } else {
      return false;
    }
  };

  if (isFetching) {
    return <Spin />
  }

  return (
    <div className={'overview__block'}>
      <div className="search__block">
        <Button onClick={() => handlerDownloadFiles()} style={{ display: "flex", alignItems: "center" }}>
          <span>{t('download_all_files')}</span>
          <DownloadOutlined style={{ fontSize: '20px', marginRight: 0 }}/>
        </Button>
        <Search style={{ width: '50%'}} placeholder={'Search...'} onSearch={onSearch} allowClear={true}/>
      </div>
      <div className="table__block">
        <Table
          className={'file-manager__table'}
          columns={columns}
          dataSource={dataColumn}
          pagination={checkPagination()}
        />
      </div>
    </div>
  )
};

export default withTranslation('file_manager')(FilesOverview);