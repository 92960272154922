import React from "react";
import { Tooltip } from "antd";

const TabsHeader = ({ gridColumns }) => {
  return (
    <div className={"tabs__header"}>
      {gridColumns?.map((item, index) => (
        <Tooltip key={item.name + index} overlayInnerStyle={{textAlign: "center"}} overlayStyle={{maxWidth: "350px"}} placement={"top"} title={item.Tooltip}>
          <div className={"columns"} key={item.name + index}>
            {item.title}
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export default TabsHeader;
