import { Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

function TextSegmentationFilter(props) {
  const { filterChanged, filterState, item, popoverId, t } = props;

  const onFilterTypeChanged = (value) => {
    filterChanged({
      ...filterState,
      filter: value
    });
  }

  const onTextChanged = (value) => {
    filterChanged({
      ...filterState,
      search: value.target.value
    });
  }

  return (
    <>
      <Input allowClear={true} onChange={onTextChanged} placeholder={t("search")} value={filterState.search} />

      {item.filters.length > 0 && (
        <Select
          getPopupContainer={() => document.getElementById(popoverId)}
          onChange={onFilterTypeChanged}
          placeholder={t("select")}
          value={filterState.filter}
        >
          {item.filters.map((f, i) => (
            <Select.Option value={f} key={i}>
              {t(`text_filter_${f}`)}
            </Select.Option>
          ))}
        </Select>
      )}
    </>
  );
}

export default withTranslation("benchmark")(TextSegmentationFilter);
