import React, {useEffect, useMemo, useState} from "react";
import {withTranslation} from "react-i18next";
import {Pagination, Spin, Input, Popover, Checkbox} from "antd";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import FilterOutlined from "@ant-design/icons/lib/icons/FilterOutlined";

import './UsersList.scss';
import {UserItem} from "./components";
import {HelpCenter, NoRecords} from "../../../../components";
import history from "../../../../history";

const pageSize = 12;

const { Search } = Input;

const UsersList = ({
 t,
 isFetching,
 profileList,
 getProfileList,
 getProfileRoles,
 profileRoles,
}) => {

  const [checkboxData, setCheckboxData] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    totalPage: 0
  });
  const [roles, setRoles] = useState(null);
  const [search, setSearch] = useState(null);

  useEffect(() => {
    setPagination({
      current: 1,
      totalPage: +profileList?.total / pageSize,
    })
  }, [])

  useEffect(() => {
    if (!profileRoles) {
      getProfileRoles();
    }

    getProfileList({ page: 1 });
  }, []);

  useEffect(() => {
    if (profileRoles) {
      const rolesValues = profileRoles?.map(item => item.label);
      setCheckboxData(rolesValues);
    }
  }, [profileRoles]);

  const handlerCheckedRoles = (value) => {
    setCheckboxData(value);
    const rolesData = value?.map(item => {
      return profileRoles.find(role => role.label === item);
    })?.map(item => item.id)?.join(',');
    if (profileRoles?.length === rolesData.split(',')?.length) {
      setRoles(null)
    } else {
      setRoles(rolesData);
    }
  }

  const setFilterRoles = async () => {
    await getProfileList({ page: 1, roles, search });
    setPagination({
      current: 1,
      totalPage: +profileList?.total / pageSize,
    });
  };

  const popoverRolesContent = (
    <div className={'popover__content'}>
      <p className={'popover__title'}>{t('role')}</p>
      <Checkbox.Group
        options={profileRoles?.map(item => item.label)}
        value={checkboxData}
        onChange={handlerCheckedRoles}
      />
      <button className={'button__apply'} onClick={setFilterRoles}>{t('apply')}</button>
    </div>
  )

  const onSearch = async (value) => {
    setSearch(value);
    await getProfileList({ page: 1, roles, search: value })
  }

  const handleChangePage = async (page) => {
    await getProfileList({ page, roles, search });
    setPagination({
      current: page,
      totalPage: +profileList?.total / pageSize,
    })
  }

  const setProfileList = useMemo(() => {
    if (!isFetching) {
      return (
        <ul className={'user__list'} key={profileList?.page}>
          {
            profileList?.users
            ? profileList?.users?.map((item) =>
                <li key={item.id} className={'user__list-item'}>
                  <UserItem value={item} t={t} />
                </li>
              )
            : <li> <NoRecords text={t('no_users')} /> </li>
          }
          <li className={'pagination'}>
            <Pagination
              showSizeChanger={false}
              pageSize={pageSize}
              current={pagination?.current}
              total={+profileList?.total}
              onChange={handleChangePage}
            />
          </li>
        </ul>
      )
    } else {
      return (
        <Spin
          size={'large'}
          style={{
            paddingTop: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        />
      )
    }
  }, [profileList, pagination.current, isFetching]);

  return (
    isFetching
      ? <Spin
          size={'large'}
          style={{
            paddingTop: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        />
      : (
        <div className={'user__block'}>
          <div className={'user__actions'}>
            <div className={'user__edit-action'} onClick={() => history.push('/profile/personal-edit')}>
              {t('edit_profile')}
              <EditOutlined />
            </div>
            <div className={'user__create-action'} onClick={() => history.push('/profile/create')}>
              {t('add_user')}
              <span />
            </div>
          </div>
          <div className={'user__filters'}>
            <Popover
              placement="bottom"
              className={'filter__sort'}
              content={popoverRolesContent}
              trigger="click"
            >
              <p>{t('filtered_by_roles')}</p>
              <FilterOutlined />
            </Popover>
            <div className={'filter__search'}>
              <Search
                value={search || ''}
                placeholder={t('search_text')}
                allowClear={true}
                onChange={(e) => setSearch(e.target.value)}
                onSearch={onSearch}
                enterButton
              />
            </div>
          </div>
          {setProfileList}
          <HelpCenter />

          <div className={'feedback'}>{t('feedback')}</div>
        </div>
      )

  )
};

export default withTranslation('user')(UsersList);
