import { Button, Popconfirm } from "antd";
import React from "react";
import { withTranslation } from "react-i18next";

function ReopenButton({ canReopen, canUpdate, formActive, loadingSubmission, reopen, t }) {
  return (
    <Popconfirm 
      cancelText={t("general:no")} 
      disabled={ loadingSubmission || !canReopen || !formActive || !canUpdate} 
      onConfirm={() => reopen()}
      okText={t("general:yes")}
      title={<div dangerouslySetInnerHTML={{__html: t("be_careful")}}/>}>
      
      <Button disabled={!canReopen || loadingSubmission || !formActive || !canUpdate} style={{backgroundColor: "#134176", borderRadius: 21, color: '#FFF', height: 35, width: 195}} type="primary">
          {t("reopen")}
      </Button>

    </Popconfirm>
  );
}

export default withTranslation('questionnaire')(ReopenButton);