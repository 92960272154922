import { connect } from 'react-redux'

import Login from './Login'

const mapStateToProps = state => ({
  app: state.app,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
