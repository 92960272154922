import React, {useMemo} from "react";
import { Collapse } from "antd";
import CaretRightOutlined from "@ant-design/icons/lib/icons/CaretRightOutlined";
import BranchesOutlined from "@ant-design/icons/lib/icons/BranchesOutlined";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";

import './SearchCollapse.scss';

const { Panel } = Collapse;

const SearchCollapse = ({
 searchData,
 searchResultValue,
 t,
 periodName,
 value
}) => {

  const setTitle = (value) => {
    if (value === null) {
      return t('category')
    } else if (value.parent === null) {
      return t('subcategory')
    } else {
      return t('indicators')
    }
  };

  const setPeriods = (periodName) => {
    let result = [];
    result.push(periodName)

    if (searchData?.parent === null) {
      return result;
    } else if (searchData?.parent?.parent === null) {
      result.push(searchData?.parent?.title);
      return result;
    } else if (searchData?.parent?.parent?.parent === null) {
      result.push(searchData?.parent?.parent?.title);
      result.push(searchData?.parent?.title);
      return result;
    }
  }

  const panelHeader = useMemo(() => {
    return (
      <div className={'panel__header'}>
        <div className={'titles'}>
          <span>{setTitle(searchData?.parent)}</span>
          <div className={'report__title'}>
            {
              searchData?.title?.split(' ')
                ?.map(
                  (item) => item === searchResultValue
                  ? <span>{item} </span>
                  : <>{item} </>
                )
            }
          </div>
        </div>
        <div className={'steps'}>
          <div className={'icons'}>
            <BranchesOutlined />
          </div>
          {
            setPeriods(periodName)?.map((item, index) => (
              <div className={'step'} key={index}>
                {item ? <p className={'step__title'}>{item}</p> : null}
                <RightOutlined />
              </div>
            ))
          }
        </div>
      </div>
    )
  }, [searchData, value]);


  return (
    <Collapse
      className={'collapse__block'}
      expandIcon={
        ({ isActive }) => (
          searchData?.parent?.parent
          ? <CaretRightOutlined rotate={isActive ? 90 : 0} />
          : <button>{t('to_show')}</button>
        )
      }
      expandIconPosition={'right'}
    >
      <Panel
        header={panelHeader}
        key="1"
      >
      </Panel>
    </Collapse>
  );
};

export default SearchCollapse;
