import React from 'react'
import MaskedInput from 'antd-mask-input'
import { Input } from 'antd'

export default function ({ mask = '', prefix = '', suffix = '', ...rest }) {
  const formattedMask = mask.replace(/9/gi, "1").replace(/[\])}[{(]/g, "")
  return (
    <div className='c-customInputNumber'>
      {prefix && <div className='c-customInputNumber__prefix'>{prefix}</div>}
      {!!mask
        ? <MaskedInput
            mask={formattedMask}
            {...rest}
        />
        : <Input {...rest} />
      }
      {suffix && <div className='c-customInputNumber__suffix'>{suffix}</div>}
    </div>
  )
}