import { connect } from 'react-redux'

import PrivateRoute from './PrivateRoute'
import actions from '../../store/actions'

const mapStateToProps = state => ({
  user: state.user,
  settings: state.app.settings
})

const mapDispatchToProps = dispatch => ({
  getSiteSetting: () => dispatch(actions.app.getSiteSettings()),
  getUser: () => dispatch(actions.user.getUser()),
  getTags: () => dispatch(actions.app.getTags()),
  getBenchmarkSettings: () => dispatch(actions.benchmark.getBenchmarkSettings()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute)
