import { DownOutlined, RightOutlined } from '@ant-design/icons';
import React, { useState } from "react";
import { Typography } from 'antd';

export default function More({more, moreTitle}) {
    const [show, setShow] = useState(false);

    return (
        <>
            {more &&       
                <>
                    <div onClick={() => setShow(!show)} style={{ alignItems: 'center', cursor: 'pointer', display: 'flex', justifyContent: 'flex-start' }}>
                        { show ? <DownOutlined /> : <RightOutlined /> } 
                        <Typography.Text underline>{moreTitle}</Typography.Text>
                    </div>

                    { show && <div dangerouslySetInnerHTML={{ __html: more }} /> }
                </>
            }
        </>
    );
}