import React, {useState, useEffect, useMemo} from "react";
import {Spin} from "antd";
import {NoRecords} from "../../../index";

import './ModalOrganisationInfo.scss'

const ModalOrganisationInfo = ({
  t,
  organisationId,
  getOrganisationInfo
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [organisationData, setOrganisationData] = useState(null);


  useEffect(() => {
    setIsFetching(true)
    getOrganisationInfo(organisationId)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          setOrganisationData(data?.organisation);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setIsFetching(false));
  }, [organisationId]);

  const renderOrganisationInfo = useMemo(() => {
    if (organisationData) {
      return (
        <ul className={'info__list'} key={organisationData?.id}>
          <li className={'info__list--item'}>
            <p className={'label'}>{t('title')}</p>
            <div className={'info'}>{organisationData.title}</div>
          </li>
          <li className={'info__list--item'}>
            <p className={'label'}>{t('country_code')}</p>
            <div className={'info'}>{organisationData.country_code}</div>
          </li>
          <li className={'info__list--item'}>
            <p className={'label'}>{t('address')}</p>
            <div className={'info'}>{organisationData.address}</div>
          </li>
          <li className={'info__list--item'}>
            <p className={'label'}>{t('email')}</p>
            <div className={'info'}>{organisationData.email}</div>
          </li>
          <li className={'info__list--item'}>
            <p className={'label'}>{t('level')}</p>
            <div className={'info'}>{organisationData.level}</div>
          </li>
          <li className={'info__list--item'}>
            <p className={'label'}>{t('phone_number')}</p>
            <div className={'info'}>{organisationData.phone_number}</div>
          </li>
        </ul>
      )
    } else {
      return <NoRecords />
    }
  }, [organisationData]);

  return (
    <div className={'modal__organisation--info'}>
      {
        isFetching
          ? <Spin />
          : (
            <div className={'content'}>
              {renderOrganisationInfo}
            </div>
          )
      }
    </div>
  );
};

export default ModalOrganisationInfo;