import React from "react";

export default () => <svg xmlns="http://www.w3.org/2000/svg" width="15.591" height="14.525" viewBox="0 0 15.591 14.525">
  <g id="streamline-icon-pie-line-graph-desktop_140x140" data-name="streamline-icon-pie-line-graph-desktop@140x140" transform="translate(-2.417 -8.25)">
    <path id="Path_193" data-name="Path 193" d="M20.417,31.387a2.22,2.22,0,1,0,2.22-2.22,2.22,2.22,0,0,0-2.22,2.22Z" transform="translate(-15.597 -18.196)" fill="none" stroke="#134176" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    <path id="Path_194" data-name="Path 194" d="M40.833,29.167v2.22h2.22" transform="translate(-33.793 -18.196)" fill="none" stroke="#134176" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    <path id="Path_195" data-name="Path 195" d="M52.5,110.454a4.427,4.427,0,0,0,.952-2.538" transform="translate(-44.191 -88.382)" fill="none" stroke="#134176" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    <path id="Path_196" data-name="Path 196" d="M79.7,110.454a4.427,4.427,0,0,1-.952-2.538" transform="translate(-67.586 -88.382)" fill="none" stroke="#134176" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    <path id="Path_197" data-name="Path 197" d="M43.744,131.25h5.71" transform="translate(-36.387 -109.178)" fill="none" stroke="#134176" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    <path id="Path_198" data-name="Path 198" d="M2.917,90.417H17.508" transform="translate(0 -72.785)" fill="none" stroke="#134176" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    <path id="Path_199" data-name="Path 199" d="M2.917,8.75H17.508V19.535H2.917Z" fill="none" stroke="#134176" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    <path id="Path_200" data-name="Path 200" d="M78.75,27.836l.952-1.269,1.9,1.586,1.586-1.9" transform="translate(-67.586 -15.597)" fill="none" stroke="#134176" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    <path id="Path_201" data-name="Path 201" d="M113.75,65.118v-.952" transform="translate(-98.78 -49.39)" fill="none" stroke="#134176" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    <path id="Path_202" data-name="Path 202" d="M102.083,62.519V61.25" transform="translate(-88.382 -46.79)" fill="none" stroke="#134176" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    <path id="Path_203" data-name="Path 203" d="M90.417,57.32v-1.9" transform="translate(-77.984 -41.591)" fill="none" stroke="#134176" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    <path id="Path_204" data-name="Path 204" d="M78.75,65.118v-.952" transform="translate(-67.586 -49.39)" fill="none" stroke="#134176" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
  </g>
</svg>
