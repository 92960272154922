import React from 'react';

export default () => <svg xmlns="http://www.w3.org/2000/svg" width="25.804" height="20.914" viewBox="0 0 25.804 20.914">
    <g id="Group_2040" data-name="Group 2040" transform="translate(-1323.696 -309.5)">
        <line id="Line_609" data-name="Line 609" x2="19.302" transform="translate(1324.196 315.195)" fill="none" stroke="#1e3968" stroke-width="1"/>
        <path id="Path_368" data-name="Path 368" d="M0,0H19.572" transform="translate(1324.196 318.97)" fill="none" stroke="#1e3968" stroke-width="1"/>
        <path id="Path_367" data-name="Path 367" d="M0,0H15" transform="translate(1324.353 322.743)" fill="none" stroke="#1e3968" stroke-width="1"/>
        <line id="Line_612" data-name="Line 612" y2="11.323" transform="translate(1328.715 315.195)" fill="none" stroke="#1e3968" stroke-width="1"/>
        <line id="Line_613" data-name="Line 613" y2="11.323" transform="translate(1333.748 315.195)" fill="none" stroke="#1e3968" stroke-width="1"/>
        <line id="Line_614" data-name="Line 614" y2="7.549" transform="translate(1338.78 315.195)" fill="none" stroke="#1e3968" stroke-width="1"/>
        <g id="Group_2036" data-name="Group 2036" transform="translate(1337.111 318.025)">
            <path id="Path_362" data-name="Path 362" d="M0,0H3.1V11H0Z" transform="translate(8.876 0.82) rotate(45)" fill="none" stroke="#1e3968" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
            <path id="Path_363" data-name="Path 363" d="M4.013,99.96l-1.1,3.289,3.29-1.1Z" transform="translate(-2.917 -91.361)" fill="none" stroke="#1e3968" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
            <path id="Path_364" data-name="Path 364" d="M105.279,5.929l-2.193-2.193.365-.365a1.551,1.551,0,1,1,2.193,2.193Z" transform="translate(-94.21 -2.917)" fill="none" stroke="#1e3968" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        </g>
        <path id="Path_366" data-name="Path 366" d="M5009.813,15818.35v-9.109h-19.064v16.428h12.389" transform="translate(-3666.552 -15499.24)" fill="none" stroke="#1e3968" stroke-width="1"/>
    </g>
</svg>
