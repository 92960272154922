import React from "react";
import {Form, Input, Button, message} from "antd";
import EyeTwoTone from "@ant-design/icons/lib/icons/EyeTwoTone";
import EyeInvisibleOutlined from "@ant-design/icons/lib/icons/EyeInvisibleOutlined";

import './UserChangePassword.scss';
import {validatePasswordMatch, validatePasswordStrong} from "../../../../utils";

const UserChangePassword = ({
  t,
  changeUserPassword
}) => {
  const [form] = Form.useForm();

  const onFinish = async (value) => {
    await changeUserPassword(value)
      .then((response) => {
        const { status } = response;

        if (status === 200) {
          message.success(t('password_update'));
        }
        return response;
      })
      .catch(() => message.error(t('wrong_old_password')));
  }

  return (
    <div className={'change__password'}>
      <Form form={form} onFinish={onFinish}>
        <p className={'title'}>{t('create_mew_password')}</p>
        <Form.Item
          name={'old_password'}
          rules={[
            {
              required: true,
              message: `${t('please_enter_old_password')}`,
            },
          ]}
        >
          <Input.Password
            placeholder={`${t('old_password')}*`}
            type={'password'}
          />
        </Form.Item>
        <Form.Item
          name={'password'}
          rules={[
            {
              required: true,
              message: `${t('please_enter_new_password')}`,
            },
            validatePasswordStrong(`${t('not_strong_password')}`),
          ]}
        >
          <Input.Password
            placeholder={`${t('new_password')}*`}
            type={'password'}
          />
        </Form.Item>
        <Form.Item
          name={'password_confirmation'}
          rules={[
            {
              required: true,
              message: `${t('please_confirm_new_password')}`,
            },
            validatePasswordMatch('password', `${t('password_not_match')}`),
          ]}
        >
          <Input.Password
            placeholder={`${t('confirm_password')}*`}
            type={'password'}
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        <Button className={'action__button'} htmlType={'submit'}>{t('confirm')}</Button>
      </Form>
    </div>
  );
};

export default UserChangePassword;
