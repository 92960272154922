import React from 'react';

import './Footer.scss';

const Footer = ({
 footerData,
}) => {

  return (
    footerData?.value &&
    <div className={'footer__content'} dangerouslySetInnerHTML={{__html: footerData?.value}} />
  );
};

export default Footer;