import axios from 'axios'
import history from '../../../../history'

let isRefreshing = false
let refreshToken = ''
let failedQueue = []
let baseURL = process.env.REACT_APP_BASE_URL
let clientId = process.env.DRUPAL_API_CLIENT_ID
let clientSecret = process.env.DRUPAL_API_CLIENT_SECRET
const langCode = window.localStorage.getItem('langCode')

if (langCode)
  baseURL += ('/' + langCode)

const processQueue = (error, token = null) => {
  failedQueue.forEach(promise => {
    if (error) {
      promise.reject(error)
    } else {
      promise.resolve(token)
    }
  })

  failedQueue = []
}

export default instance => {
  instance.interceptors.request.use(function (request) {
    const accessToken = window.localStorage.getItem('accessToken')
    const csrfToken = window.localStorage.getItem('csrfToken')
    
    if (accessToken != null)
      request.headers['Authorization'] = `Bearer ${accessToken}`

    if (csrfToken != null)
      request.headers['X-CSRF-Token'] = csrfToken

    return request
  })

  instance.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    const originalRequest = error.config

    if (error.response.status === 400 || error.response.status === 404 || (error.response.status === 403 && error.response.data?.errors?.length)) {
      return Promise.resolve(error.response)
    }

    if ((error.response.status === 401 && !originalRequest._retry) || (error.response.status === 403 && !originalRequest._retry)) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        }).then(token => {
          originalRequest.headers['Authorization'] = `Bearer ${token}`
          window.localStorage.setItem('accessToken', token)
          window.localStorage.setItem('userLogin', true)
          return axios(originalRequest)
        }).catch(err => {
          return Promise.reject(err)
        })
      }

      originalRequest._retry = true
      isRefreshing = true
      refreshToken = window.localStorage.getItem('refreshToken')

      return new Promise(function (resolve, reject) {
        axios.get(`${baseURL}/session/token`, { withCredentials: true })
          .then(({data:token}) => {
            originalRequest.headers['X-CSRF-Token'] = token
            window.localStorage.setItem('csrfToken', token)
          })
          .catch((err) => {

          })

        axios.request({
            url: "/oauth/token?refresh",
            method: "post",
            baseURL: baseURL,
            data: {
              "grant_type": "refresh_token",
              "refresh_token": refreshToken,
              "client_id": 'react_client',
              "client_secret": 'z*kFbbx*!kbGVRW7R2^0CJzs2#Eziz'
            },
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            }
          })
          .then((response) => {
            const { status, data } = response;
            originalRequest.headers['Authorization'] = `Bearer ${data.access_token}`
            window.localStorage.setItem('accessToken', data.access_token)
            window.localStorage.setItem('refreshToken', data.refresh_token)
            window.localStorage.setItem('userLogin', true)
            processQueue(null, data.access_token)
            resolve(axios(originalRequest))
          })
          .catch((err) => {
            window.localStorage.clear()
            history.push('/login')
            processQueue(err, null)
            reject(err)
          })
          .finally(() => { isRefreshing = false })
      })
    }

    const guestMode = window.localStorage.getItem('guest_mode')
    const userLogin = window.localStorage.getItem('userLogin');
    if (guestMode !== 'true' && userLogin !== 'true') {
      window.localStorage.clear()
      history.push('/login')
    } 
    else if (guestMode !== 'true' && userLogin === 'true') {
      history.push('/not-found/2')
    }

    return Promise.resolve(new Error('Unauthorized'))
  })
}
