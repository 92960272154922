import { Link } from "react-router-dom";
import React from "react";

export default function CategoryLink({ activeCategoryKey, category, onClick }) {
    return (
        <div className="containerListLink">
            <div className="listLink">
                {!!category.elements.length ? (
                    <>
                        <div className="titleLink">{category.title}</div>
                        {category.elements.map(cat => <CategoryLink activeCategoryKey={activeCategoryKey} category={cat} key={cat.webform_key} onClick={onClick} />)}
                    </>
                ) : (
                    <>
                        {category.is_visible && (
                            <Link 
                                className={category.webform_key === activeCategoryKey ? "activeLink" : "notActiveLink"} 
                                key={category.webform_key} 
                                onClick={() => onClick(category.webform_key)}
                                to="#">
                                {category.title}
                            </Link>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}