import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {Checkbox, Form, Input, Select, Button} from "antd";

import Camera from '../../../../assets/images/svg/camera.jsx';
import './UserForm.scss';
import {convertToBase64, generatePassword, validatePhoneNumber} from "../../../../utils";
import {Loading} from "../../../../components";
import {DeleteOutlined} from "@ant-design/icons";

const { Option } = Select;

const UserForm = ({
  t,
  createForm = false,
  profileUserInfo,
  profileRoles,
  isFetching,
  getUserData,
  deleteUser,
  personalPage = false,
  profileAllOrganization,
  getAllOrganizationForProfile,
}) => {
  const [form] = Form.useForm();

  const [userAvatarName, setUserAvatarName] = useState(null);
  const [userAvatarImage, setUserAvatarImage] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userOrganization, setUserOrganization] = useState(null);
  const [sendNewsByEmail, setSendNewsByEmail] = useState(false);
  const [isEdit, setIsEdit] = useState(!createForm ? profileUserInfo?.can_edit_users : false);
  const [generatePasswordData, setGeneratePasswordData] = useState(null);

  useEffect(() => {
    if (!profileAllOrganization) {
      getAllOrganizationForProfile();
    }
  }, [])

  const handlerCheck = (e) => {
    setIsEdit(e.target.checked);
  }

  const onFinish = async (value) => {
    let userInfo = {...value};
    userInfo.can_edit_users = isEdit;
    if (createForm) {
      userInfo.user_avatar = userAvatarImage;
      userInfo.password = generatePasswordData;
    }
    userInfo.newsletter_signup = sendNewsByEmail;
    if (!personalPage) {
      userInfo.organisations = userOrganization;
      userInfo.role = userRole || profileUserInfo?.roles?.[0] || profileRoles?.[0]?.id;
    }

    if (profileUserInfo != null) {
      if (userOrganization) {    
        profileUserInfo.organisations = userOrganization.map((item) => {
          return {
            id: item
          }
        })
      }
  
      if (userRole) {
        profileUserInfo.roles = userRole.map((item) => {
          return item
        })
      }
      else {
        profileUserInfo.roles = [profileUserInfo?.roles?.[0] || profileRoles?.[0]?.id]
      }
    }

    getUserData(userInfo);
  }

  const handlerGeneratePassword = () => {
    const password = generatePassword();
    form.setFieldsValue({
      password
    });
    setGeneratePasswordData(password);
  }

  const addUserAvatar = async (e) => {
    setUserAvatarName(e.target?.files[0]?.name);
    const avatarImage = await convertToBase64(e.target.files[0]);
    setUserAvatarImage(avatarImage);
  }

  const handlerChangeUserRole = (value) => {
    setUserRole(value);
  };

  const handlerAddOrganization = (value) => {
    setUserOrganization(value);
  };

  const handlerDeleteUser = () => {
    if (profileUserInfo?.id) {
      deleteUser(profileUserInfo?.id)
    }
  };

  const handlerDeleteAvatar = () => {
    setUserAvatarImage(null);
  };

  return (
    isFetching
    ? <Loading />
    : (
        <div className={'user__form'}>
          <div className={'characteristics'}>
            <p className={'title'}>{t('characteristics')}</p>
            <Form
              onFinish={onFinish}
              form={form}
              layout="vertical"
            >
              <Form.Item
                label={t('user_name')}
                name={'user_name'}
                rules={[{ required: true, message: `${t('warning_user_name')}` }]}
                initialValue={!createForm ? profileUserInfo?.display_name : ''}
              >
                <Input
                  disabled={!createForm}
                />
              </Form.Item>
              <Form.Item
                label={t('first_name')}
                name={'first_name'}
                rules={[{ required: true, message: `${t('warning_first_name')}` }]}
                initialValue={!createForm ? profileUserInfo?.first_name : ''}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('infixes')}
                name={'insertion'}
                rules={[{ required: false}]}
                initialValue={!createForm ? profileUserInfo?.insertion : ''}
                style={{ width: '122px' }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('last_name')}
                name={'last_name'}
                rules={[{ required: true, message: `${t('warning_last_name')}` }]}
                initialValue={!createForm ? profileUserInfo?.last_name : ''}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('function')}
                name={'function'}
                rules={[{ required: false}]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('email')}
                name={'email'}
                rules={[{ type: 'email', required: true, message: `${t('warning_email')}` }]}
                initialValue={!createForm ? profileUserInfo?.email : ''}
              >
                <Input />
              </Form.Item>
              {
                createForm && (
                  <>
                    <Form.Item
                      label={t('password')}
                      name={'password'}
                      rules={[{
                        required: true,
                        message: `${t('warning_password')}`
                      }]}
                    >
                      <Input value={generatePasswordData} disabled />

                    </Form.Item>
                    <Form.Item>
                      <span
                        className={'action__button generate__button'}
                        onClick={handlerGeneratePassword}
                      >{t('generate')}</span>
                    </Form.Item>
                  </>
                )
              }
              <Form.Item
                label={t('phone_number')}
                name={'phone_number'}
                rules={[{ required: false }]}
                initialValue={!createForm ? profileUserInfo?.telephone_number : ''}
              >
                <Input onInput={validatePhoneNumber} />
              </Form.Item>

              {
                createForm && (
                  <Form.Item
                    name={'user_avatar'}
                  >
                    <div className={'avatar'}>
                      <input
                        type={'file'}
                        className={'avatar__upload'}
                        onChange={addUserAvatar}
                      />
                      <div className={'avatar__icon'}>
                        {
                          userAvatarImage
                          ? <img src={userAvatarImage} alt="avatar"/>
                          : <Camera color={'blue'} />
                        }
                      </div>
                      <p className={'avatar__label'}>
                        {
                          userAvatarName
                            ? <>
                                {userAvatarName}
                                <DeleteOutlined onClick={handlerDeleteAvatar} />
                              </>
                            : t('add_profile_picture')
                        }
                      </p>
                    </div>
                  </Form.Item>
                )
              }

              <Form.Item
                name={'newsletter_signup'}
              >
                <Checkbox.Group>
                  <Checkbox
                    onChange={(e) => setSendNewsByEmail(e.target.checked)}
                  >{t('send_news')}</Checkbox>
                </Checkbox.Group>
              </Form.Item>

              <Form.Item
                name={'user_edit'}
              >
                  <Checkbox
                    checked={isEdit}
                    onChange={handlerCheck}
                  >{t('can_edit')}</Checkbox>
              </Form.Item>

              <Form.Item>
                <Button className={'action__button'} htmlType={'submit'}>{t('save')}</Button>
                <button className={'cancel__button reset__form'} onClick={() => form.resetFields()}>{t('cancel')}</button>
              </Form.Item>
            </Form>
          </div>

          <div className={'characteristics rights'}>
            <div className={'roles'}>
              <p className={'title'}>{t('rights')}</p>
              <div className={'select__block'}>
                <p className={'label'}>{t('user_profile')}</p>

                <Select
                  disabled={personalPage}
                  mode="multiple"
                  placeholder={t('please_select')}
                  defaultValue={
                    !createForm ? (profileUserInfo?.roles?.map(item => item)) : []
                  }
                  onChange={handlerChangeUserRole}
                >
                  {
                    profileRoles?.map(item => (
                      <Option value={item.id} key={item.id}>{item.label}</Option>
                    ))
                  }
                </Select>
              </div>
            </div>

            {
              !personalPage && (
                <div className={'organizations'}>
                  <p className={'title'}>{t('organizations')}</p>
                  <div className={'select__block'}>
                    <p className={'label'}>{t('organizations')}</p>

                    <Select
                      disabled={personalPage}
                      mode="multiple"
                      placeholder={t('please_select')}
                      defaultValue={
                        (!personalPage && !createForm) ? (profileUserInfo?.organisations?.map(item => item.id)) : []
                      }
                      onChange={handlerAddOrganization}
                    >
                      {
                        profileAllOrganization?.map(item => (
                          <Option value={item.id} key={item.id}>{item.title}</Option>
                        ))
                      }
                    </Select>
                  </div>
                </div>
              )
            }

            {
              !createForm &&
              <button
                className={'cancel__button delete__button'}
                onClick={handlerDeleteUser}
              >{t('delete_user')}</button>
            }
          </div>
        </div>
      )

  );
};

export default withTranslation('user')(UserForm);
