import * as t from '../types';

const initialState = {
  isFetching: false,
  profileList: null,
  profileRoles: null,
  profileUserInfo: null,
  profileAllOrganization: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case t.PROFILE_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      };

    case t.PROFILE_LIST:
      return {
        ...state,
        profileList: action.payload
      }

    case t.PROFILE_SET_ROLES:
      return {
        ...state,
        profileRoles: action.payload
      }

    case t.PROFILE_SET_USER_INFO:
      return {
        ...state,
        profileUserInfo: action.payload,
      }

    case t.PROFILE_SET_ALL_ORGANIZATIONS:
      return {
        ...state,
        profileAllOrganization: action.payload,
      }

    default:
      return state
  }
};