import React, {useEffect, useState} from 'react';
import {Header, TableBlock} from "../../components";
import {Spin} from "antd";

const FolderPage = ({
  getFolder,
  folderData,
  isFetching
}) => {
  const [searchData, setSearchData] = useState([]);
  const [searchActive, setSearchActive] = useState(false);

  useEffect(() => {
    getFolder()
  }, []);

  const handlerSearch = (data) => {
    if (!!data) {
      const newSearchData = folderData?.filter(item => item.name.toLowerCase().search(data.toLowerCase()) !== -1);
      setSearchData(newSearchData)
      setSearchActive(true);
      return;
    } else {
      setSearchActive(false);
    }
  }

  return (
    isFetching
    ? <Spin
        style={{
          paddingTop: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        size={'large'}
      />
    : (
      <>
        <Header showUploadButton={false} handlerSearch={handlerSearch} />
        <TableBlock folder={true} folderData={searchActive ? searchData : folderData} />
      </>
    )

  )
};

export default FolderPage;