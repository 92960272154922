import { DeleteOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import { Checkbox, message, Popover, Radio, Tooltip } from "antd";
import "./OrganisationGroupDropdown.scss";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Item from "antd/lib/list/Item";
import { api } from "../../../services";

export default function OrganisationGroupDropdown({
    availableGroups, 
    defaultGroup,
    handleAfterComparisonGroupDeleted,
    handleComparisonGroupEditClicked,
    handleSelectedComparisonGroupsChanged, 
    multiple = false
  }) {
  const[groups, setGroups] = useState([]);
  const[popoverVisible, setPopoverVisible] = useState(false);
  const {t, i18n} = useTranslation('organisation_groups');

  useEffect(() => {
    if (defaultGroup)
      setGroups([defaultGroup]);
  }, [defaultGroup, availableGroups]);

  const popoverContentMultiple = (
    <div className={"organisation-groups-dropdown_popover-content-wrapper"}>
      <div className={"organisation-groups-dropdown_popover-content"}>
        <p className={'organisation-groups-dropdown_popover-title'}>
          {t('saved_comparison_groups')}
        </p>
        <div className={"organisation-groups-dropdown_popover-checkboxes"}>
          <Checkbox.Group onChange={setGroups} value={groups}>
            {availableGroups?.map((group) => (
              <Checkbox key={group.id} value={group.id}>
                {group.name}
                <div className={'organisation-groups-dropdown_popover-radios-icons'}>
                  {
                    group.personal_group && (
                      <>
                        <EditOutlined onClick={() => {
                          handleComparisonGroupEditClicked(group);
                          setPopoverVisible(false);
                        }}/>
                        <DeleteOutlined onClick={() => handleDeleteComparisonGroup}/>
                      </>
                    )
                  }
                </div>
              </Checkbox>
            ))}  
          </Checkbox.Group>
        </div>
        <button className={"organisation-groups-dropdown_popover-button"}
          onClick={handleApplyOrganisationGroups}>
          {t('apply')}
        </button>
      </div>
    </div>
  );

  const popoverContentSingle = (
    <div className={"organisation-groups-dropdown_popover-content-wrapper"}>
      <div className={"organisation-groups-dropdown_popover-content"}>
        <p className={'organisation-groups-dropdown_popover-title'}>
          {t('saved_comparison_groups')}
        </p>
        <div className={"organisation-groups-dropdown_popover-radios"}>
          <Radio.Group onChange={handleSelectSingleOrganisationGroup}>
            <Radio key={'no group'} style={{ fontStyle: 'italic' }} value={null}>
              {t('no_comparison_group')}
            </Radio>
            {
              availableGroups?.map(group => (
                <Radio key={group.id} value={group.id}>
                  {group.name}
                  <div className={'organisation-groups-dropdown_popover-radios-icons'}>
                    {
                      group.personal_group && (
                        <>
                          <EditOutlined onClick={() => {
                            handleComparisonGroupEditClicked(group);
                            setPopoverVisible(false);
                          }}/>
                          <DeleteOutlined onClick={() => handleDeleteComparisonGroup}/>
                        </>
                      )
                    }
                  </div>
                </Radio>
              ))
            }
          </Radio.Group>
        </div>
        <button className={"organisation-groups-dropdown_popover-button"}
          onClick={handleApplyOrganisationGroups}>
          {t('apply')}
        </button>
      </div>
    </div>
  );

  function handleApplyOrganisationGroups() {
    setPopoverVisible(false);
    handleSelectedComparisonGroupsChanged(multiple ? groups : (groups.length > 0 ? groups[0] : null));
    message.success(t('filter_applied'));
  }

  function handleDeleteComparisonGroup(comparisonGroupId) {
    api.benchmark.deleteComparisonGroup(comparisonGroupId)
      .then((response) => {
        const { status } = response;

        if (status < 300) {
          handleAfterComparisonGroupDeleted(comparisonGroupId);
          message.success(t('group_deleted'));
        }
      })
      .finally(() => setPopoverVisible(false));
  }

  function handleSelectSingleOrganisationGroup(e) {
    const value = e.target.value;
    setGroups([value]);
  }

  return (
    <>
      <Popover
        content={multiple ? popoverContentMultiple : popoverContentSingle}
        onOpenChange={setPopoverVisible}
        open={popoverVisible}
        placement="bottomLeft"
        trigger={"click"}>
        <div className={"organisation-groups-dropdown_outside-wrapper"}>
          { t("comparison_groups") }
          <DownOutlined />
        </div>
      </Popover>
    </>
  )
}