import { connect } from 'react-redux'

import PublicRoute from './PublicRoute'
import actions from '../../store/actions'

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  getSiteSetting: () => dispatch(actions.app.getSiteSettings()),
  getBenchmarkSettings: () => dispatch(actions.benchmark.getBenchmarkSettings()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicRoute)
