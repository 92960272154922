import BenchmarkMap from "../../../../assets/images/svg/benchmarkMap";
import BranchesOutlined from "@ant-design/icons/lib/icons/BranchesOutlined";
import { Collapse, Modal, Popover } from "antd";
import GridOrganizationRow from "./components/GridOrganizationRow";
import InfoCircleOutlined from "@ant-design/icons/lib/icons/InfoCircleOutlined";
import ModalCharts from "../../modal/ModalCharts";
import ModalDrillDownGroups from "../../modal/ModalDrillDownGroups";
import ModalDrillDownOrganization from "../../modal/ModalDrillDownOrganization";
import { NoRecords } from "../../../../components";
import PieChartOutlined from "@ant-design/icons/lib/icons/PieChartOutlined";
import React, { useEffect, useState } from "react";
import { StarFilled, StarOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const ReportContent = ({
  activeOrganization,
  checkedList,
  comparisonGroups,
  defaultActiveKey,
  drillDownData,
  drillDownGroups,
  elementsData,
  endDate,
  favoriteIndicators,
  getBenchmarkDrillDown,
  isDrillDown,
  mapFile,
  onToggleFavorite,
  optionList,
  organizationData,
  periodType,
  reportData,
  settingsBenchmark,
  specialOrganisations,
  startDate,
  t
}) => {
  const [collapseActiveKey, setCollapseActiveKey] = useState([]);
  const [decimalPlaces, setDecimalPlaces] = useState(null);
  const [defaultTabKey, setDefaultTabKey] = useState(null);
  const [descriptionText, setDescriptionText] = useState(null);
  const [indexCharts, setIndexCharts] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [mapOrgData, setMapOrgData] = useState(null);
  const [modalCharts, setModalCharts] = useState(null);
  const [modalDrilldownGroupsVisible, setModalDrilldownGroupsVisible] = useState(false);
  const [modalDrilldownChildOrganizationVisible, setModalDrilldownChildOrganizationVisible] = useState(false);
  const [modalIndicator, setModalIndicator] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalValue, setModalValue] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [organisationData, setOrganisationData] = useState(null);
  
  const formatIndicatorValue = (value, tableItem) => {
    if (value === "-")
      return "-";

    let number = value;
    let decimals = tableItem.decimal_places ?? 2;
    let decpoint = tableItem.numeric_decimal ?? ",";
    let thousand = tableItem.numeric_thousand ?? ".";

    let n = Math.abs(number).toFixed(decimals).split(".");
    n[0] = n[0]
      .split("")
      .reverse()
      .map((c, i, a) =>
        i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c
      )
      .reverse()
      .join("");

    return (Math.sign(number) < 0 ? "-" : "") + n.join(decpoint);
  };

  useEffect(() => {
    setCollapseActiveKey(defaultActiveKey);
  }, [defaultActiveKey]);

  const closeModal = () => {
    setModalVisible(false);
  };

  const closeDrilldownGroupsModal = () => setModalDrilldownGroupsVisible(false);

  const closeDrilldownChildOrganizationModal = () => setModalDrilldownChildOrganizationVisible(false);

  const openModal = (
    value,
    reportData,
    number,
    tabKey = "charts",
    organisationData = null,
    indicator,
    mapOrgDataValue = false
  ) => {
    const currentWebform = elementsData?.find((item) => item.webform_indicator === indicator);

    setModalValue(value);
    setModalCharts(reportData);
    setIndexCharts(number);
    setModalTitle(value?.title);
    setModalVisible(true);
    setDefaultTabKey(tabKey);
    setOrganisationData(organisationData);
    setModalIndicator(indicator);
    setDecimalPlaces(currentWebform?.decimal_places);
    setDescriptionText(value?.description);

    if (mapOrgDataValue)
      setMapOrgData(mapOrgDataValue);
  };

  const openDrilldownModal = (indicator) => {
    setModalIndicator(indicator);
    setModalDrilldownGroupsVisible(true);
  };

  const openDrilldownOrganisationModal = (indicator) => {
    setModalIndicator(indicator);
    setModalDrilldownChildOrganizationVisible(true);
  };

  const setShowMap = () => settingsBenchmark?.maps?.show_maps_view === "yes";

  const handlerOpenCollapse = async (value, open) => {
    setIsOpen((prev) => !prev);

    if (!collapseActiveKey?.length) {
      const activeKeys = [];
      activeKeys.push(value.element_indicator);
      setCollapseActiveKey(activeKeys);
    } 
    else {
      const activeKeys = [...collapseActiveKey];
      const hasActiveKey = !!activeKeys.find((item) => item === value.element_indicator);

      if (hasActiveKey) {
        const newActiveKeys = activeKeys.filter((item) => item !== value.element_indicator);
        setCollapseActiveKey(newActiveKeys);
      } 
      else {
        activeKeys.push(value.element_indicator);
        setCollapseActiveKey(activeKeys);
      }
    }
  };

  function GridRow({ item, index }) {
    if (!!!item.is_benchmarking) 
      return null;

    const orgData = organizationData?.find(org => org.organization === activeOrganization.id)?.items?.find(
      (i) => i.indicator == item.webform_indicator
    );

    function GridRowRender({ org, number }) {
      return (
        <GridOrganizationRow
          activeOrganization={activeOrganization}
          checkedList={checkedList}
          comparisonGroups={comparisonGroups}
          drillDownData={drillDownData}
          index={index}
          indicatorValue={orgData?.result?.find(x => true)?.indicator_value}
          isLoader={isLoader}
          item={item}
          key={item.webform_indicator}
          number={number}
          openDrilldownModal={openDrilldownModal}
          openDrilldownOrganisationModal={openDrilldownOrganisationModal}
          openModal={openModal}
          org={org}
          organizationData={organizationData}
          reportData={reportData}
          settingsBenchmark={settingsBenchmark}
          specialOrganisations={specialOrganisations}
          t={t}
        />
      );
    }

    function FavoriteButton({ title, indicator, checked, toggleFavorite }) {
      let className = "favorite";
      if (checked) className += " checked";
    
      let icon = checked ? <StarFilled /> : <StarOutlined />;
    
      return (<div className={className} onClick={(evt) => {evt.stopPropagation(); toggleFavorite(title, indicator);}}>{icon}</div>);
    }

    function GridRowExtras({element, orgdata}) {
      const indicatorvalue = orgdata?.result?.find(x => true)?.indicator_value;
      const isFavorite = !!favoriteIndicators?.find((fav) => fav.indicator === element.webform_indicator);

      return (
        <div key={element.webform_indicator} style={{display: "flex"}}>
          <div 
            className={"map"} 
            onClick={(evt) => {
              evt.stopPropagation();
              openModal(element, reportData, index, "map", indicatorvalue, element.webform_indicator, indicatorvalue);
            }}
          >
            {setShowMap() && (
              <>
                <BenchmarkMap className={"map__img"} />
                {t("card")}
              </>
            )}
          </div>

          <div 
            className={"chart"} 
            onClick={(evt) => {
              evt.stopPropagation();
              openModal(element, reportData, 0, "charts", indicatorvalue, element.webform_indicator);
            }}
          >
            <PieChartOutlined />
            {t("chart")}
          </div>

          {!!element.is_multilevel && settingsBenchmark?.show_drilldown_in_own_hierarchy === "yes" && !!comparisonGroups.length && (
            <div 
              className={"chart"} 
              onClick={(evt) => {
                evt.stopPropagation(); 
                openDrilldownModal(element.webform_indicator);
              }}
            >
              <BranchesOutlined />
              {t("drilldown")}
            </div>
          )}

          {!!element.is_multilevel && settingsBenchmark?.show_drilldown_in_own_hierarchy === "yes" && (
            <div 
              className={"chart"} 
              onClick={(evt) => {
                evt.stopPropagation(); 
                openDrilldownOrganisationModal(element.webform_indicator);
              }}
            >
              <BranchesOutlined />
              {t("drilldown_organisation")}
            </div>
          )} 

          <div className={"info"}>
            {element.webform_indicator}
            {(element.description?.length ?? 0) > 0 && (
              <Popover content={element.description} title={t("description")}>
                <InfoCircleOutlined />
              </Popover>
            )}
          </div>
          
          <FavoriteButton
            checked={isFavorite}
            indicator={element.webform_indicator}
            title={element.title}
            toggleFavorite={onToggleFavorite}
          />
        </div>
      );
    }

    return (
      <Collapse activeKey={collapseActiveKey} key={item.webform_indicator} onChange={() => handlerOpenCollapse(item, !isOpen)}>
        <Panel className={"report__item"} extra={(<GridRowExtras element={item} orgdata={orgData}/>)} header={item.title} key={item.element_indicator}>
          {orgData?.result?.length ? (
            orgData?.result?.map((org, number) => (
              <GridRowRender key={`${org}-${item.webform_indicator}`} org={org} number={number} />
            ))
          ) : (
            <GridRowRender />
          )}
        </Panel>
      </Collapse>
    );
  }

  function Grid() {
    if (!!!elementsData) 
      return <NoRecords />;

    return elementsData?.map((element, index) => {

      if (element.type == 'webform_markup') {
        return (
          <div style={{background: '#134176', borderRadius: '5px', height: '100%', padding: '19px', width: '100%'}}>
              <div style={{color: 'white', fontSize: '14px', lineHeight: '18px'}} dangerouslySetInnerHTML={{ __html: element.markup }}/>
          </div>
        );
      }
      else
        return (<GridRow key={`gridrow-${element.webform_indicator}`} item={element} index={index} />);
    });
  }

  return (
    <>
      <Modal
        className={"report__modal"}
        footer={null}
        open={modalVisible}
        onCancel={closeModal}
        title={modalTitle}
        width={"1024px"}
      >
        <ModalCharts
          activeOrganization={activeOrganization}
          checkedColumns={checkedList}
          comparisonGroups={comparisonGroups}
          decimalPlaces={decimalPlaces}
          defaultTabKey={defaultTabKey}
          drillDownGroups={drillDownGroups}
          endDate={endDate}
          indexCharts={indexCharts}
          indicator={modalIndicator}
          mapFile={mapFile}
          mapOrgData={mapOrgData}
          modalCharts={modalCharts}
          modalValue={modalValue}
          modalVisible={modalVisible}
          optionList={optionList}
          organisationData={organisationData}
          periodType={periodType}
          settingsBenchmark={settingsBenchmark}
          showMap={setShowMap()}
          startDate={startDate}
          t={t}
        />
      </Modal>
      
      <Modal
        className={"report__modal"}
        footer={null}
        key={modalIndicator + 'group'}
        open={modalDrilldownGroupsVisible}
        onCancel={closeDrilldownGroupsModal}
        title={t("drilldown")}
        width={"600px"}
      >
        <ModalDrillDownGroups
          indicator={modalIndicator}
          groups={drillDownGroups}
          periodStart={startDate}
          periodEnd={endDate}
          calculationTypes={checkedList}
          t={t}
        />
      </Modal>

      <Modal
        className={"report__modal"}
        footer={null}
        key={modalIndicator + 'org'}
        open={modalDrilldownChildOrganizationVisible}
        onCancel={closeDrilldownChildOrganizationModal}
        title={t("drilldown")}
        width={"600px"}
      >
        <ModalDrillDownOrganization
          indicator={modalIndicator}
          organization={activeOrganization}
          periodStart={startDate}
          periodEnd={endDate}
          calculationTypes={checkedList}
          t={t}
        />
      </Modal>

      <Grid />
    </>
  );
};

export default ReportContent;
