import React, {useEffect} from 'react'
import {Redirect, Route} from 'react-router-dom'

const PublicRoute = ({component: Component, getSiteSetting, getBenchmarkSettings, ...rest}) => {
    useEffect(() => {
        getSiteSetting();
        getBenchmarkSettings();
    }, [])

    return <Route {...rest}
                  render={props =>
                      (window.localStorage.getItem('accessToken') && !rest.user.isGuest)
                          ? (<Redirect to={{pathname: '/'}}/>)
                          : <Component {...props} />
                  }
    />
}

export default PublicRoute
