import React, { Component, Fragment } from 'react'
import { AppstoreOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Select, Spin, Table } from 'antd';
import { CustomerTable, Card } from '../../components'
import { withTranslation } from 'react-i18next'
import history from '../../history'
import { api } from '../../services'

import chatText from '../../assets/images/svg/chat-text.svg'
import helpWheel from '../../assets/images/svg/icon-help-wheel.svg'
import layoutModule from '../../assets/images/svg/layout-module.svg'
import listBullets from '../../assets/images/svg/list-bullets.svg'

const { Column } = Table;

const { Option } = Select

const statuses = ['open', 'in_progress', 'completed', 'empty']

class Questionnaires extends Component {
  state = {
    forms: [],
    fetch: true,
    filterKeys: [],
    modeViewCart: true
  }

  fetchData(search = '') {
    this.setState({fetch: true});

    api.questionnaires.getForms(search)
      .then(({ data }) => {
        if (data.forms) {
          const webformIds = data.forms.map(({ id }) => id)

          if (webformIds.length) {
            const batchRequest = api.questionnaires.getProgressFormBatch(webformIds)

            return Promise.resolve(batchRequest)
              .then(results => {
                let response = results.data;
                const forms = [];

                if (response.id)
                  response = [response];

                response = Object.keys(response).map(function (key) {
                  return { ...response[key] }
                });

                response.forEach(webForm => {
                  const form = data.forms.find(({ id }) => webForm.id === id);

                  const progress = webForm.progress || { count: 0, filled: 0 };
                  const percent = progress.count_input ? Math.round(100 / progress.count_input * progress.filled) : 0;

                  let statusKey = 'open';
                  if (percent === 100) statusKey = 'completed';
                  else if (percent) statusKey = 'in_progress';
                  else if (progress.count_input === 0) statusKey = 'empty';

                  const status = statuses.find(i => i === statusKey);

                  forms.push({ ...form, status, statusKey, percent, progress })
                })

                return forms;
              })
          }

          return;
        }

        throw new Error(data.error);
      })
      .then(forms => {
        this.setState({
          forms,
          fetch: false
        })
      })
      .catch(error => console.log(error))
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const currentUserData = this.props.user.data
    const prevUserData = prevProps.user.data

    if (prevUserData.organisation !== currentUserData.organisation)
      this.fetchData();
  }

  handleSelect = filterKeys => {
    this.setState({ filterKeys });
  }

  onSetButton = (value) => {
    this.setState({modeViewCart: value});
  }

  onSearch = (value) => {
    this.fetchData(value);
  }

  render() {
    const { forms, fetch, filterKeys } = this.state;
    const { user: { data }, t } = this.props;

    let selectedForms = forms;

    if (filterKeys.length)
      selectedForms = forms.filter(i => filterKeys.includes(i.statusKey));

    const organisation = data.organisations.find(i => i.id === data.organisation);

    return (
      <div>
        <div className='containerSelects'>
          <div className='selectLeft'>
            <div className='selectQuestionnaires'>
                <Input disabled={fetch} onPressEnter={(e) => this.onSearch(e.target.value)} placeholder={t('Search') + '...'} size='large'></Input>
            </div>
          </div>

          {false && <Fragment>
            <div className='selectRight'>
              <div className='selectQuestionnaires'>
                <Select defaultValue='Tags'>
                  <Option value='Tags'>Tags</Option>
                </Select>
              </div>
              <div className='selectQuestionnaires'>
                <Select defaultValue='Status'>
                  <Option value='Status'>Status</Option>
                </Select>
              </div>
              <div className='selectQuestionnaires'>
                <Select defaultValue='Year'>
                  <Option value='Year'>Year</Option>
                </Select>
              </div>
              <div className='selectQuestionnaires'>
                <Select defaultValue='Quarter'>
                  <Option value='Quarter'>Quarter</Option>
                </Select>
              </div>
              <div className='selectQuestionnaires'>
                <Select defaultValue='Month' style={{ fontSize: '14px' }}>
                  <Option value='Month'>Month</Option>
                </Select>
              </div>
            </div>
            <div className='selectLeft'>
              <div className='selectQuestionnaires'>
                <Select defaultValue='25 per page' style={{ fontSize: '14px' }}>
                  <Option value='25 per page'>Show: 25 per page</Option>
                </Select>
              </div>
              <div className='selectQuestionnaires'>
                <Select defaultValue='Due date' style={{ fontSize: '14px' }}>
                  <Option value='Due date'>Sort by: Due date</Option>
                </Select>
              </div>
              <div className='containerButtons'>
                <Button type='default' style={{ marginRight: '4px' }} className={this.state.modeViewCart && 'activeButton'} onClick={() => this.onSetButton(true)}>
                  <UnorderedListOutlined />
                </Button>
                <Button type='default' className={(!this.state.modeViewCart) ? 'activeButton' : 'notAvtiveButton'} onClick={() => this.onSetButton(false)}>
                  <AppstoreOutlined />
                </Button>
              </div>
            </div>
          </Fragment>}
        </div>
        {
          fetch
            ? <div className='d-flex justify-content-center mt-5'>
              <Spin />
            </div>
            : this.state.modeViewCart
              ? <div className='containerQuestionnaires'>
                <CustomerTable t={t} selectedForms={selectedForms} pagination={false} />
              </div>
              : <Row gutter={24} type='flex' justify='start' align='top'>
                {selectedForms.map(item => <Col span={8} key={item.id}>
                  <Card
                    title={item.title}
                    onClick={() => history.push('/skyquestionnaires/' + item.id)}
                    type='skyquestionnaire'
                    item={item}
                  />
                </Col>
                )}
              </Row>
        }

        {false && <div className='containerHelpCenter'>
          <div className='containerHelpCenter_text'>
            <span style={{ color: '#000' }}>Any questions? Visit our Help Center.</span>
            <Button type='default' style={{ borderRadius: '18px', fontSize: '14px !important', width: '146px', height: '35px' }}>
              Go to Help center
            </Button>
          </div>
          <img src={helpWheel} alt='' />
        </div>
        }
      </div>
    );
  }
}

export default withTranslation()(Questionnaires)