import { GET_ORGANISATIONS_FETCHING, GET_ORGANISATIONS_SUCCESS } from '../types'
import { api } from '../../services'

const getOrganisations = () => dispatch => {
  dispatch({
    type: GET_ORGANISATIONS_FETCHING,
    payload: true
  })

  return api.organisations.getOrganisations()
    .then(({ data }) => {
      if (data.status) {
        dispatch({
          type: GET_ORGANISATIONS_SUCCESS,
          payload: data.organisations
        })
      }
      dispatch({
        type: GET_ORGANISATIONS_FETCHING,
        payload: false
      })
      return data.organisations
    })
}

export {
  getOrganisations
}
