import React from 'react'

export default () => <svg xmlns="http://www.w3.org/2000/svg" width="96.605" height="96.605" viewBox="0 0 96.605 96.605">
    <g id="streamline-icon-help-wheel_140x140_1_" data-name="streamline-icon-help-wheel@140x140 (1)" transform="translate(2 2)">
        <path id="Path_112" data-name="Path 112" d="M2.917,49.225a46.3,46.3,0,1,0,46.3-46.3,46.3,46.3,0,0,0-46.3,46.3Z" transform="translate(-2.917 -2.923)" fill="none" stroke="#8bc7e9" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
        <path id="Path_113" data-name="Path 113" d="M35,59.164A24.158,24.158,0,1,0,59.158,35.006,24.158,24.158,0,0,0,35,59.164Z" transform="translate(-12.855 -12.861)" fill="none" stroke="#8bc7e9" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
        <path id="Path_114" data-name="Path 114" d="M117.725,31.535,101.587,47.672" transform="translate(-33.482 -11.786)" fill="none" stroke="#8bc7e9" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
        <path id="Path_115" data-name="Path 115" d="M85.091,31.148l16.186-16.186" transform="translate(-28.372 -6.652)" fill="none" stroke="#8bc7e9" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
        <path id="Path_116" data-name="Path 116" d="M31.529,117.731l16.137-16.137" transform="translate(-11.78 -33.488)" fill="none" stroke="#8bc7e9" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
        <path id="Path_117" data-name="Path 117" d="M31.17,85.1,15.032,101.234" transform="translate(-6.67 -28.378)" fill="none" stroke="#8bc7e9" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
        <path id="Path_118" data-name="Path 118" d="M101.271,117.7,85.137,101.57" transform="translate(-28.386 -33.481)" fill="none" stroke="#8bc7e9" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
        <path id="Path_119" data-name="Path 119" d="M101.611,85.05l16.142,16.142" transform="translate(-33.489 -28.363)" fill="none" stroke="#8bc7e9" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
        <path id="Path_120" data-name="Path 120" d="M15.062,31.494,31.191,47.628" transform="translate(-6.679 -11.773)" fill="none" stroke="#8bc7e9" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
        <path id="Path_121" data-name="Path 121" d="M47.711,31.145,31.57,15" transform="translate(-11.793 -6.665)" fill="none" stroke="#8bc7e9" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
    </g>
</svg>
