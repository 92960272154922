import React from "react";
import ListInfo from "../../../BenchmarkNew/components/ListInfo";

const StepSubcategory = ({
  t,
  allGroups,
  actionFunction
}) => {
  return (
    <div className={'step'}>
      {
        allGroups?.map((item) => (
          <ListInfo
            key={item.title}
            id={item.title}
            title={item.title}
            actionFunction={actionFunction}
            buttonText={t('to_show')}
          />
        ))
      }
    </div>
  );
};

export default StepSubcategory;
