import {connect} from "react-redux";

import UserForm from './UserForm'
import actions from '../../../../store/actions';

const mapStateToProps = state => ({
  profileRoles: state.user.data.permissions.can_create_users_with_roles,
  isFetching: state.profile.isFetching,
  profileAllOrganization: state.profile.profileAllOrganization,
})

const mapDispatchToProps = (dispatch) => ({
  deleteUser: (id) => dispatch(actions.profile.deleteUser(id)),
  getAllOrganizationForProfile: () => dispatch(actions.profile.getAllOrganizationForProfile(true)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserForm)


