import axios from 'axios'

import {
  token,
  log,
  refresh
} from './interceptors'
import { setUrlParams } from "./setUrlParams";

let baseURL = process.env.REACT_APP_BASE_URL
const langCode = window.localStorage.getItem('langCode')

if (langCode) {
  baseURL += ('/' + langCode)
}

const instance = axios.create({
  baseURL: baseURL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-store'
  },
  data: {},
  withCredentials: true
})

token(instance)
log(instance)
refresh(instance)

export default instance
export { setUrlParams }
