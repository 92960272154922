import React from "react";
import ListInfo from "../../../BenchmarkNew/components/ListInfo";

const StepPeriods = ({
  t,
  periods,
  actionFunction,
}) => {
  return (
    <div className={'step'}>
      {periods?.map((item, index) => (
        <ListInfo
          key={item.name + index}
          id={item.name}
          title={item.name}
          buttonText={t('to_show')}
          actionFunction={actionFunction}
        />
      ))}
    </div>
  );
};

export default StepPeriods;