import StaticPage from './StaticPage'
import {connect} from "react-redux";
import actions from "../../store/actions";

const mapStateToProps = state => ({
    user: state.user,
    app: state.app
})

const mapDispatchToProps = dispatch => ({
    addFilesForFileManager: (params) => dispatch(actions.fileManager.addFilesForFileManager(params)),
    getStaticPageDocxById: (params) => dispatch(actions.report.getStaticPageDocxById(params))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StaticPage)
