import React, { Component, Fragment } from 'react'
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

const { Text } = Typography

export default class MoreItem extends Component {
  state = {
    show: false
  }

  render () {
    const { title, text } = this.props
    const { show } = this.state
    return (
      <Fragment>
        <div onClick={() => this.setState(state => ({ show: !state.show }))} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', cursor: 'pointer' }}>
          { show ? <DownOutlined /> : <RightOutlined /> } <Text underline>{title}</Text>
        </div>
        { show && <div dangerouslySetInnerHTML={{ __html: text }} /> }
      </Fragment>
    );
  }
}
