import { connect } from 'react-redux';
import Kart from './Kart';
import actions from "../../../../../../store/actions";

const mapStateToProps = (state) => ({
  mapData: state.benchmark.mapData,
  settingsBenchmark: state.benchmark.benchmarkSettings,
  isFetching: state.benchmark.isFetching,
});

const mapDispatchToProps = (dispatch) => ({
  getBenchmarkMap: (params) => dispatch(actions.benchmark.getBenchmarkMap(params)),
})


export default connect(mapStateToProps, mapDispatchToProps)(Kart)
