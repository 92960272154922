import http, {setUrlParams} from './http';

const getProfilesList = (page, per_page = 12, roles, search) => {
  return http.get(setUrlParams(
    '/api/v1/user/list',
    {page, per_page, roles, search}
  ))
};

const getProfileRoles = (profile) => {
  return http({
    url: `/api/v1/user/roles${profile ? '?profile=1' : ''}`,
    method: 'GET',
  });
}

const createUserProfile = (data) => {
  return http({
    url: '/api/v1/user/new',
    method: 'POST',
    data
  });
}

const getUserInfo = (id) => {
  return http({
    url: `/api/v1/user/${id}`,
    method: 'GET',
  });
};

const updateUserInfo = (id, data) => {
  return http({
    url: `/api/v1/user/${id}`,
    method: 'PUT',
    data
  })
}

const deleteUser = (id) => {
  return http({
    url: `/api/v1/user/${id}`,
    method: 'DELETE',
  })
}

export {
  getProfilesList,
  getProfileRoles,
  createUserProfile,
  getUserInfo,
  updateUserInfo,
  deleteUser
}