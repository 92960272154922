import * as t from '../types';

import { api } from '../../services';

const getFolder = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore()?.app.settings;
  const user = getFromStore()?.user.data;
  const params = {
    site_id: settings?.site_id,
    url: settings?.calculation_url,
    ...data,
  }

  dispatch({
    type: t.FILE_FETCHING,
    payload: true,
  })

  return api.file_manager.getFolders(params)
    .then((response) => {
      const { data, status } = response;
      const currentFolders = data?.folders?.filter(item => {
        return user.organisations?.find(org => org.id === item.id);
      })

      if (status === 200) {
        dispatch({
          type: t.FILE_GET_FOLDER,
          payload: currentFolders,
        });
      }
      return response;
    })
    .finally(() => {
      dispatch({
        type: t.FILE_FETCHING,
        payload: false,
      })
    })
};

const getFolderFiles = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore()?.app.settings;
  const params = {
    site_id: settings?.site_id,
    url: settings?.calculation_url,
    ...data,
  }

  dispatch({
    type: t.FILE_FETCHING,
    payload: true,
  });

  return api.file_manager.getFolderFiles(params)
    .then((response) => {
      const { status, data } = response;

      if (status === 200) {
        dispatch({
          type: t.FILE_GET_FILES,
          payload: data?.files,
        })
      }
      return response;
    })
    .finally(() => {
      dispatch({
        type: t.FILE_FETCHING,
        payload: false,
      })
    });
};

const addFilesForFileManager = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore()?.app.settings;
  const params = {
    site_id: settings?.site_id,
    url: settings?.calculation_url,
    ...data,
  };

  dispatch({
    type: t.FILE_FETCHING,
    payload: true,
  });

  return api.file_manager.addFilesForFileManager(params)
    .then((response) => {
      const { status, data: responseData } = response;

      if (status === 200 && responseData.status === 'success') {
        return response;
      }
    })
    .catch((error) => console.error('error ', error))
    .finally(() => {
      dispatch({
        type: t.FILE_FETCHING,
        payload: false,
      });
    });
};

const deleteFiles = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore()?.app.settings;
  const params = {
    site_id: settings?.site_id,
    url: settings?.calculation_url,
    ...data,
  };

  dispatch({
    type: t.FILE_FETCHING,
    payload: true,
  });

  return api.file_manager.deleteFiles(params)
    .then((response) => {
      const { status, data } = response;

      if (status === 200 && data.status === 'success') {
        return response;
      }
    })
    .catch((error) => console.error('error ', error))
    .finally(() => {
      dispatch({
        type: t.FILE_FETCHING,
        payload: false,
      });
    });
};

const downloadFiles = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore()?.app.settings;
  const params = {
    site_id: settings?.site_id,
    url: settings?.calculation_url,
    ...data,
  };

  const { fileName } = data;

  return api.file_manager.downloadFiles(params)
    .then((response) => {
      const { status, data, headers } = response;

      const file = new Blob(
        [data],
        {type: `${headers['content-type']}`}
      );

      const fileURL = URL.createObjectURL(file);

      if (status === 200) {
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = fileName;
        link.dispatchEvent(new MouseEvent('click'));
        return response;
      }
    })
    .catch((error) => console.error('error ', error))
};

const updateFile = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore()?.app.settings;
  const params = {
    site_id: settings?.site_id,
    url: settings?.calculation_url,
    ...data,
  };

  dispatch({
    type: t.FILE_FETCHING,
    payload: true,
  });

  return api.file_manager.updateFile(params)
    .then((response) => {
      const { status, data: responseData } = response;

      if (status === 200 && responseData.status === 'success') {
        return response;
      }
    })
    .catch((error) => console.error('error ', error))
    .finally(() => {
      dispatch({
        type: t.FILE_FETCHING,
        payload: false,
      });
    });
}

const fileHistory = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore()?.app.settings;
  const params = {
    site_id: settings?.site_id,
    url: settings?.calculation_url,
    ...data,
  };

  dispatch({
    type: t.FILE_FETCHING_HISTORY,
    payload: true,
  });

  return api.file_manager.fileHistory(params)
    .then((response) => {
      const { data, status } = response;

      if (status === 200) {
        dispatch({
          type: t.FILE_GET_HISTORY_FILES,
          payload: data?.files,
        });
      }

      return response;
    })
    .catch((e) => console.error(e))
    .finally(() => {
      dispatch({
        type: t.FILE_FETCHING_HISTORY,
        payload: false,
      });
    });
}

const downloadAllFilesForOrganizations = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore()?.app.settings;
  const params = {
    site_id: settings?.site_id,
    url: settings?.calculation_url,
    ...data,
  };

  const { organizations } = data;

  return api.file_manager.downloadAllFilesForOrganizations(params)
    .then((response) => {
      const { status, config: { url } } = response;

      if (status === 200) {
        const link = document.createElement('a');
        link.href = url;
        link.download = "Files";
        link.dispatchEvent(new MouseEvent('click'));
        return response;
      }
    })
    .catch((e) => console.error(e));
}

const downloadAllFiles = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore()?.app.settings;
  const params = {
    site_id: settings?.site_id,
    url: settings?.calculation_url,
    ...data,
  };

  const { folderId } = data;

  return api.file_manager.downloadAllFiles(params)
    .then((response) => {
      const { status, config: { url } } = response;

      if (status === 200) {
        const link = document.createElement('a');
        link.href = url;
        link.download = folderId;
        link.dispatchEvent(new MouseEvent('click'));
        return response;
      }
    })
    .catch((e) => console.error(e));
}

const getFilesOverview = (data = null) => (dispatch, getFromStore) => {
  const settings = getFromStore()?.app.settings;
  const params = {
    site_id: settings?.site_id,
    url: settings?.calculation_url,
    q: data
  };

  dispatch({
    type: t.FILE_FETCHING_HISTORY,
    payload: true,
  });

  return api.file_manager.getFilesOverview(params)
    .then((response) => {
      const { data } = response;

      dispatch({
        type: t.FILE_GET_FILES_OVERVIEW,
        payload: data?.files
      })

      return response;
    })
    .catch((e) => console.error('error: ', e))
    .finally(() => {
      dispatch({
        type: t.FILE_FETCHING_HISTORY,
        payload: false,
      });
    })
}

export {
  getFolder,
  getFolderFiles,
  addFilesForFileManager,
  deleteFiles,
  downloadFiles,
  updateFile,
  fileHistory,
  downloadAllFilesForOrganizations,
  downloadAllFiles,
  getFilesOverview
};
