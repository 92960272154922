import { connect } from 'react-redux'

import Questionnaires from './Questionnaires'

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Questionnaires)
