import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {Breadcrumb, Spin} from "antd";
import InfoCircleOutlined from "@ant-design/icons/lib/icons/InfoCircleOutlined";

import './FilePage.scss';
import history from "../../../../history";
import {Header, TableBlock} from "../../components";

const FilePage = ({
 t,
 fileData,
 isFetching,
 getFolderFiles,
}) => {
  const { name, id } = useParams();

  const [searchData, setSearchData] = useState([]);
  const [searchActive, setSearchActive] = useState(false);

  useEffect(() => {
    if (id) {
      getFolderFiles({folderId: id});
    }
  }, [])

  const handlerSearch = (data) => {
    if (!!data) {
      const newSearchData = fileData?.filter(item => item.name.toLowerCase().search(data.toLowerCase()) !== -1);
      setSearchData(newSearchData)
      setSearchActive(true);
      return;
    } else {
      setSearchActive(false);
    }
  }

  return (
    isFetching
    ? <Spin
        style={{
          paddingTop: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        size={'large'}
      />
    : (
      <div className={'organization-page'}>
        <div className={'files__header'}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item onClick={() => history.push('/file-manager')}>{t('organization')}</Breadcrumb.Item>
            <Breadcrumb.Item>{name}</Breadcrumb.Item>
          </Breadcrumb>
          <div className={'rules__block'} onClick={() => history.push('/file-manager/rules')}>
            <InfoCircleOutlined />
          </div>
        </div>
        <Header handlerSearch={handlerSearch} />

        <TableBlock
          folder={false}
          fileData={searchActive ? searchData : fileData}
        />
      </div>
    )
  )
};

export default withTranslation('file_manager')(FilePage);
