import { connect } from 'react-redux';
import ReportFavorites from './ReportFavorites';
import ReportFavoritesHeader from './ReportFavoritesHeader';
import actions from "../../../../store/actions";

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
})

export { ReportFavoritesHeader };
export default connect(mapStateToProps, mapDispatchToProps)(ReportFavorites);
