import { connect } from "react-redux";

import actions from '../../../../store/actions';
import ModalEdit from "./ModalEdit";


const mapStateToProps = (state) => ({
  user: state.user.data,
});

const mapDispatchToProps = (dispatch) => ({
  updateFile: (params) => dispatch(actions.fileManager.updateFile(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalEdit);



