import React from "react";

import './ListInfo.scss';

const ListInfo = ({
  id,
  title,
  actionFunction,
  buttonText
}) => {
  return (
    <div className={'list__item'} onClick={() => actionFunction(id, title)}>
      <p className={'list__title'}>{title}</p>
      <div className={'list__actions'}>
        <button className={'list__button'}>
          {buttonText}
        </button>
      </div>
    </div>
  )
};

export default ListInfo;