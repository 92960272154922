import React from "react";
import { withTranslation } from "react-i18next";

import './NoRecords.scss';
import noRecords from '../../assets/images/svg/no-records.svg';

const NoRecords = ({ t, text }) => {
  return (
    <div className={'no-records'}>
      <img src={noRecords} alt="no data"/>
      <p>{text || t('text')}</p>
    </div>
  )
};

export default withTranslation('no_records')(NoRecords);