import React, { useEffect, useState } from "react";
import moment from "moment";
import { Select } from "antd";

const { Option } = Select;

const SelectPeriods = ({ getActivePeriods, allPeriods, selectedPeriod }) => {
  const [activePeriod, setActivePeriod] = useState(null);

  useEffect(() => {
    if (allPeriods?.length) {
      !selectedPeriod
        ? setActivePeriod(
            moment(
              allPeriods?.[0]?.condition?.period_start,
              "YYYY-MM-DD hh:mm:ss"
            ).valueOf() / 1000
          )
        : setActivePeriod(selectedPeriod);
    }
  }, [allPeriods]);

  const handlerSelectOption = (value) => {
    setActivePeriod(value);
    getActivePeriods(value);
  };

  return (
    <>
      {allPeriods?.length ? (
        <Select
          style={{ minWidth: "200px" }}
          value={activePeriod}
          onChange={handlerSelectOption}
        >
          {allPeriods?.map((period, index) => (
            <Option
              value={
                moment(
                  period?.condition?.period_start,
                  "YYYY-MM-DD hh:mm:ss"
                ).valueOf() / 1000
              }
              key={index + period?.name}
            >
              {period?.name}
            </Option>
          ))}
        </Select>
      ) : null}
    </>
  );
};

export default SelectPeriods;