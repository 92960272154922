import React, {useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import {withTranslation} from "react-i18next";

import './SavedView.scss';
import LeftOutlined from "@ant-design/icons/lib/icons/LeftOutlined";
import history from "../../../../history";
import {api} from "../../../../services";
import {Loading} from "../../../../components";
import ReportContent from "../../reports/ReportContent";
import {FilterBlock, TabsHeader} from "../../components";

const SavedView = ({
  t,
  getBenchmarkSpecialView,
  getBenchmarkOrgDataForCategory,
  getBenchmarkDrillDown,
  getBenchmarkDataForCategory,
  settingsBenchmark,
  getBenchmarkSettings
}) => {
  const { id, formTitle, viewId } = useParams()

  const [elementsData, setElementsData] = useState(null);
  const [organizationData, setOrganizationData] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [defaultActiveCollapseKey, setDefaultActiveCollapseKey] = useState([]);
  const [optionList, setOptionList] = useState(null);
  const [checkedList, setCheckedList] = useState(null);
  const [activeOrganisation, setActiveOrganisation] = useState(null);
  const [mapFile, setMapFile] = useState(null);

  useEffect(() => {
    if (settingsBenchmark) {
      setOptionList(Object.values(settingsBenchmark?.extra_columns)?.filter(item => !!item))
      setCheckedList(Object.values(settingsBenchmark?.extra_columns)?.filter(item => !!item));
    } else {
      setShowLoader(true);
      getBenchmarkSettings()
        .then((response) => {
          const { status, data: {settings} } = response;
          if (status === 200) {
            setOptionList(Object.values(settings?.extra_columns)?.filter(item => !!item))
            setCheckedList(Object.values(settings?.extra_columns)?.filter(item => !!item));
          }
          return response;
        })
        .catch((e) => console.error(e))
        .finally(() => setShowLoader(false));
    }
  }, []);

  const getReportData = async () => {
    if (viewId == null)
      return [];

    setShowLoader(true);

    await getBenchmarkSpecialView(viewId)
      .then(async (response) => {

        const { status, data: { data: { view_params } } } = await response;

        if (status === 200) {
          setStartDate(view_params.period_start);
          setEndDate(view_params.period_end);
          setActiveOrganisation(view_params.organization);
          setMapFile(view_params.mapFile);

          await api.questionnaires.getElements(view_params?.formId, view_params?.key)
            .then((response) => {
              if (response.status < 300)
                setElementsData(response.data);
            })
            .catch((error) => console.error(error));

          await getBenchmarkOrgDataForCategory({
            batch: false,
            calculation_type: 'average',
            organization: [view_params.organization?.id],
            indicator: view_params.indicator,
            period_start: view_params.period_start,
            period_end: view_params.period_end,
            period_type: view_params.period_type,
          })
            .then((response) => {
              if (response.status < 300)
                setOrganizationData(response.data);
            })
            .catch((error) => console.error(error));

          await getBenchmarkDataForCategory({
            batch: true,
            calculation_types: view_params.calculation_types,
            group_by: 'time_period',
            groups: view_params.groups,
            indicator: view_params.indicator,
            period_start: view_params.period_start,
            period_end: view_params.period_end,
            period_type: view_params.period_type,
          })
            .then((response) => {
              const { data, status } = response;
              if (status === 200)
                setReportData(data?.values);
            })
            .catch((e) => console.error(e.message))
            .finally(() => setShowLoader(false))
        }

        return response;
      })
      .catch((e) => console.error(e))
  }

  const handlerOpenAllCollapse = (value) => {
    if (value) {
      const defaultKey = elementsData?.webform?.elements?.map(item => item.element_indicator);
      setDefaultActiveCollapseKey([...defaultKey]);
    } 
    else
      setDefaultActiveCollapseKey([]);
  }

  const handlerCheckedFilters = (value) => setCheckedList(value);
  const handlerComeBack = () => history.push(`/benchmark/${id}&title=${formTitle}`);
  useEffect(() => getReportData(), []);

  return (
    <div className={'view__page'}>
      {showLoader && <Loading />}
      <div className={'come__back'} onClick={handlerComeBack}>
        <LeftOutlined />
        <span>{t('come_back')} to benchmark page</span>
      </div>

      <FilterBlock
        t={t}
        optionList={optionList}
        checkedList={checkedList}
        handlerCheckedFilters={handlerCheckedFilters}
        steps={4}
        handlerOpenAllCollapse={handlerOpenAllCollapse}
        viewPage={true}
      />
      <div className={'view__report'}>
        <div className={'report__analyse report__tabs'}>
          <TabsHeader
            activeOrganization={activeOrganisation}
            checkedList={checkedList}
          />
          {
            !reportData
              ? <Loading/>
              : <ReportContent
                optionList={optionList}
                mapFile={mapFile}
                checkedList={checkedList}
                organizationData={organizationData}
                elementsData={elementsData}
                reportData={reportData}
                settingsBenchmark={settingsBenchmark}
                startDate={startDate}
                endDate={endDate}
                defaultActiveKey={defaultActiveCollapseKey}
                t={t}
              />
          }
        </div>
      </div>
    </div>
  )
};

export default withTranslation('benchmark')(SavedView);