import { connect } from "react-redux";

import FilesOverview from "./ FilesOverview";
import actions from '../../store/actions';


const mapStateToProps = (state) => ({
  user: state.user.data,
  isFetching: state.fileManager.isFetching,
  filesOverview: state.fileManager.filesOverview,
});

const mapDispatchToProps = (dispatch) => ({
  getFilesOverview: (data) => dispatch(actions.fileManager.getFilesOverview(data)),
  downloadFiles: (params) => dispatch(actions.fileManager.downloadFiles(params)),
  downloadAllFilesForOrganizations: (params) => dispatch(actions.fileManager.downloadAllFilesForOrganizations(params)),
  downloadAllFiles: (params) => dispatch(actions.fileManager.downloadAllFiles(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(FilesOverview);

