import './AnalyseList.scss';
import ListInfo from "../ListInfo";
import React from "react";
import {withTranslation} from "react-i18next";

const AnalyseList = ({ t, forms, handlerSetFormId, user: { isGuest } }) => {
  return (
    <div className={'analyse-block'}>
      <div className={'analyse-list'}>
        {forms?.map((item) => (
          item?.is_security && !isGuest &&
          <ListInfo
            actionFunction={handlerSetFormId} 
            buttonText={t('view_questionnaire')}
            id={item.id}
            key={item.id}
            title={item.title}
          />
        ))}
      </div>
    </div>
  )
}

export default withTranslation('benchmark')(AnalyseList);
