import { connect } from 'react-redux';
import SegmentationFilters from "./SegmentationFilters";

const mapDispatchToProps = (dispatch) => ({
  
})

const mapStateToProps = (state) => ({

});

export default connect(
  mapDispatchToProps,
  mapStateToProps
)(SegmentationFilters);