import React from 'react'
import NumberFormat from 'react-number-format'

import './NumericPreview.scss'

export default function ({ settings = {}, value = '' }) {
  return !isNaN(+value)
    ? <NumberFormat
      disabled
      className={'numericPreview'}
      defaultValue={settings.numeric_decimal ? value.toString().replace(/\./g, settings.numeric_decimal) : value}
      decimalScale={Number(settings.decimal_places)}
      decimalSeparator={settings.numeric_decimal && settings.numeric_decimal}
      thousandSeparator={settings.numeric_thousand && settings.numeric_thousand}
      prefix={settings.field_prefix && `${settings.field_prefix} `}
      suffix={settings.field_suffix && ` ${settings.field_suffix}`}
    />
    : value

}
