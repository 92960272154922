import { connect } from "react-redux";

import actions from '../../../../../../store/actions';
import TabHistory from "./TabHistory";

const mapStateToProps = (state) => ({
  isHistoryFetching: state.fileManager.isHistoryFetching,
  historyFile: state.fileManager.historyFile,
});

const mapDispatchToProps = (dispatch) => ({
  fileHistory: (params) => dispatch(actions.fileManager.fileHistory(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabHistory);
