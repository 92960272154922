import React from 'react';
import {withTranslation} from "react-i18next";

import './UserCreate.scss';
import {HelpCenter} from '../../../../components';
import { UserForm } from "../../components";

const UserCreate = ({
  t,
  createUserProfile,
}) => {

  const getUserData = async (data) => {
    await createUserProfile(data);
  }

  return (
    <div className={'user__create'}>
      <p className={'create__title'}>{t('add_user')}</p>

      <UserForm createForm={true} getUserData={getUserData} />
      <HelpCenter style={{ width: '70%' }} />
      <div className={'feedback'}>{t('feedback')}</div>

    </div>
  );
};

export default withTranslation('user')(UserCreate);
