import React, { Component } from 'react'
import axios from 'axios'

import { Label, FormGroup, Button, Alert, Input } from 'reactstrap'
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'

import { withTranslation } from 'react-i18next'

import history from '../../history'

import { Loader } from '../../components'

import './Login.scss'
import {AUTH_SUCCESS} from "../../store/types";

const href = ''

class Login extends Component {
  state = {
    username: '',
    password: '',
    fetch: false,
    error: null
  }

  handleValidSubmit = (e, values) => {
    this.setState({ fetch: true, error: null })

    const { username, password } = values

    let baseURL = process.env.REACT_APP_BASE_URL

    axios.request({
      baseURL: baseURL,
      data: {
        "client_id": "react_client",
        "client_secret": "z*kFbbx*!kbGVRW7R2^0CJzs2#Eziz",
        "grant_type": "password", 
        "username": username,
        "password": password
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      method: "POST",
      url: "/oauth/token"
    })
      .then(({ data }) => {
        if (data) {
          window.localStorage.setItem('accessToken', data.access_token)
          window.localStorage.setItem('refreshToken', data.refresh_token)
          window.localStorage.setItem('userLogin', !!data)
          history.push('/')
        } else {
          throw new Error(data.message)
        }
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          error: (error.response && error.response.data.message) || 'Error'
        })
      })
      .finally(() => {
        this.setState({ fetch: false })
      })
  }

  handleForgot = () => {
    window.location.href = process.env.REACT_APP_BASE_URL + '/user/password'
  }

  render () {
    const { username, password, fetch, error } = this.state

    const { t, app: { settings } } = this.props

    return <div className='account-pages'>
      <div className='containerLogin'>
        {fetch && <Loader />}

        <div className='logo'>
          <a href='/'>
            <span><img src={settings.site_logo} alt='' /></span>
          </a>
          <p className='text-muted' />
        </div>

        {error && <Alert color='danger' isOpen={!!error}>
          <div>{error}</div>
        </Alert>}

        <Label className='title'>{t('welcome')} {t('please_login_to_continue')}</Label>

        <AvForm onValidSubmit={this.handleValidSubmit}>
          <AvGroup className=''>
            <Label for='username'>{t('username')}</Label>
            <AvInput name='username' id='username' value={username} required style={{ border: 'solid 2px #a0a7b7' }} />
            <AvFeedback>{t('errors:field_is_invalid')}</AvFeedback>
          </AvGroup>

          <AvGroup className=''>
            <Label for='password'>{t('password')}</Label>
            <AvInput type='password' name='password' id='password' value={password} required style={{ border: 'solid 2px #a0a7b7' }} />
            <AvFeedback>{t('errors:field_is_invalid')}</AvFeedback>
          </AvGroup>

          <FormGroup check style={{ marginBottom: 33, color: '#21416f' }}>
            <Label check>
              <Input type='checkbox' />
              {t('keep_me_logged_in')}
            </Label>
          </FormGroup>

          <FormGroup style={{ margin: 0 }}>
            <Button color='blue' className='buttonLogin'>{t('login')}</Button>
          </FormGroup>

          <Label style={{ width: '100%', textAlign: 'center', marginTop: 5 }}><a href={href} onClick={this.handleForgot} className='linkForgot'>{t('forgot_password')}</a></Label>
        </AvForm>

      </div>
    </div>
  }
}

export default withTranslation('login')(Login)
