import http from './http'

const login = data => {
  return http({
    url: '/user/login?_format=json',
    method: 'POST',
    data
  })
}

const getUser = () => {
  return http({
    url: '/api/v1/current_user?_format=json',
    method: 'GET'
  })
}

const updatePersonalUserInfo = (data) => {
  return http({
    url: '/api/v1/current_user?_format=json',
    method: 'PUT',
    data
  })
}

const changeUserPassword = (data) => {
  return http({
    url: '/api/v1/current_user/password',
    method: 'PUT',
    data
  });
};

const getActiveOrganisation = () => {
  return http({
    url: '/api/v1/current_user/organisation',
    method: 'GET'
  });
}

const updateActiveOrganisation = data => {
  return http({
    url: '/api/v1/current_user/organisation?_format=json',
    method: 'POST',
    data
  })
}

const getOrganisationInfo = id => {
  return http({
    url: `/api/v1/organisation/${id}`,
    method: 'GET'
  })
}

const logout = () => {
  return http({
    url: '/user/logout',
    method: 'GET'
  })
}

export {
  login,
  getUser,
  getActiveOrganisation,
  updateActiveOrganisation,
  logout,
  updatePersonalUserInfo,
  changeUserPassword,
  getOrganisationInfo,
}
