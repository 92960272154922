import React from "react";
import ListInfo from "../../../BenchmarkNew/components/ListInfo";

const StepCategory = ({
  t,
  category,
  actionFunction
}) => {
  return (
    <div className={'step'}>
      {
        category?.map((item) => (
          <ListInfo
            key={item.webform_indicator}
            id={item.title}
            title={item.title}
            actionFunction={actionFunction}
            buttonText={t('to_show')}
          />
        ))
      }
    </div>
  );
};

export default StepCategory;