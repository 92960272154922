import ReportBlockPage from './ReportBlockPage'
import {connect} from "react-redux";
import actions from "../../store/actions";

const mapStateToProps = state => ({
    app: state.app,
    user: state.user
})

const mapDispatchToProps = dispatch => ({ 
    getReportBlockDocxById: (params) => dispatch(actions.report.getReportBlockDocxById(params))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportBlockPage)