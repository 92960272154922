export default (instance) => {
  instance.interceptors.response.use(null, (err) => {
    let message

    if (err.config && err.response) {
      message = `${err.config.method.toUpperCase()} ${err.config.url} responded with status ${err.response.status}`
    } else {
      message = err.message
    }

    console.error(`ERROR: ${message}`)

    return Promise.reject(err)
  })
}
