import chatText from '../../assets/images/svg/chat-text.svg';
import checkCircle from '../../assets/images/svg/check-circle.svg';
import history from '../../history';
import { Progress, Table } from 'antd';
import React from 'react';
import { RightOutlined, WarningOutlined } from '@ant-design/icons';

import './CustomerTable.scss';

const { Column } = Table;

const CustomerTable = ({ selectedForms, pagination, t }) => {
  return (
    <Table
      dataSource={selectedForms}
      onRow={(r) => ({
        onClick: () => history.push('/skyquestionnaires/' + r.id)
      })}
      pagination={{
        hideOnSinglePage: pagination
      }}
      showHeader={false}
    >
      <Column
        dataIndex='title'
        key='firstName'
        className='tableTitle'
        render={(text) => (
          <div>
            {/* error warning noneMessages */}
            <div className='messages noneMessages'><WarningOutlined /> <span>Expires in 2 days</span></div>
            <span style={{ fontSize: '18px', color: '#21416f', fontWeight: '500' }}>
              {text}
            </span>
          </div>
        )}
        style={{ maxWidth: 100, background: 'red' }}
      />
      {/* <Column
        dataIndex='Autosaved'
        key='action'
        render={(text, item) => (
          <span style={{ display: 'flex', alignItems: 'center', fontStyle: 'italic', color: '#333333' }}>
            <Icon type='clock-circle' style={{ marginRight: '10px' }} /> Autosaved on 12-05-2020
          </span>
        )}
      /> */}
      <Column
        key='action2'
        render={(text, item) => (
          <span style={{ fontStyle: 'italic', color: '#333333' }}>
            <img src={chatText} style={{ marginRight: '5px' }} />{item.progress ? item.progress.count_input : 0} {item.progress === 1 ? t('general:question') : t('general:questions')}
          </span>
        )}
      />

      <Column
        dataIndex='percent'
        render={(text, item) => (
          <div style={{ color: '#333333' }}>
            <Progress
              percent={item.percent || 0}
              format={(value) => value === 100 ? <div style={{ marginTop: -16 }}><img src={checkCircle} alt='Check circle' style={{ marginRight: 9 }} />{t('statuses:completed')}</div> : value + '%'}
              style={{ width: item.percent === 100 ? '0%' : '100%' }}
            />
          </div>
        )}
        width='200px'
      />

      <Column
        title='Action'
        key='action3'
        align='right'
        render={() => (
          <span style={{ color: '#21416f' }}>
            <RightOutlined />
          </span>
        )}
      />
    </Table>
  );
}

export default CustomerTable
