import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { filter } from "lodash";

export default function GridOrganizationRow({
  activeOrganization,
  checkedList,
  comparisonGroups,
  drillDownData,
  formatIndicatorValue,
  index,
  indicatorValue,
  isLoader,
  item,
  number,
  openDrilldownModal,
  openDrilldownOrganisationModal,
  openModal,
  org,
  organizationData,
  reportData,
  settingsBenchmark,
  specialOrganisations,
  t
}) {
  if (isLoader)
    return (
      <div style={{alignItems: "center", display: "flex", justifyContent: "center", margin: "10px 0"}}>
        <Spin size={"large"} />
      </div>
    );

  let showIndividualScores = settingsBenchmark?.show_individual_scores === "yes";

  return (
    <div className={"report__content-block"} key={item.webform_indicator}>

      {showIndividualScores && (<ActiveOrganisation activeOrganization={activeOrganization} item={item} indicatorValue={indicatorValue} />)}

      {specialOrganisations?.map((org) => (
          <SpecialOrganisations
            index={number}
            item={item}
            key={org.id}
            org={org}
            organizationData={organizationData}
          />
        )
      )}
        
      <ComparisonGroupColumn drillDownData={drillDownData} item={item} />

      <ExtraColumns
        checkedList={checkedList}
        extra_columns={settingsBenchmark?.extra_columns}
        item={item}
        number={number}
        reportData={reportData}
      />
    </div>
  );
}

function IndicatorColumnRaw({
  decimal_places = 2,
  numeric_decimal = ",",
  numeric_thousand = ".",
  value,
}) {
  return (
    <div className={"report__content"}>
      {formatIndicatorValue(
        value || "-",
        decimal_places,
        numeric_decimal,
        numeric_thousand
      )}
    </div>
  );
}

function IndicatorColumn({ element, value }) {
  return (
    <IndicatorColumnRaw
      decimal_places={element.decimal_places}  
      numeric_decimal={element.numeric_decimal}
      numeric_thousand={element.numeric_thousand}
      value={value}
    />
  );
}

function ComparisonGroupColumn({ drillDownData, item }) {
  if (!!!drillDownData?.values?.average) 
    return null;

  return (<IndicatorColumn element={item} value={drillDownData.values.average?.[item.webform_indicator]?.organizationsResult}/>);
}

function SpecialOrganisations({ item, org, organizationData }) {
  let orgData = organizationData?.find((val) => val.organization === org.id);
  let indicator = orgData?.items.find((i) => i.indicator === item.webform_indicator);
  return (<IndicatorColumn element={item} key={org.id} value={indicator?.result?.[0]?.indicator_value} />);
}

function ExtraColumns({
  checkedList,
  extra_columns,
  item,
  number,
  reportData
}) {
  return checkedList?.map((obj) => {
    for (let key in extra_columns) {
      if (extra_columns[key] !== obj)
        continue;

      let val = reportData?.[key]?.[0]?.items?.find((i) => i.indicator == item.webform_indicator)?.result?.[0]?.indicator_value;
      return (<IndicatorColumn element={item} key={key} value={val} />);
    }
  });
}

function ActiveOrganisation({ activeOrganization, item, indicatorValue }) {
  return (<IndicatorColumn element={item} key={activeOrganization} value={indicatorValue} />);
}

function formatIndicatorValue(
  value,
  decimal_places = 2,
  numeric_decimal = ",",
  numeric_thousand = "."
) {
  if (value === "-")
    return "-";

  let number = value;
  let decimals = decimal_places ?? 2;
  let decpoint = numeric_decimal ?? ",";
  let thousand = numeric_thousand ?? ".";

  let n = Math.abs(number).toFixed(decimals).split(".");
  n[0] = n[0]
    .split("")
    .reverse()
    .map((c, i, a) => (i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c))
    .reverse()
    .join("");

  return (Math.sign(number) < 0 ? "-" : "") + n.join(decpoint);
}
