import React from "react";
import {useParams} from "react-router-dom";
import {withTranslation} from "react-i18next";

import history from "../../history";
import './ErrorPage.scss';

const ErrorPage = ({ t }) => {
  const { id } = useParams();

  return (
    <div className={'error__page'}>
      <p className={'title'}>{t('title')}</p>
      <p className={'subtitle'}>{t('text')}</p>

      <div className={'return__actions'}>
        {t('back_to_the')}
        <span className={'comeback'} onClick={() => history.go(-id)}>{t('prev_page')}</span>
        {t('back_to')}
        <span className={'comeback'} onClick={() => history.push('/')}>{t('back_home')}</span>
      </div>
    </div>
  )
};

export default withTranslation('error')(ErrorPage);
