import { connect } from 'react-redux';

import SkyQuestionnaire from './SkyQuestionnaire';
import actions from "../../store/actions";

const mapStateToProps = state => ({
  user: state.user,
  app: state.app
});

const mapDispatchToProps = dispatch => ({
  getSubmissionPeriods: (params) => dispatch(actions.questionnaires.getSubmissionPeriods(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SkyQuestionnaire);