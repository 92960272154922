import { combineReducers } from 'redux';

import app from './app';
import benchmark from './benchmark';
import fileManager from "./fileManager";
import profile from './profile';
import user from './user';

export default combineReducers({
  app,
  user,
  benchmark,
  fileManager,
  profile
});