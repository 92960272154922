import React, { Component } from 'react'
import { EditOutlined } from '@ant-design/icons';
import { Form, Table, Button, Modal, Input, Select, Radio, Spin } from 'antd';
import { withTranslation } from 'react-i18next'

import { api } from '../../services'
import history from '../../history'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px'
}

const href = ''

class OrganisationsPage extends Component {
  state = {
    visible: false,
    fetch: false,
    elements: [],
    modalFetch: false,
    modalOrganisation: null
  }

  componentDidMount () {
    this.setState({
      fetch: true
    })

    return Promise
      .all([
        api.organisations.getOrganisationForm(),
        this.props.getOrganisations()
      ])
      .then(([{ data }]) => {
        this.setState({
          fetch: false,
          elements: data.elements
        })
      })
  }

  handleCancel = () => {
    this.setState({
      visible: false,
      modalOrganisation: null
    })
  }

  mapElement = element => {
    const { form: { getFieldDecorator }, t } = this.props

    const { modalOrganisation } = this.state

    const { key, required, title, options = [], type } = element

    switch (type) {
      case 'textfield':
        return <Form.Item label={t(key)} key={key}>
          {getFieldDecorator(key, {
            initialValue: modalOrganisation ? modalOrganisation[key] : '',
            rules: [
              {
                required,
                message: `Please input ${title}!`
              }
            ]
          })(<Input />)}
        </Form.Item>
      case 'email':
        return <Form.Item label='Email' key={key}>
          {getFieldDecorator(key, {
            initialValue: modalOrganisation ? modalOrganisation[key] : '',
            rules: [
              {
                type: 'email',
                message: 'The input is not valid E-mail!'
              },
              {
                required,
                message: 'Please input your E-mail!'
              }
            ]
          })(<Input />)}
        </Form.Item>

      case 'select':
        return <Form.Item label={title} key={key}>
          {getFieldDecorator(key, {
            initialValue: modalOrganisation ? modalOrganisation[key] : '',
            rules: [
              {
                required,
                message: `Please select ${title}`
              }
            ]
          })(
            <Select
              placeholder='Please select'
              style={{ width: '100%' }}
              allowClear
            >
              {options.map(el => <Select.Option key={el.key}>{el.value}</Select.Option>)}
            </Select>
          )}
        </Form.Item>

      case 'radios':
        return <Form.Item label={title} key={key}>
          {getFieldDecorator(key, {
            initialValue: modalOrganisation ? modalOrganisation[key] : '',
            rules: [
              {
                required,
                message: `Please select ${title}`
              }
            ]
          })(
            <Radio.Group onChange={this.onChange}>
              {options.map(i => <Radio style={radioStyle} key={i.key} value={i.key}>{i.value}</Radio>)}
            </Radio.Group>
          )}
        </Form.Item>

      default:
        break
    }
  }

  handleSubmit = e => {
    e.preventDefault()

    const { modalOrganisation } = this.state

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const data = Object.keys(values)
          .map(key => ({ key, value: values[key] || '' }))

        this.setState({ fetch: true })
        this.handleCancel()

        return Promise.resolve()
          .then(() => {
            if (modalOrganisation) {
              return api.organisations.updateOrganisationById(modalOrganisation.id, { data })
            }
            return api.organisations.createOrganisation({ data })
          })
          .then(() => this.props.getOrganisations())
          .then(organisations => {
            this.setState({
              fetch: false
            })
            if (organisations.length === 1) {
              return this.props.updateActiveOrganisation({ organisation: organisations[organisations.length - 1].id })
                .then(() => {
                  history.push('/questionnaires')
                })
            }
          })
      }
    })
  }

  openEditModal = record => {
    this.setState({
      visible: true,
      modalFetch: true,
      modalOrganisation: record
    })

    return api.organisations.getOrganisationById(record.id)
      .then(({ data }) => {
        this.setState({
          modalOrganisation: data.organisation,
          modalFetch: false
        })
      })
  }

  render () {
    const {
      user: {
        data: {
          organisations = [],
          permissions = {}
        }
      },
      t
    } = this.props

    const { visible, elements, modalFetch, modalOrganisation, fetch } = this.state

    const columns = [
      { title: t('title'), dataIndex: 'title', key: 'title' },
      // { title: 'Email', dataIndex: 'email', key: 'email' },
    ]

    if (permissions.can_edit_own_organisations) {
      columns.push({
        title: t('action'),
        dataIndex: '',
        key: 'x',
        render: record => <a href={href} onClick={() => this.openEditModal(record)}><EditOutlined /></a>
      })
    }

    return <div>
      {visible && <Modal
        title={modalOrganisation ? `${t('edit')} ${modalOrganisation.title}` : t('create_organisation')}
        visible
        onCancel={this.handleCancel}
        footer={null}
      >
        {modalFetch
          ? <div className='d-flex justify-content-center mt-5 mb-5'>
            <Spin />
          </div>
          : <Form onSubmit={this.handleSubmit} {...formItemLayout}>
            {elements.map(this.mapElement)}
            <Form.Item key='submit'>
              <Button type='primary' htmlType='submit'>
                {modalOrganisation ? t('edit') : t('create')}
              </Button>
            </Form.Item>
          </Form>
        }

      </Modal>
      }

      <div className='row'>
        <div className='col-10'>
          <div className='page-title-box'>
            <h4 className='page-title'>{t('organisations')}</h4>
          </div>
        </div>
        {permissions.can_create_organisations && <div className='col-2'>
          <Button
            className='mt-3'
            style={{ float: 'right' }}
            type='primary'
            onClick={() => this.setState({ visible: true })}>{t('create')}</Button>
        </div>
        }
      </div>
      <Table
        loading={fetch}
        columns={columns}
        dataSource={organisations}
      />
    </div>
  }
}

export default withTranslation('organisations')(OrganisationsPage)
