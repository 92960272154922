import { connect } from 'react-redux';
import FilterBlock from './FilterBlock';
import actions from '../../../../store/actions';

const mapStateToProps = (state) => ({
  user: state.user,
  appSettings: state.app.settings,
  allOrganization: state.benchmark.allOrganization,
  searchOrganization: state.benchmark.searchOrganization,
  isSearchLoader: state.benchmark.isSearchLoader,
  comparisonGroups: state.benchmark.comparisonGroups || [],
});

const mapDispatchToProps = (dispatch) => ({
  getBenchmarkOrganization: () => dispatch(actions.benchmark.getBenchmarkOrganization()),
  getBenchmarkComparisonGroups: () => dispatch(actions.benchmark.getBenchmarkComparisonGroups()),
  getFilteredOrganizationForBenchmark: (form_id, activeFilters) => dispatch(actions.benchmark.getFilteredOrganizationForBenchmark(form_id, activeFilters)),
  searchByAllOrganizations: (params) => dispatch(actions.benchmark.searchByAllOrganizations(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterBlock);