import React from "react";
import {message, Spin} from "antd";
import {withTranslation} from "react-i18next";

import './EditPersonalAccount.scss';
import {UserChangePassword, UserForm, UserInfoBlock} from "../../components";
import {HelpCenter} from "../../../../components";

const EditPersonalAccount = ({
  t,
  isFetching,
  personalUserInfo,
  getUser,
  updatePersonalUserInfo,
  changeUserPassword,
}) => {

  const getUserData = async (value) => {
    await updatePersonalUserInfo(value)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          message.success(`${value?.user_name} ${t('successfully_edit')}`);
          getUser();
        }
      })
      .catch(() => message.error(t('error_edit_personal')));
  }

  return (
    isFetching
    ? <Spin
        size={'large'}
        style={{
          paddingTop: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      />
    : (
      <div className={'personal__edit'}>
        <UserInfoBlock
          t={t}
          profileUserInfo={personalUserInfo}
          updatePersonalUserInfo={updatePersonalUserInfo}
          personalPage={true}
        />
        <UserForm
          getUserData={getUserData}
          profileUserInfo={personalUserInfo}
          personalPage={true}
        />
        <UserChangePassword
          t={t}
          changeUserPassword={changeUserPassword}
        />
        <HelpCenter style={{ width: '70%' }} />
      </div>
    )
  );
};

export default withTranslation('user')(EditPersonalAccount);