import { connect } from 'react-redux';
import SegmentationFilter from "./SegmentationFilter";

const mapDispatchToProps = (dispatch) => ({
  
})

const mapStateToProps = (state) => ({

});


export default connect(
  mapDispatchToProps,
  mapStateToProps
)(SegmentationFilter);