import { CustomInputMask } from "../../../../../../components";
import { Button, Form, Input, InputNumber, Spin } from "antd";
import Help from "../../../Help";
import More from "../../../More";
import React, {useEffect, useState} from "react";
import Validation from "../../../Validation";

import "./Number.scss";

export default function NumberElement({active, disabled, element, isChild = false, loadingSubmission, onChange, submission}) {
    const [data, setData] = useState(submission?.data?.find((d) => d.key === element.webform_key) ?? null);
    const [isOverwritten, setIsOverwritten] = useState(!!submission?.data?.find((d) => d.key === element.webform_key)?.overwritten);
    const [value, setValue] = useState(Number(submission?.data?.find((d) => d.key === element.webform_key)?.value)?.toFixed(element.decimal_places ?? 2)?.replace(/\./g, element.numeric_decimal ?? ","));

    useEffect(() => {
        if (!loadingSubmission) {
            setData(submission?.data?.find((d) => d.key === element.webform_key) ?? null);
            setValue(submission?.data?.find((d) => d.key === element.webform_key)?.value?.toString()?.replace(/\./g, element.numeric_decimal ?? ","));
            setIsOverwritten(submission?.data?.find((d) => d.key === element.webform_key)?.overwritten);
        }
    }, [loadingSubmission, submission?.data?.find((d) => d.key === element.webform_key)?.value, submission?.data?.find((d) => d.key === element.webform_key)?.nvt, submission?.data?.find((d) => d.key === element.webform_key)?.nvh]);

    const isCalculation = (!!element.is_multilevel && !!submission && !!submission?.child_organisations?.length && !!element.is_propagation) || (!!element.is_autocalculated && !(!!element.is_multilevel && !!submission?.child_organisations?.length && !!element.is_propagation));
    const canOverwrite = !!element.numeric_settings_level_overwrite && !!submission?.child_organisations?.length;
    const multiset = !!(submission?.parent_organisation);
    const readonly = Object.hasOwn(element, 'readonly') ? !!element.readonly : false;
    const seperatorDecimal = element.numeric_decimal ?? ",";

    const isDisabled = readonly || (submission?.completed && !isChild) || (isCalculation && !canOverwrite) || (multiset && data?.is_default_value) || disabled;

    const inputProps = {};
    if (element.input_mask) inputProps.mask = element.input_mask;
    if (element.placeholder) inputProps.placeholder = element.placeholder;
    if ((element.decimal_places ?? 2) > 0) inputProps.precision = element.decimal_places ?? 2;

    return (
        <>
            {loadingSubmission ? (<Spin/>) : (
                <Form>
                    <div className="qs-area">
                        {!isChild && (
                            <Help active={active} help={element.help} required={element.required} title={element.title} nvh={data?.nvh} nvt={data?.nvt}/>
                        )}

                        <Form.Item key={element.webform_key + "_" + submission.organisation_id}>
                            {(element.type == 'number' && !element.input_mask) ? (
                                <div className="number-wrapper">
                                    {element.field_prefix && <div className='number-prefix'>{element.field_prefix}</div>}
                                    <InputNumber
                                        {...inputProps}
                                        decimalSeparator={seperatorDecimal}
                                        disabled={isDisabled || !!data?.nvt || !!data?.nvh}
                                        key={element.webform_key + "_" + submission.organisation_id}
                                        onBlur={(e) => onChange(submission.organisation_id, element.webform_key, e.target.ariaValueNow ?? "")}
                                        onChange={(e) => setValue(e?.toString()?.replace(seperatorDecimal, '.'))}
                                        style={{width: "100%"}}
                                        value={value}/>
                                    {element.field_suffix && <div className='number-suffix'>{element.field_suffix}</div>}
                                </div>
                            ) : (!element.input_mask ? (
                                <Input 
                                    defaultValue={value} 
                                    disabled={isDisabled || !!data?.nvt || !!data?.nvh}
                                    onBlur={(e) => onChange(submission.organisation_id, element.webform_key, e.target.value)}
                                    onChange={(e) => setValue(e.target.value)}
                                    placeholder={element.placeholder} 
                                    type="text"
                                    value={value}/>
                            ) : (
                                <CustomInputMask
                                    {...inputProps}
                                    defaultValue={value}
                                    disabled={isDisabled || !!data?.nvt || !!data?.nvh}
                                    key={element.webform_key + "_" + submission.organisation_id}
                                    mask={element.input_mask}
                                    onBlur={(e) => onChange(submission.organisation_id, element.webform_key, e.target.value)}
                                    onChange={(e) => setValue(e.target.value)}
                                    value={value}/>
                            ))}

                            {!readonly && isCalculation && !isChild && isOverwritten && (
                                <Button onClick={() => {setValue(''); onChange(submission.organisation_id, element.webform_key, '')}} style={{position: "absolute", right: 0}}>
                                    Waarde berekenen
                                </Button>
                            )}
                        </Form.Item>

                        {!isChild && <More more={element.more} moreTitle={element.more_title} />}

                        <Validation elementKey={element.webform_key} errors={submission?.validation?.errors} warnings={submission?.validation?.warnings}/>
                    </div>
                </Form>
            )}
        </>
    );
}