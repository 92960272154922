import { Button, Form, Input, Spin } from "antd";
import Help from "../../../Help";
import More from "../../../More";
import React, { useEffect, useState } from "react";

export default function TextAreaElement({active, disabled, element, isChild = false, loadingSubmission, onChange, submission}) {
    const [data, setData] = useState(submission?.data?.find((d) => d.key === element.webform_key) ?? null);
    const [value, setValue] = useState(submission?.data?.find((d) => d.key === element.webform_key)?.value);

    useEffect(() => {
        if (!loadingSubmission) {
            setData(submission?.data?.find((d) => d.key === element.webform_key) ?? null);
            setValue(submission?.data?.find((d) => d.key === element.webform_key)?.value);
        }
    }, [loadingSubmission, submission?.data?.find((d) => d.key === element.webform_key)?.value]);

    const isCalculation = (!!element.is_multilevel && !!submission && !!submission?.child_organisations?.length) || !!element.is_autocalculated;
    const isOverwritten = !!(data?.is_overwritten);
    const multiset = !!(submission?.parent_organisation);
    const readonly = Object.hasOwn(element, 'readonly') ? !!element.readonly : false;
    const isDisabled = (multiset && data?.is_default_value) || readonly || (submission?.completed && !isChild) || disabled;

    return (
        <>
            {loadingSubmission ? (<Spin/>) : (
                <Form>
                    <div className="qs-area">
                        {!isChild && (
                            <Help active={active} help={element.help} required={element.required} title={element.title} nvh={data?.nvh} nvt={data?.nvt}/>
                        )}
                        
                        <Form.Item>
                            <Input.TextArea 
                                className="questionnaireTextArea" 
                                defaultValue={value}
                                disabled={isDisabled || data?.nvh || data?.nvt}
                                key={element.webform_key}
                                onBlur={(e) => onChange(submission.organisation_id, element.webform_key, e.target.value)}
                                onChange={(e) => setValue(e)}
                                value={value}/>
                        </Form.Item>

                        {!readonly && isCalculation && isOverwritten && (
                            <Button>Waarde berekenen</Button>
                        )}

                        {!isChild && <More more={element.more} moreTitle={element.more_title} />}
                    </div>
                </Form>
            )}  
        </>
    );
}