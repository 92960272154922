import Topbar from './Topbar'
import { connect } from 'react-redux'

import actions from '../../store/actions'

const mapStateToProps = state => ({
  user: state.user,
  app: state.app
})

const mapDispatchToProps = dispatch => ({
  updateActiveOrganisation: data => dispatch(actions.user.updateActiveOrganisation(data)),
  getOrganisationInfo: data => dispatch(actions.user.getOrganisationInfo(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Topbar)
