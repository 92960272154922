import * as app from './app';
import * as benchmark from './benchmark';
import * as fileManager from './fileManager';
import * as organisations from './organisations';
import * as profile from "./profile";
import * as questionnaires from './questionnaires';
import * as report from "./report";
import * as user from './user';

export default {
  app,
  benchmark,
  fileManager,
  organisations,
  profile,
  questionnaires,
  report,
  user
}