import generator from 'generate-password';

export const generatePassword = () => {
  return generator.generate({
    length: 12,
    lowercase: true,
    uppercase: true,
    numbers: true,
    symbols: true
  });
};