import { connect } from "react-redux";

import Footer from "./Footer";

const mapStateToProps = (state) => ({
  footerData: state.app.settings?.site_footer
});

export default connect(mapStateToProps, null)(Footer);

