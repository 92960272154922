import React, {Fragment, useState} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {DownOutlined, LeftOutlined, InfoCircleOutlined} from '@ant-design/icons';
import {Dropdown, Menu, Modal} from 'antd';
// import logo from '../../assets/images/logo.png'
import profilePic from '../../assets/images/svg/people-man.svg'
import iconlogout from '../../assets/images/svg/logout.svg'
import {withTranslation} from 'react-i18next'
import history from '../../history'
import {Label} from "reactstrap";
import {ArrowBack} from "../index";

import './Topbar.scss';
import {ModalOrganisationInfo} from "./components";

const topbarTitle = t => ({
  '/': t('general:home'),
  '/questionnaires': t('general:questionnaires'),
  '/dashboard': t('general:reports'),
  '/file-manager': t('general:file_manager'),
  '/file-manager/': t('general:file_manager'),
  '/profile': t('general:users'),
  '/profile/': <ArrowBack title={t('user:back_to_user_list')} url={'/profile'}/>,
  '/benchmark': t('general:benchmark'),
  '/benchmark/': t('general:benchmark'),
})

const Topbar = props => {
  const {
    user: {
      isGuest,
      data: {
        organisations = [],
        organisation,
        data,
      }
    },
    app: {
      settings: {
        site_logo: logo,
        guest_mode: guestMode
      }
    },
    location: {
      pathname
    },
    t,
    getOrganisationInfo
  } = props

  const [modalVisible, setModalVisible] = useState(false);
  const [infoOrganisationId, setInfoOrganisationId] = useState(null);

  const handleSwitch = (id) => {
    const {
      user: {
        data: {organisation}
      },
      updateActiveOrganisation
    } = props

    return Promise.resolve()
      .then(() => {
        if (id === organisation) {
          return null
        }
        return updateActiveOrganisation({organisation: id})
      })
      .then(() => {
        window.location.reload()
      })
  }

  const handleLogout = () => {
    window.location.href = process.env.REACT_APP_BASE_URL + '/user/logout'
  }

  const handleLogin = () => {
    history.push('/login')
  }

  const handlerOpenModal = (id) => {
    setModalVisible(true);
    setInfoOrganisationId(id);
  }

  const menu = (
    <Menu
      selectedKeys={[organisation]}
      mode='inline'
      className={'topbar__menu'}
    >
      {organisations.map(i =>
        <Menu.Item key={i.id} className={'menu__item'}>
          <p onClick={() => handleSwitch(i.id)} style={{ width: '100%' }}>{i.title}</p>
          <div className={'menu__info'} onClick={() => handlerOpenModal(i.id)}>
            <InfoCircleOutlined />
          </div>
        </Menu.Item>
      )}
    </Menu>
  )

  const activeOrganisation = organisations.find(i => i.id === organisation)

  const closeModal = () => setModalVisible(false);

  return (
    <Fragment>
      <Modal
        width={600}
        footer={null}
        open={modalVisible}
        onCancel={closeModal}
        title={t('modal_title')}
      >
        <ModalOrganisationInfo
          t={t}
          organisationId={infoOrganisationId}
          getOrganisationInfo={getOrganisationInfo}
        />
      </Modal>
      <div className='navbar-custom'>
        <ul className='list-unstyled topnav-menu float-right mb-0'>

          {!isGuest ? (
              <>
                <li>
                  <div className='nav-link'>
                    <Dropdown overlay={menu} style={{ maxHeight: '700px' }}>
                        <a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
                          <img src={profilePic} style={{marginRight: '9px'}} alt=''/>
                          {data?.display_name} {!!activeOrganisation && ('(' + activeOrganisation.title + ')')}
                          <DownOutlined/>
                        </a>
                    </Dropdown>
                  </div>
                </li>

                <li>
                  <a className='nav-link' onClick={handleLogout}>
                    <img src={iconlogout} style={{marginRight: '9px'}} alt=''/> {t('header:logout')}
                  </a>
                </li>
              </>
            ) :
            (
              <>
                <li>
                  <div className='nav-link'>
                    <div style={{lineHeight: '1', marginTop: '22px'}}>{t('general:organisations')}</div>
                    <strong style={{lineHeight: '1', display: 'block'}}>{guestMode.organisation_name}</strong>
                  </div>
                </li>
                <li>
                  <a className='nav-link' onClick={handleLogin}>
                    <img src={profilePic} style={{marginRight: '9px'}} alt=''/> {t('header:login')}
                  </a>
                </li>
              </>
            )}
        </ul>

        <div className='logo-box'>
          <Link to='/' className='logo text-center'>
                    <span className='logo-lg'>
                    <img style={{display: 'block'}} src={logo} alt='' height='70'/>
                    </span>
          </Link>
        </div>

        {!isGuest && (
          <>
            {props.location.pathname.slice(0, 16) !== '/questionnaires/'
              ? <div className='topnav-menu-left'>
                    <span>
                    {
                      topbarTitle(t)[
                        `/${props.location.pathname.split('/')?.[1]}${props.location.pathname.split('/')?.length > 2 ? '/' : ''}`
                        ]
                    }
                    </span>

              </div>
              : <div className='topnav-menu-left_link'>
                <Link to='/questionnaires'>
                  <LeftOutlined style={{marginRight: 14}}/>{t('header:back_to_list')}
                </Link>
              </div>
            }
          </>
        )}
      </div>
    </Fragment>
  );
}

export default withTranslation('header')(connect()(Topbar))
