import React from "react";
import {withTranslation} from "react-i18next";

import './Loading.scss';

const Loading = ({ t }) => {
  return (
    <div className={'loading'}>
      <div className={'loading__blur'}>
        <div className={'loading__content'}>
          <div className={'loader'}>
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <p className={'loading__title'}>{t('text')}</p>
        </div>
      </div>
    </div>
  )
};

export default withTranslation('loader')(Loading);
