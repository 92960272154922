import axios from 'axios';

const getFolders = params => {
  const { url, site_id } = params;

  return axios.get(`${url}/files/folders?site_id=${site_id}`);
};

const getFolderFiles = params => {
  const { url, site_id, folderId } = params;

  return axios.get(`${url}/files/folders/${folderId}?site_id=${site_id}`);
}

const addFilesForFileManager = params => {
  const { url, site_id, category, folderId, formId, name, author, file, files } = params;

  return axios.post(`${url}/files/folders/${folderId}/batch`, {
    files,
    site_id,
    category,
    formId,
    name,
    author,
    file,
  });
};

const deleteFiles = params => {
  const { url, site_id, folderId, fileId } = params;

  return axios.delete(`${url}/files/folders/${folderId}/${fileId}?site_id=${site_id}`);
}

const downloadFiles = params => {
  const { url, site_id, folderId, fileId } = params;

  return axios.get(`${url}/files/folders/${folderId}/${fileId}?site_id=${site_id}`, {
    responseType: 'blob',
  });
}

const updateFile = params => {
  const { url, site_id, folderId, name, author, file, fileId } = params;

  return axios.put(`${url}/files/folders/${folderId}/${fileId}`, {
    site_id,
    name,
    author,
    file,
  });
};

const fileHistory = params => {
  const { url, folderId, fileId, site_id } = params;

  return axios.get(`${url}/files/folders/${folderId}/${fileId}/history?site_id=${site_id}`);
}

const downloadAllFilesForOrganizations = params => {
  const { url, organizations, site_id } = params;

  return axios.get(`${url}/files/folders/download?site_id=${site_id}&organizations=${organizations.join(',')}`, {
    responseType: 'blob',
  });
}

const downloadAllFiles = params => {
  const { url, folderId, site_id } = params;

  return axios.get(`${url}/files/folders/${folderId}/download?site_id=${site_id}`, {
    responseType: 'blob',
  });
}

const getFilesOverview = params => {
  const { url, site_id, q } = params;

  return axios.get(`${url}/files/overview?site_id=${site_id}&q=${q || ''}`);
}

export {
  getFolders,
  getFolderFiles,
  addFilesForFileManager,
  deleteFiles,
  downloadFiles,
  updateFile,
  fileHistory,
  downloadAllFilesForOrganizations,
  downloadAllFiles,
  getFilesOverview
};
