import React from "react";

import "./Validation.scss";

export default function Validation({elementKey, errors, warnings}) {
  return (
    <>
      {!!errors && !!(errors[elementKey]) && (
        <div className={"validation_error"} dangerouslySetInnerHTML={{__html: errors[elementKey]}}></div>
      )} 

      {!!warnings && (!!!errors || !!!(errors[elementKey])) && !!(warnings[elementKey]) && (
        <div className={"validation_warning"} dangerouslySetInnerHTML={{__html: warnings[elementKey]}}></div>
      )}
    </>
  )
}