import React from 'react'

export default ({setActive}) => <svg xmlns="http://www.w3.org/2000/svg" width="15.591" height="15.591" viewBox="0 0 15.591 15.591">
  <g id="streamline-icon-help-wheel_140x140" data-name="streamline-icon-help-wheel@140x140" transform="translate(-2.417 -2.423)">
    <path id="Path_54" data-name="Path 54" style={{ fill: 'none', stroke: setActive ? '#000000' : '#21416f', strokeLinecap: 'round', strokeLinejoin: 'round' }} d="M2.917,10.218a7.3,7.3,0,1,0,7.3-7.3A7.3,7.3,0,0,0,2.917,10.218Z" transform="translate(0)" />
    <path id="Path_55" data-name="Path 55" style={{ fill: 'none', stroke: setActive ? '#000000' : '#21416f', strokeLinecap: 'round', strokeLinejoin: 'round' }} d="M35,38.812a3.806,3.806,0,1,0,3.806-3.806A3.806,3.806,0,0,0,35,38.812Z" transform="translate(-28.594 -28.594)" />
    <path id="Path_56" data-name="Path 56" style={{ fill: 'none', stroke: setActive ? '#000000' : '#21416f', strokeLinecap: 'round', strokeLinejoin: 'round' }} d="M104.13,31.535l-2.543,2.543" transform="translate(-87.94 -25.501)" />
    <path id="Path_57" data-name="Path 57" style={{ fill: 'none', stroke: setActive ? '#000000' : '#21416f', strokeLinecap: 'round', strokeLinejoin: 'round' }} d="M85.091,17.513l2.55-2.55" transform="translate(-73.237 -10.731)" />
    <path id="Path_58" data-name="Path 58" style={{ fill: 'none', stroke: setActive ? '#000000' : '#21416f', strokeLinecap: 'round', strokeLinejoin: 'round' }} d="M31.529,104.136l2.543-2.543" transform="translate(-25.501 -87.94)" />
    <path id="Path_59" data-name="Path 59" style={{ fill: 'none', stroke: setActive ? '#000000' : '#21416f', strokeLinecap: 'round', strokeLinejoin: 'round' }} d="M17.575,85.1l-2.543,2.543" transform="translate(-10.798 -73.237)" />
    <path id="Path_60" data-name="Path 60" style={{ fill: 'none', stroke: setActive ? '#000000' : '#21416f', strokeLinecap: 'round', strokeLinejoin: 'round' }} d="M87.679,104.112l-2.542-2.542" transform="translate(-73.279 -87.919)" />
    <path id="Path_61" data-name="Path 61" style={{ fill: 'none', stroke: setActive ? '#000000' : '#21416f', strokeLinecap: 'round', strokeLinejoin: 'round' }} d="M101.611,85.05l2.543,2.543" transform="translate(-87.961 -73.196)" />
    <path id="Path_62" data-name="Path 62" style={{ fill: 'none', stroke: setActive ? '#000000' : '#21416f', strokeLinecap: 'round', strokeLinejoin: 'round' }} d="M15.062,31.494,17.6,34.036" transform="translate(-10.824 -25.464)" />
    <path id="Path_63" data-name="Path 63" style={{ fill: 'none', stroke: setActive ? '#000000' : '#21416f', strokeLinecap: 'round', strokeLinejoin: 'round' }} d="M34.113,17.547,31.57,15" transform="translate(-25.537 -10.767)" />
  </g>
</svg>
