import { InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import "./NumberSegmentationFilter.scss";

function NumberSegmentationFilter(props) {
  const { filterChanged, filterState, item, t } = props;

  const [maxEnabled, setMaxEnabled] = useState(true);
  const [minEnabled, setMinEnabled] = useState(true);

  const onMaxChanged = (value) => {
    filterChanged({
      ...filterState,
      max: filterState.min === null ? value : value < filterState.min ? filterState.min : value === filterState.min ? null : value,
      min: filterState.min === null ? null : value < filterState.min ? value : value === filterState.min ? value : filterState.min
    });
  }

  const onMinChanged = (value) => {
    filterChanged({
      ...filterState,
      max: filterState.max === null ? null : value > filterState.max ? value : value === filterState.max ? null : filterState.max,
      min: filterState.max === null ? value : value > filterState.max ? filterState.max : value,
    });
  }

  const onSpecificChanged = (value) => {
    filterChanged({
      ...filterState,
      search: value
    });
  }

  if (item.max != null) {
    filterChanged({
      ...filterState,
      max: item.max
    });
    setMaxEnabled(false);
  }

  if (item.min != null) {
    filterChanged({
      ...filterState,
      min: item.min
    });
    setMinEnabled(false);
  }

  return (
    <>
      <InputNumber onChange={onSpecificChanged} placeholder={t('search')} value={filterState?.search}/>

      <div className={"segmentationfilter-number-range"}>
        <InputNumber disabled={!minEnabled} onChange={onMinChanged} placeholder={t('min')} value={filterState?.min}/>
        <InputNumber disabled={!maxEnabled} onChange={onMaxChanged} placeholder={t('max')} value={filterState?.max}/>
      </div>
    </>
  );
}

export default withTranslation("benchmark")(NumberSegmentationFilter);