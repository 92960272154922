import * as app from './app'
import * as benchmark from './benchmark'
import * as fileManager from './file_manager'
import * as organisations from './organisations'
import * as profile from './profile'
import * as questionnaires from './questionnaires'
import * as reportBlocks from './report_blocks'
import * as reports from './reports'
import * as staticPages from './static_pages'
import * as user from './user'

export default {
  app,
  benchmark,
  file_manager: fileManager,
  organisations,
  profile,
  questionnaires,
  report_blocks: reportBlocks,
  reports,
  static_pages: staticPages,
  user
}
