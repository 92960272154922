import { connect } from 'react-redux';
import CheckboxesSegmentationFilter from "./CheckboxesSegmentationFilter";

const mapDispatchToProps = (dispatch) => ({
  
})

const mapStateToProps = (state) => ({

});


export default connect(
  mapDispatchToProps,
  mapStateToProps
)(CheckboxesSegmentationFilter);