import {connect} from "react-redux";

import UserCreate from './UserCreate'
import actions from '../../../../store/actions';

const mapStateToProps = state => ({
  isFetching: state.profile.isFetching,
})

const mapDispatchToProps = (dispatch) => ({
  createUserProfile: (data) => dispatch(actions.profile.createUserProfile(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserCreate)


