import { AutoCalculated, Category, CategoryWithSubCategories, Checkbox, CheckboxGroup, Date, File, Markup, Number, RadioGroup, Select, Text, TextArea, Twig } from "./Types";
import { Collapse, Spin, Tooltip } from "antd";
import icon_conversation from '../../../../assets/images/svg/conversation-chat-text.svg';
import moment from "moment";
import React from "react";
import { CheckCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";

import "./WebformElement.scss";

const { Panel } = Collapse;

function WebformElement(props) {
    const { t } = props;   

    const render = (type) => {
        switch (type) {
            case 'webform_autocalculated':
                return (<AutoCalculated {...props}/>);
            case 'category_container':
                if ((props.element.elements || []).filter((e) => e.type === "category_container").length)
                    return (<CategoryWithSubCategories {...props}/>);
                else
                    return (<Category {...props}/>);
            case 'checkbox':
                return (<Checkbox {...props}/>);
            case 'checkboxes':
                return (<CheckboxGroup {...props}/>);
            case 'date':
                return (<Date {...props}/>);
            case 'managed_file':
            case 'webform_document_file':
                return (<File {...props}/>);
            case 'webform_markup':
                return (<Markup {...props}/>);
            case 'number':
                return (<Number {...props}/>);
            case 'radios':
                return (<RadioGroup {...props}/>);
            case 'select':
                return (<Select {...props}/>);
            case 'textfield':
                return (<Text {...props}/>);
            case 'textarea':
                return (<TextArea {...props}/>);
            case 'webform_computed_twig':
                return (<Twig {...props}/>);
            default:
                console.error(`Element ${type} not found`, props.element);
                return null;
        }
    };

    return (
        <>
            {!props.element.is_visible ? null : (props.element.type === 'category_container' || props.element.type === 'webform_markup' || !!props.isChild ? (render(props.element.type)) : (
                <div className='mainWrapper' onClick={() => props.onClick(props.element)}>
                    <div className='indicator'>
                        {props.element.webform_indicator}
                        <div style={{ display: "flex", gap: 30}}>
                            {(props.element.webform_tags.length > 0) && 
                                <div className='containerTags'>
                                    {props.element.webform_tags.map(e => props.app.tags.map(t => e === t.id && <div className='tagItem'>{t.name}</div>))}
                                </div>
                            }

                            {props.user.data.permissions.view_comments && props.options.show_description_popup && 
                                <div className='containerButton'>
                                    <div className='comments' onClick={() => props.showDetailsClick(props.element)}>
                                        <img alt='' src={icon_conversation} />
                                        <span style={{ marginLeft: 10 }} >{t('comments')}</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    {render(props.element.type)}

                    {!!!props.element.is_multilevel ? (null) : (props.loadingSubmission ? (<Spin/>) : (!!!props.submission?.child_organisations.length ? null : (
                        <Collapse>
                            <Panel
                                header={`${t('multi_organizations')} ${props.submission.child_organisations.filter(child => !!child.completed).length}/${props.submission.child_organisations.length}`}
                                key={'1'}>
                                <div className={'header'}>
                                    <p>{t('organization')}</p>
                                    <p>{t('worth')}</p>
                                    <p>{t('status')}</p>
                                </div>

                                {props.loadingChildSubmissions ? <Spin /> : props.childSubmissions?.map(childSubmission => (
                                    <div className={'multi__organizations'} key={childSubmission.organisation_id}>
                                        <Tooltip 
                                            placement={'left'}
                                            title={
                                                <ul className={'tooltip__list'}>
                                                    <li>Last update: {moment(childSubmission.updated_at).format('YYYY-MM-DD HH:mm:ss')}</li>
                                                    <li>User name: {childSubmission.user?.name?.map(name => name.value).join(', ')}</li>
                                                    <li>User roles: {childSubmission.user?.roles?.map(role => role.target_id).join(', ')}</li>
                                                </ul>
                                            }>

                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                <p className={'title'}>{childSubmission.organisation_title}</p>

                                                <div className={'content'}>
                                                    <WebformElement disabled={props.disabled} element={props.element} isChild={true} onChange={props.onChange} submission={childSubmission} />
                                                </div>
    
                                                <div className={'status extendedTags'}>
                                                    {!!childSubmission.completed && <CheckCircleOutlined title="Completed" />}
                                                    {!!childSubmission?.data?.find(d => d.key === props.element.webform_key)?.nvt && <div className='extendedTag' title="Niet van toepassing">N.V.T.</div>}
                                                    {!!childSubmission?.data?.find(d => d.key === props.element.webform_key)?.nvh && <div className='extendedTag' title="Niet voorhanden">N.V.H.</div>}
                                                </div>
                                            </div>

                                        </Tooltip>
                                    </div>
                                ))}
                            </Panel>
                        </Collapse>
                    )))}
                </div>
            ))}
        </>
    );
}

export default withTranslation('questionnaire')(WebformElement);