import React from "react";

import HelpCenterCircle from "../../assets/images/svg/helpCenterCircle";
import {withTranslation} from "react-i18next";

const HelpCenter = ({ t, ...props }) => {

  return (
    <div className={'help-center'} style={props.style}>
      <div className={'help-center__content'}>
        <p className={'help-center__title'}>{t('any_questions_block')}</p>
        <button className={'help-center__action'}>{t('go_to_help_center')}</button>
      </div>
      <div className={'help-center__img'}>
        <HelpCenterCircle />
      </div>
    </div>
  );
};

export default withTranslation('help_center')(HelpCenter);