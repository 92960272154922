import React, {Component, Fragment} from 'react'
import _ from 'lodash'
import moment from 'moment'
import {
    CaretRightOutlined,
    LeftOutlined,
    PlusSquareOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusCircleOutlined,
    SearchOutlined,
    LoadingOutlined,
    InfoCircleOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons';
import {
    Select,
    DatePicker,
    Input,
    Button,
    Table,
    Typography,
    Collapse,
    Spin,
    message,
    Result,
    Modal,
    Form,
    Divider,
    Tabs,
    Popover
} from 'antd';
import {api} from '../../services'

import './Benchmark.scss'
import {Charts, NumericPreview} from './parts'
import {CustomerTable, CustomInputMask} from '../../components'

const {ColumnGroup, Column} = Table
const {Option} = Select
const {Search} = Input
const {Text, Title} = Typography
const {RangePicker} = DatePicker
const statuses = ['open', 'in_progress', 'completed']
const {Panel} = Collapse

const dateFormat = 'DD/MM/YYYY'

const comprasisonGroupActionButtons = [
    {title: 'Create group', type: 'primary', icon: <PlusCircleOutlined/>, actionType: 'create'},
    {title: 'Edit group', type: 'primary', icon: <EditOutlined/>, actionType: 'update'},
    {title: 'Delete group', type: 'primary', icon: <DeleteOutlined/>, actionType: 'delete'}
]

const periodTypes = ['week', 'month', 'quarter', 'year']

class Benchmark extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeForm: 1,
            activeTab: 1,
            date: ['01.01.2020', '24.08.2020'],
            expression: '',
            groupsOrganisations: [], // this.props.user.data.organisations,
            orgIds: [],
            activeGroupId: undefined,
            isSaving: false,
            groupName: '',
            groupMode: null,
            settings: {},
            selectedColumn: '',
            selectedGroups: [],
            selectedDates: [],
            selectedPeriodType: '',
            benchmarkData: [],
            visibleCategory: true,
            forms: [],
            fetch: true,
            visibleTable: false,
            questions: false,
            treeForm: {
                category: false,
                custom_formula: false
            },
            Elements: {},
            dataElem: {},
            formId: null,
            tableItems: [],
            currentOrganizationData: null,
            viewForm: false,
            BenchmarkDataCategory: [],
            BenchmarkMainDataCategory: [],
            categoryId: null,
            isBenchmarkLoading: false,
            drillDown: {
                isModalVisible: false,
                isFetching: false,
                data: {},
                groupData: {}
            },
            customFormula: {
                isResultVisible: false,
                isFetchingResult: false
            },
            searchWebform: {
                searchText: '',
                type: '',
                isFetching: false,
                list: []
            },
            tableData: [],
            tableColumns: [],
            tableDates: [],
            parents: []
        }
    }

    fetchData() {
        this.setState({
            fetch: true
        })

        api.questionnaires.getForms()
            .then(({data}) => {
                if (data.forms) {
                    this.setState({
                        forms: data.forms,
                        fetch: false
                    })
                } else {
                    throw new Error(data.error)
                }
            })
            .catch(error => console.log(error))
    }

    componentDidMount() {
        this.fetchData()
        Promise
            .all([
                api.benchmark.getComparisonGroups(),
                api.benchmark.getBenchmarkSettings()
            ])
            .then(([{data}, {data: {settings}}]) => {
                this.setState({
                    groupsOrganisations: data.comparison_groups,
                    settings
                })
            })
    }

    handleSearchWebforms = _.debounce((value) => {
        const {searchWebform: {searchText, type}} = this.state

        this.setState({
            searchWebform: {
                ...this.state.searchWebform,
                isFetching: true,
                searchText: value
            }
        })

        api.benchmark.searchWebform({type, q: value})
            .then(response => {
                const {data: {status, elements}} = response
                this.setState({
                    searchWebform: {
                        ...this.state.searchWebform,
                        isFetching: false,
                        list: elements
                    }
                })
            })
    }, 700)

    setActiveForm = (value) => {
        this.setState({
            activeForm: value
        })
    }

    onChangeSelect = (id) => {
        this.setState({
            // groupMode: 'update',
            orgIds: []
        })
        api.benchmark.getComparisonGroupsItem(id)
            .then(({data: {data: {organisations}}}) => {
                const {user: {data: {organisations: userOrganisations}}} = this.props
                const userOrganisationIds = userOrganisations.map(i => i.id)
                const orgIds = organisations
                    .filter(i => userOrganisationIds.includes(i.id))
                    .map(i => i.id)

                this.setState({
                    orgIds,
                    activeGroupId: id,
                    groupName: this.state.groupsOrganisations.find(i => i.id === id).name
                })
            })
    }

    handleDeleteComparisonGroup = () => {
        const {activeGroupId} = this.state

        this.setState({
            isSaving: true
        })

        api.benchmark.deleteComparisonGroup(activeGroupId)
            .then(() => api.benchmark.getComparisonGroups())
            .then(({data}) => {
                this.setState({
                    groupsOrganisations: data.comparison_groups,
                    activeGroupId: undefined,
                    isSaving: false
                })
            })
    }

    onSaveOrganisations = () => {
        const {activeGroupId, orgIds, groupName} = this.state

        if (!orgIds.length) {
            message.error('Select at least one organization')
            return
        }

        if (!groupName.length) {
            message.error('Input a group name')
            return
        }


        this.setState({
            isSaving: true
        })

        api.benchmark.updateComparisonGroup(activeGroupId, {ids: orgIds, name: groupName})
            .then(({data}) => {
                this.setState({
                    isSaving: false,
                    groupsOrganisations: this.state.groupsOrganisations.map(i => {
                        if (i.id === activeGroupId) {
                            return {
                                ...i,
                                name: groupName
                            }
                        } else {
                            return i
                        }
                    })
                })
            })
    }

    onCreateOrganisations = () => {
        const {orgIds: organisations, groupName: name} = this.state

        if (!name || !organisations.length) {
            if (!name) {
                message.error('Enter group name')
            }
            if (!organisations.length) {
                message.error('Select organization')
            }
            return
        }

        this.setState({
            isSaving: true
        })

        api.benchmark.createComparisonGroup({organisations, name})
            .then(() => api.benchmark.getComparisonGroups())
            .then(({data}) => {
                this.setState({
                    groupsOrganisations: data.comparison_groups,
                    orgIds: [],
                    groupName: '',
                    isSaving: false
                })
            })
    }

    onCancel = () => {
        this.setState({
            groupMode: null,
            activeGroupId: undefined,
            orgIds: [],
            groupName: ''
        })
    }

    handleOrgSelect = ids => {
        this.setState({
            orgIds: ids
        })
    }

    handleInputGroupName = e => {
        this.setState({
            groupName: e.target.value
        })
    }

    handleChangeGroupMode = mode => {
        this.setState({
            groupMode: mode,
            activeGroupId: undefined,
            orgIds: []
        })
    }

    handleChangeColumn = v => {
        this.setState({selectedColumn: v}, () => this.loadBenchmarkData())
    }

    handleChangePeriodType = v => {
        this.setState({selectedPeriodType: v, selectedDates: []}, () => this.loadBenchmarkData())
    }

    handleChangeDates = v => {
        const {selectedPeriodType} = this.state
        const dates = v ? v : []

        if (dates.length) {
            this.setState({selectedDates: [moment(dates[0]).startOf(selectedPeriodType), moment(dates[1]).endOf(selectedPeriodType)]}, () => this.loadBenchmarkData())
        } else {
            this.setState({selectedDates: dates}, () => this.loadBenchmarkData())
        }
    }

    handleChangeSelectGroup = ids => {
        this.setState({selectedGroups: ids}, () => this.loadBenchmarkData())
    }

    handleBenchmarkDataSelect = (item, selectedFormId, parents) => {
        const {viewForm} = this.state

        const state = {
            activeForm: viewForm ? 4 : 3,
            formId: selectedFormId,
            parents
        }

        if (viewForm) {
            state.activeCategory = item
        } else {
            state.tableItems = [item]
        }

        this.setState(state)
    }

    loadBenchmarkData = () => {
        const {
            activeForm,
            selectedColumn,
            selectedGroups,
            selectedPeriodType,
            tableItems,
            viewForm,
            selectedDates,
            formId,
            activeCategory
        } = this.state

        if (!selectedDates.length || !selectedGroups.length || !selectedColumn.length || !selectedPeriodType.length || activeForm === 5) {
            return
        }

        this.setState({
            isBenchmarkLoading: true,
            tableData: [],
            tableColumns: [],
            tableDates: []
        })

        if (!viewForm) {
            const [item] = tableItems

            const indicator = item.webform_indicator
            const period_start = selectedDates[0].format('YYYY-MM-DD HH:mm:ss')
            const period_end = selectedDates[1].format('YYYY-MM-DD HH:mm:ss')

            return Promise.all([
                this.props.getBenchmarkDataForIndicator({
                    calculation_type: selectedColumn,
                    groups: selectedGroups,
                    period_type: selectedPeriodType,
                    indicator,
                    period_start,
                    period_end
                }),
                this.props.getBenchmarkOrgDataForIndicator({
                    calculation_type: selectedColumn,
                    organization: this.props.user.data.organisation,
                    period_type: selectedPeriodType,
                    indicator,
                    period_start,
                    period_end
                })
            ])
                .then(([{data: {values}}, {data: {values: orgValues}}]) => {
                    this.setState({
                        benchmarkData: values,
                        currentOrganizationData: orgValues[0],
                        isBenchmarkLoading: false
                    }, () => {
                        this.generateDataTableSource()
                    })
                })
        } else {
            return api.questionnaires.getElements(formId, activeCategory.webform_key)
                .then(({data: {webform: {elements}}}) => {
                    this.setState({
                        tableItems: elements
                    })
                })
                .then(() => {
                    const indicator = activeCategory.webform_indicator
                    const period_start = selectedDates[0].format('YYYY-MM-DD HH:mm:ss')
                    const period_end = selectedDates[1].format('YYYY-MM-DD HH:mm:ss')

                    return Promise.all([
                        this.props.getBenchmarkDataForCategory({
                            calculation_type: selectedColumn,
                            groups: selectedGroups.map(Number),
                            indicator,
                            period_start,
                            period_end,
                            period_type: selectedPeriodType
                        }),
                        this.props.getBenchmarkOrgDataForCategory({
                            calculation_type: selectedColumn,
                            organization: this.props.user.data.organisation,
                            indicator,
                            period_start,
                            period_end,
                            period_type: selectedPeriodType
                        })
                    ])
                        .then(([{data: {values}}, {data: {values: orgValues}}]) => {
                            this.setState({
                                currentOrganizationData: orgValues[0],
                                benchmarkData: values,
                                isBenchmarkLoading: false
                            }, () => {
                                this.generateDataTableSource()
                            })
                        })
                })
                .catch(err => {
                    message.error(err.message)
                })

        }
    }

    onSetVisibleForms = (e, view, isCustomFormula = false, currentForm = '') => {
        if (isCustomFormula) {
            this.setState({
                activeForm: 5
            })
        } else {
            if (view) {
                this.setState({
                    activeForm: 2,
                    viewForm: true,
                    searchWebform: {
                        ...this.state.searchWebform,
                        type: currentForm,
                    }
                })
            } else {
                this.setState({
                    activeForm: 2,
                    viewForm: false,
                    searchWebform: {
                        ...this.state.searchWebform,
                        type: currentForm,
                    }
                })
            }
        }
    }

    ActiveCollapse = (v, item) => {
        if (v.length) {
            api.questionnaires.getAllCollapseElements(item.id)
                .then(({data}) => {
                    this.setState({
                        Elements: {
                            ...this.state.Elements,
                            [item.id]: data.webform.elements
                        }
                    })
                })
        }
    }

    paintElements = (item, id, parents = []) => {
        const {
            type,
            elements,
            basic_category: basicCategory,
            webform_children,
            webform_key,
            webform_indicator
        } = item

        const {
            selectedGroups,
            selectedColumn,
            selectedDates,
            viewForm
        } = this.state

        if (viewForm) {
            if (type === 'category_container' && elements.length) {
                return (
                    <Collapse expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
                              style={{marginTop: 10}}>
                        <Panel header={item.title}>
                            {elements.map(el => {
                                const element = this.paintElements(el, id, [...parents, item])
                                return element &&
                                    <div className='qs-item' key={el.webform_key}>
                                        {element}
                                    </div>
                            })}
                        </Panel>
                    </Collapse>
                );
            } else {
                return <div style={{marginBottom: 10}}>
                    <Button
                        // disabled={!selectedDates.length || !selectedGroups.length || !selectedColumn.length}
                        onClick={() => this.handleBenchmarkDataSelect(item, id, [...parents, item])}
                    >
                        {item.title}
                    </Button>
                </div>
            }
        } else if (type === 'category_container' && elements.length) {
            return (
                <Collapse expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
                          style={{marginTop: 10}}>
                    <Panel header={item.title}>
                        {elements.map(el => {
                            const element = this.paintElements(el, id, [...parents, item])
                            return element &&
                                <div className='qs-item' key={el.webform_key}>
                                    {element}
                                </div>
                        })}
                    </Panel>
                </Collapse>
            );
        } else {
            return (
                <Collapse expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
                          style={{marginTop: 10}} onChange={(v) => this.openQuestions(v, item, id)}>
                    <Panel header={item.title}>
                        {this.state.dataElem[item.webform_key]
                            ? this.state.dataElem[item.webform_key].map((elem, key) => {
                                return <div key={key} style={{marginBottom: 10}}>
                                    <Button
                                        // disabled={!selectedDates.length || !selectedGroups.length || !selectedColumn.length}
                                        onClick={() => this.handleBenchmarkDataSelect(elem, id, [...parents, item, elem])}>
                                        {elem.title}
                                    </Button>
                                </div>
                            })
                            : <Spin/>
                        }
                    </Panel>
                </Collapse>
            );
        }
    }

    openQuestions = (v, item, id) => {
        if (v.length) {
            api.questionnaires.getElements(id, item.webform_key)
                .then(({data: {webform: {elements}}}) => {
                    this.setState({
                        ...this.state,
                        dataElem: {
                            ...this.state.dataElem,
                            [item.webform_key]: elements
                        }
                    })
                })
        }
    }

    onBack = () => {
        this.setState({
            activeForm: 1,
            benchmarkData: [],
            selectedColumn: undefined,
            selectedGroups: [],
            selectedDates: [],
            tableItems: [],
            selectedPeriodType: '',
            formId: null,
            activeCategory: null,
            currentOrganizationData: null,
            tableData: [],
            tableColumns: [],
            tableDates: [],
            parents: [],
            searchWebform: {
                searchText: '',
                type: '',
                isFetching: false,
                list: []
            }
        })
    }

    downloadDrillDownExcel = () => {
        const {
            selectedDates,
            selectedColumn,
            currentOrganizationData: {organization},
            drillDown: {groupData: {indicator, group}}
        } = this.state
        const params = {
            indicator,
            group,
            organization,
            period_start: selectedDates[0].format('YYYY-MM-DD HH:mm:ss'),
            period_end: selectedDates[1].format('YYYY-MM-DD HH:mm:ss'),
            calculation_type: selectedColumn,
            format: 'xlsx'
        }

        this.props.getBenchmarkDrillDown(params)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'benchmark.xlsx')
                document.body.appendChild(link)
                link.click();
                document.body.removeChild(link)
            })
            .catch(err => {
                message.error(err.message)
            })
    }

    downloadExcel = () => {
        const {tableItems, selectedDates, selectedColumn, selectedGroups, viewForm, activeCategory} = this.state

        return Promise.resolve()
            .then(() => {
                if (!viewForm) {
                    const [item] = tableItems

                    const indicator = item.webform_indicator
                    const period_start = selectedDates[0].format('YYYY-MM-DD HH:mm:ss')
                    const period_end = selectedDates[1].format('YYYY-MM-DD HH:mm:ss')

                    return this.props.getBenchmarkDataForIndicator({
                        calculation_type: selectedColumn,
                        groups: selectedGroups,
                        indicator,
                        period_start,
                        period_end,
                        format: 'xlsx'
                    })
                } else {
                    const indicator = activeCategory.webform_indicator
                    const period_start = selectedDates[0].format('YYYY-MM-DD HH:mm:ss')
                    const period_end = selectedDates[1].format('YYYY-MM-DD HH:mm:ss')

                    return this.props.getBenchmarkDataForCategory({
                        calculation_type: selectedColumn,
                        groups: selectedGroups.map(Number),
                        indicator,
                        period_start,
                        period_end,
                        format: 'xlsx'
                    })
                }
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'benchmark.xlsx')
                document.body.appendChild(link)
                link.click();
                document.body.removeChild(link)
            })
            .catch(err => {
                message.error(err.message)
            })
    }

    formatIndicatorValue = (value, tableItem) => {
        let number = value
        let decimals = tableItem.decimal_places ?? 2
        let decpoint = tableItem.numeric_decimal ?? ','
        let thousand = tableItem.numeric_thousand ?? '.'

        let n = Math.abs(number).toFixed(decimals).split('.')
        n[0] = n[0].split('').reverse().map((c, i, a) =>
            i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c
        ).reverse().join('')
        let final = (Math.sign(number) < 0 ? '-' : '') + n.join(decpoint)

        /*
        isNaN(Number(value))
          ? value
          : String(value).replace('.', ',')
         */

        return final
    }

    handleGetBenchmarkDrillDown = data => {
        const {selectedDates, selectedColumn, selectedPeriodType, currentOrganizationData: {organization}} = this.state
        const {indicator, group, tableItem, date} = data
        const formattedDates = [moment(date).startOf(selectedPeriodType), moment(date).endOf(selectedPeriodType)]

        const params = {
            indicator,
            group,
            organization,
            period_start: moment(formattedDates[0]).format('YYYY-MM-DD HH:mm:ss'),
            period_end: moment(formattedDates[1]).format('YYYY-MM-DD HH:mm:ss'),
            calculation_type: selectedColumn,
        }

        this.setState({
            ...this.state,
            drillDown: {
                ...this.state.drillDown,
                isModalVisible: true,
                isFetching: true,
                groupData: data
            }
        })

        this.props.getBenchmarkDrillDown(params)
            .then(response => {
                const {data: {success, values}} = response
                if (success) {
                    this.setState({
                        ...this.state,
                        drillDown: {
                            ...this.state.drillDown,
                            isFetching: false,
                            data: values,
                            tableItem
                        }
                    })
                }
            })
    }

    handleCloseDrillDownModal = () => {
        this.setState({
            ...this.state,
            drillDown: {
                isModalVisible: false,
                isFetching: false,
                data: {}
            }
        })
    }

    findOrganizations = v => {
        this.setState({
            groupsOrganisations: this.state.groupsOrganisations.filter(i => i.name.toLowerCase().includes(v.toLowerCase()))
        })
    }

    handleGetBenchmarkDataCategory = values => {
        const {expression} = values
        const {selectedDates, selectedColumn, selectedGroups} = this.state

        this.setState({
            ...this.state,
            expression,
            customFormula: {
                ...this.state.customFormula,
                isFetchingResult: true
            }
        })

        const params = {
            expression,
            groups: selectedGroups,
            period_start: selectedDates[0].format('YYYY-MM-DD HH:mm:ss'),
            period_end: selectedDates[1].format('YYYY-MM-DD HH:mm:ss'),
            calculation_type: selectedColumn,
        }

        this.props.getBenchmarkDataFromExpression(params)
            .then(response => {
                const {data: {success, values}} = response
                if (success) {

                    this.setState({
                        ...this.state,
                        benchmarkData: values,
                        customFormula: {
                            ...this.state.customFormula,
                            isFetchingResult: false,
                            isResultVisible: true
                        }
                    })
                }
            })
    }

    generateDataTableSource = () => {
        const {
            activeForm,
            groupsOrganisations,
            selectedGroups,
            benchmarkData,
            currentOrganizationData,
            tableItems,
            expression
        } = this.state

        const {user: {data: {organisations: userOrganisations, organisation}}} = this.props
        const userOrganisation = userOrganisations.find(i => +i.id === +organisation)

        let dates = []
        let columns = [
            activeForm === 5
                ? {
                    align: 'left',
                    title: 'Formula',
                    dataIndex: 'formula',
                    key: 'formula'
                }
                : {
                    align: 'left',
                    title: 'Indicator',
                    dataIndex: 'indicator',
                    key: 'indicator',
                    render: item => <div
                        style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap'}}>
                        <Text>{item?.title}</Text>
                        {item?.description &&
                            <Popover content={() => (
                                <div dangerouslySetInnerHTML={{__html: `${item?.description}`}}/>
                            )}>
                                <InfoCircleOutlined style={{marginLeft: '1rem'}}/>
                            </Popover>
                        } <br/>
                        <Text style={{width: '100%', textAlign: 'left'}} strong>({item.webform_indicator})</Text>
                    </div>
                }
        ]
        let tableData = []

        if (activeForm === 3) {
            const tableItem = tableItems[0]

            dates = [...new Set(
                [...benchmarkData, currentOrganizationData]
                    .filter(i => !!i)
                    .reduce((acc, i) => {
                        const result = i.result.map(i => i.date)
                        return [...acc, ...result]
                    }, [])
            )]
            const data = benchmarkData
                .map(i => {
                    const group = groupsOrganisations.find(item => +item.id === +i.group)
                    return {
                        align: 'center',
                        title: group ? group.name : i.group,
                        children: dates.map(date => {
                            return {
                                align: 'center',
                                title: this.manageSelectedTimeInTable(date),
                                dataIndex: `${i.group}_${date}`,
                                key: `${i.group}_${date}`,
                                render: item => {
                                    return (
                                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            {item && <>
                                                {tableItem.is_numeric ?
                                                    <NumericPreview value={item.value} settings={tableItem}/> : item}
                                                <PlusSquareOutlined style={{marginLeft: '10px'}}
                                                                    onClick={() => this.handleGetBenchmarkDrillDown({
                                                                        ...item,
                                                                        group: i.group,
                                                                        tableItem,
                                                                        date
                                                                    })}/>
                                            </>}
                                        </div>
                                    )
                                }
                            }
                        })
                    }
                })

            const orgData = {
                align: 'center',
                title: userOrganisation.title,
                children: dates.map(date => {
                    return {
                        align: 'center',
                        title: this.manageSelectedTimeInTable(date),
                        dataIndex: `org_${organisation}_${date}`,
                        key: `org_${organisation}_${date}`,
                        render: item => {
                            return (
                                <>
                                    {item &&
                                        <>{tableItem.is_numeric ?
                                            <NumericPreview value={item} settings={tableItem}/> : item}</>}
                                </>
                            )
                        }
                    }
                })
            }
            columns = [...columns, orgData, ...data]

            tableData = [
                {indicator: tableItem},
            ]

            benchmarkData
                .forEach(i => {
                    i.result.forEach(el => {
                        tableData[0][`${i.group}_${el.date}`] = {
                            value: this.formatIndicatorValue(el.indicator_value, tableItem),
                            indicator: i.indicator
                        }
                    })
                })
            if (currentOrganizationData) {
                currentOrganizationData.result.forEach(i => {
                    tableData[0][`org_${organisation}_${i.date}`] = this.formatIndicatorValue(i.indicator_value, tableItem)
                })
            }
            selectedGroups.forEach(i => {
                dates.forEach(el => {
                    const key = `${i}_${el}`
                    if (tableData[0][key] === undefined) {
                        tableData[0][key] = null
                    }
                })
            })

            dates.forEach(el => {
                const key = `org_${organisation}_${el}`
                if (tableData[0][key] === undefined) {
                    tableData[0][key] = null
                }
            })

        } else if (activeForm === 4) {
            dates = [...new Set(
                [...benchmarkData, currentOrganizationData]
                    .filter(i => !!i)
                    .reduce((acc, i) => {
                        const result = i.items.reduce((accum, el) => {
                            const accResult = el.result.map(i => i.date)
                            return [...accum, ...accResult]
                        }, [])
                        return [...acc, ...result]
                    }, [])
            )]

            const indicators = [...new Set(
                [...benchmarkData, currentOrganizationData]
                    .filter(i => !!i)
                    .reduce((acc, i) => {
                        const result = i.items.map(i => i.indicator)
                        return [...acc, ...result]
                    }, [])
            )]

            const data = benchmarkData
                .map(i => {
                    const group = groupsOrganisations.find(item => +item.id === +i.group)
                    return {
                        align: 'center',
                        title: group ? group.name : i.group,
                        children: dates.map(date => {
                            return {
                                align: 'center',
                                title: this.manageSelectedTimeInTable(date),
                                dataIndex: `${i.group}_${date}`,
                                key: `${i.group}_${date}`,
                                render: item => {
                                    return (
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center'
                                        }}>
                                            {item && <>
                                                {item.indicatorInfo.is_numeric ? <NumericPreview value={item.value}
                                                                                                 settings={item.indicatorInfo}/> : item.value}
                                                <PlusSquareOutlined style={{marginLeft: '10px'}}
                                                                    onClick={() => this.handleGetBenchmarkDrillDown({
                                                                        ...item,
                                                                        group: i.group,
                                                                        tableItem: item.indicatorInfo,
                                                                        date
                                                                    })}/>
                                            </>}
                                        </div>
                                    )
                                }
                            }
                        })
                    }
                })

            const orgData = {
                align: 'center',
                title: userOrganisation.title,
                children: dates.map(date => {
                    return {
                        align: 'center',
                        title: this.manageSelectedTimeInTable(date),
                        dataIndex: `org_${organisation}_${date}`,
                        key: `org_${organisation}_${date}`,
                        render: item => {
                            return item &&
                                <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                    {item.indicatorInfo.is_numeric ?
                                        <NumericPreview value={item.value} settings={item.indicatorInfo}/> : item.value}
                                </div>
                        }
                    }
                })
            }

            columns = [...columns, orgData, ...data]

            tableData = indicators
                .map(indicator => {
                    const tableItem = tableItems.find(i => i.webform_indicator === indicator)
                    const res = benchmarkData
                        .reduce((accum, i) => {
                            const item = i.items.find(el => el.indicator === indicator)
                            if (!item) {
                                return accum
                            }
                            const acc = item.result
                                .reduce((acc1, el1) => {
                                    acc1[`${item.group}_${el1.date}`] = {
                                        value: this.formatIndicatorValue(el1.indicator_value, tableItem),
                                        indicator: item.indicator,
                                        indicatorInfo: tableItem
                                    }
                                    return acc1
                                }, {})
                            return {...accum, ...acc}
                        }, {})

                    const orgItem = currentOrganizationData.items.find(el => el.indicator === indicator)
                    const orgRes = orgItem
                        ? orgItem.result
                            .reduce((acc1, el1) => {
                                acc1[`org_${organisation}_${el1.date}`] = {
                                    value: this.formatIndicatorValue(el1.indicator_value, tableItem),
                                    indicatorInfo: tableItem
                                }
                                return acc1
                            }, {})
                        : {}

                    return {...res, ...orgRes, indicator: tableItem ? tableItem : indicator}
                })

            indicators.forEach((el, ind) => {
                selectedGroups.forEach(i => {
                    dates.forEach(el1 => {
                        const key = `${i}_${el1}`
                        if (tableData[ind][key] === undefined) {
                            tableData[ind][key] = null
                        }
                    })
                })
            })

            indicators.forEach((el, ind) => {
                dates.forEach(el1 => {
                    const key = `org_${organisation}_${el1}`
                    if (tableData[ind][key] === undefined) {
                        tableData[ind][key] = null
                    }
                })
            })
        } else if (activeForm === 5) {
            dates = [...new Set(benchmarkData
                .filter(i => !!i)
                .reduce((acc, i) => {
                    const result = i.result.map(i => i.date)
                    return [...acc, ...result]
                }, [])
            )]

            const data = benchmarkData
                .map(i => {
                    const group = groupsOrganisations.find(item => +item.id === +i.group)
                    return {
                        align: 'center',
                        title: group ? group.name : i.group,
                        children: dates.map(date => {
                            return {
                                align: 'center',
                                title: this.manageSelectedTimeInTable(date),
                                dataIndex: `${i.group}_${date}`,
                                key: `${i.group}_${date}`,
                                render: item => <div
                                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    {item && <>
                                        {item.value}
                                        {/*<PlusSquareOutlined style={{ marginLeft: '10px' }} onClick={() => this.handleGetBenchmarkDrillDown({ ...item, group: i.group }) } />*/}
                                    </>}
                                </div>
                            }
                        })
                    }
                })

            columns = [...columns, ...data]

            tableData = [
                {formula: `[%[${expression}]%]`},
            ]

            benchmarkData
                .forEach(i => {
                    i.result.forEach(el => {
                        const tableItem = tableItems.find(i => i.webform_indicator === i.indicator)
                        tableData[0][`${i.group}_${el.date}`] = {
                            value: this.formatIndicatorValue(el.indicator_value, tableItem),
                            indicator: i.indicator
                        }
                    })
                })

            selectedGroups.forEach(i => {
                dates.forEach(el => {
                    const key = `${i}_${el}`
                    if (tableData[0][key] === undefined) {
                        tableData[0][key] = null
                    }
                })
            })
        }

        this.setState({
            tableData: tableData,
            tableColumns: columns,
            tableDates: dates
        })
    }

    manageSelectedTimeInTable = date => {
        const {selectedPeriodType} = this.state

        switch (selectedPeriodType) {
            case 'year':
                return moment(date).year()
            case 'month':
                return <>
                    {moment(date).year()} <br/>
                    <span style={{textTransform: 'capitalize'}}>{moment(date).format('MMMM')}</span>
                </>
            case 'quarter':
                return `Quarter ${moment(date).quarter()}`
            case 'week':
                return <>
                    Number of week: {moment(date).weeks()} in {moment(date).year()}
                    <br/>
                    Start date: {moment(moment(date).startOf('week')).format('DD.MM')} <br/>
                    End date: {moment(moment(date).endOf('week')).format('DD.MM')}
                </>
            default:
                return date
        }
    }

    render() {
        const {
            activeForm,
            groupsOrganisations,
            orgIds,
            activeGroupId,
            isSaving,
            groupName,
            groupMode,
            settings,
            selectedColumn,
            selectedGroups,
            selectedDates,
            benchmarkData,
            currentOrganizationData,
            forms,
            Elements,
            tableItems,
            activeCategory,
            isBenchmarkLoading,
            drillDown,
            expression,
            customFormula,
            searchWebform,
            tableData,
            tableColumns,
            tableDates,
            sear,
            parents
        } = this.state

        const {user: {data: {organisations: userOrganisations, organisation}}} = this.props

        const extraColumns = settings.extra_columns
            ? Object.keys(settings.extra_columns).filter(column => settings.extra_columns[column])
            : []
        return (
            <>
                {drillDown.isModalVisible &&
                    <Modal
                        visible
                        footer={false}
                        closable={false}
                    >
                        <>
                            <Text>Question: {drillDown.data.question}</Text><br/><br/>
                            <Text>Full result:</Text>
                            <Table
                                loading={drillDown.isFetching}
                                columns={[
                                    {
                                        title: 'Organization name',
                                        dataIndex: 'orgName'
                                    },
                                    {
                                        title: 'Result',
                                        render: item =>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center'
                                            }}>
                                                {Object.keys(drillDown.tableItem).length && drillDown.tableItem.is_numeric
                                                    ?
                                                    <NumericPreview value={item.result} settings={drillDown.tableItem}/>
                                                    : item.result}
                                                {Number(organisation) === Number(item.orgId) &&
                                                    <div style={{
                                                        marginLeft: '1rem',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}><Popover title="Current organization"
                                                                trigger="hover"><QuestionCircleOutlined/></Popover>
                                                    </div>
                                                }
                                            </div>
                                    }
                                ]}
                                dataSource={drillDown.data.full}
                            />
                            <br/><br/>
                            <Button onClick={() => this.handleCloseDrillDownModal()} type='primary'>Close</Button>
                            <Button onClick={() => this.downloadDrillDownExcel()} type='primary'
                                    style={{marginLeft: 20}}>Download excel</Button>
                        </>
                    </Modal>}
                <div className='pageBenchmark'>
                    <div className='containerLeft'>
                        {activeForm !== 1 && <div className='ItemLeft' style={{width: '100%'}}>
                            <Button type='ghost' style={{
                                width: 50,
                                height: 50,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }} onClick={this.onBack}>
                                <LeftOutlined style={{fontSize: 30}}/>
                            </Button>
                        </div>}
                        <div className='ItemLeft'>
                            {!groupMode && <div style={{marginTop: 10}}>
                                {comprasisonGroupActionButtons.map(button => (
                                    <Button
                                        type={button.type}
                                        icon={button.icon}
                                        style={{marginBottom: '10px'}}
                                        onClick={() => this.handleChangeGroupMode(`${button.actionType}`)}
                                        block
                                    >
                                        {button.title}
                                    </Button>
                                ))}
                            </div>
                            }
                            {groupMode === 'update' && <>
                                <label style={{margin: '10px 0 0'}}>Select group</label>
                                <Select
                                    style={{width: 220}}
                                    onChange={this.onChangeSelect}
                                    value={activeGroupId}
                                >
                                    {groupsOrganisations.map((item, key) => <Option key={key}
                                                                                    value={item.id}>{item.name}</Option>)}
                                </Select>

                                {activeGroupId &&
                                    <>
                                        <label style={{margin: '10px 0 0'}}>Change name</label>
                                        <Input
                                            value={this.state.groupName}
                                            onChange={this.handleInputGroupName}
                                        />
                                    </>}

                                <label style={{margin: '10px 0 0'}}>Select organisations</label>
                                <Select
                                    mode='multiple'
                                    value={orgIds}
                                    disabled={!activeGroupId}
                                    style={{width: 220}}
                                    onChange={this.handleOrgSelect}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {userOrganisations.map(item =>
                                        <Option key={item.id} value={item.id}>{item.title}</Option>
                                    )}
                                </Select>
                                <div className='containerButton'>
                                    <Button loading={isSaving} onClick={this.onSaveOrganisations}>Save</Button>
                                    <Button onClick={this.onCancel}>Cancel</Button>
                                </div>
                            </>}

                            {groupMode === 'create' && <>
                                <label style={{margin: '10px 0 0'}}>Enter group name</label>
                                <Input
                                    style={{width: 220}}
                                    onChange={this.handleInputGroupName}
                                    value={groupName}
                                />
                                <label style={{margin: '10px 0 0'}}>Select organisations</label>
                                <Select
                                    mode='multiple'
                                    value={orgIds}
                                    style={{width: 220}}
                                    onChange={this.handleOrgSelect}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {userOrganisations.map(item =>
                                        <Option key={item.id} value={item.id}>{item.title}</Option>
                                    )}
                                </Select>
                                <div className='containerButton'>
                                    <Button loading={isSaving} onClick={this.onCreateOrganisations}>Save</Button>
                                    <Button onClick={this.onCancel}>Cancel</Button>
                                </div>
                            </>}

                            {groupMode === 'delete' && <>
                                <label style={{margin: '10px 0 0'}}>Select group</label>
                                <Select
                                    style={{width: 220}}
                                    onChange={this.onChangeSelect}
                                    value={activeGroupId}
                                >
                                    {groupsOrganisations.map((item, key) => <Option key={key}
                                                                                    value={item.id}>{item.name}</Option>)}
                                </Select>

                                <div className='containerButton'>
                                    <Button
                                        disabled={!activeGroupId}
                                        loading={isSaving}
                                        onClick={this.handleDeleteComparisonGroup}
                                        type='danger'
                                    >
                                        Delete
                                    </Button>
                                    <Button disabled={isSaving} onClick={this.onCancel}>Cancel</Button>
                                </div>
                            </>}
                        </div>
                        {(!!tableItems.length || !!activeCategory || activeForm === 5) && <div className='ItemLeft'>
                            <label style={{margin: '10px 0 0'}}>Select group</label>
                            <Select
                                mode='multiple'
                                style={{width: 220}}
                                onChange={this.handleChangeSelectGroup}
                                value={selectedGroups}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {groupsOrganisations.map((item, key) => <Option key={key}
                                                                                value={item.id}>{item.name}</Option>)}
                            </Select>

                            <label style={{margin: '10px 0 0'}}>Select calculation type</label>
                            <Select style={{width: 220}} onChange={this.handleChangeColumn} value={selectedColumn}>
                                {extraColumns.map(i => <Option key={i} value={i}>{i}</Option>)}
                            </Select>

                            <label style={{margin: '10px 0 0'}}>Select period type</label>
                            <Select style={{width: 220}} onChange={this.handleChangePeriodType}
                                    value={this.state.selectedPeriodType}>
                                {periodTypes.map(i => <Option key={i} value={i}>{i}</Option>)}
                            </Select>

                            {!!this.state.selectedPeriodType.length &&
                                <>
                                    <label style={{margin: '10px 0 0'}}>Select dates</label>
                                    <RangePicker picker={this.state.selectedPeriodType} format={dateFormat}
                                                 style={{width: 220}} placeholder='' value={selectedDates}
                                                 onChange={this.handleChangeDates}/>
                                </>}
                        </div>}
                    </div>
                    <div className='containerRight'>
                        <div>{parents.map(i => i.title).join(' > ')}</div>

                        {activeForm === 1 && <div className='mainForm'>
                            <Button type='ghost'
                                    onClick={(e) => this.onSetVisibleForms(e, true, false, 'categories')}>Category</Button>
                            <Button type='ghost'
                                    onClick={(e) => this.onSetVisibleForms(e, false, false, 'elements')}>Question</Button>
                            <Button type='ghost' onClick={(e) => this.onSetVisibleForms(e, false, true)}>Custom
                                Formula</Button>
                        </div>}

                        {activeForm === 2 && <>
                            <Input
                                size='large'
                                placeholder='Search...'
                                onChange={({target: {value}}) => this.handleSearchWebforms(value)}
                                prefix={<SearchOutlined/>}
                            />
                            <Divider/>
                            {searchWebform.searchText
                                ? <>
                                    {searchWebform.isFetching
                                        ? <LoadingOutlined style={{
                                            color: '#40a9ff',
                                            fontSize: 24,
                                            display: 'block',
                                            margin: '0 auto'
                                        }}/>
                                        : <>
                                            {searchWebform.list && searchWebform.list.length
                                                ? <>{searchWebform.list.map((item, idx) => (
                                                    <Button
                                                        onClick={() => this.handleBenchmarkDataSelect(item, item.webform_id)}
                                                        style={{margin: '0 20px 20px 0'}}
                                                        key={idx}>
                                                        {item.title} (Webform: {item.webform_title})
                                                    </Button>
                                                ))}
                                                </>
                                                : <Text style={{display: 'block', textAlign: 'center'}}>Nothing
                                                    found</Text>
                                            }
                                        </>
                                    }
                                </>
                                : <>
                                    {forms.length ? forms.map((item, key) =>
                                            <Collapse
                                                onChange={v => this.ActiveCollapse(v, item)}
                                                expandIcon={({isActive}) => <CaretRightOutlined
                                                    rotate={isActive ? 90 : 0}/>}
                                                style={{marginBottom: 10}}
                                                key={key}
                                            >
                                                <Panel header={item.title}>
                                                    {Elements[item.id] ? Elements[item.id].map((i, k) => {
                                                            const element = this.paintElements(i, item.id, [item])
                                                            return <div className='qs-item' key={k}>
                                                                {element}
                                                            </div>
                                                        })
                                                        : <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}><Spin/></div>}
                                                </Panel>
                                            </Collapse>
                                        )
                                        :
                                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <Spin/></div>}
                                </>}
                        </>
                        }

                        {activeForm === 5 &&
                            <Form
                                layout='vertical'
                                style={{marginBottom: '20px'}}
                                onFinish={this.handleGetBenchmarkDataCategory}
                            >
                                <Form.Item
                                    label='Custom formula'
                                    name='expression'
                                    rules={[
                                        {required: true, message: 'Please input your custom formula!'},
                                        {
                                            type: 'pattern',
                                            pattern: /^([\+\-\*\/\.\si0-9]*)$/,
                                            message: 'Gebruik alleen wiskundige operators en indicatoren'
                                        }
                                    ]}
                                >
                                    <CustomInputMask prefix={'[%['} suffix={']%]'}/>
                                </Form.Item>

                                <Form.Item style={{marginTop: '20px'}}>
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled={!selectedColumn || !selectedDates.length || !selectedGroups.length}
                                        loading={customFormula.isFetchingResult}
                                    >
                                        Calculate
                                    </Button>
                                </Form.Item>
                            </Form>}

                        {[3, 4].includes(activeForm) && <div>
                            <Spin spinning={isBenchmarkLoading || customFormula.isFetchingResult}>
                                <Tabs defaultActiveKey={this.state.activeTab}>
                                    <Tabs.TabPane onChange={key => this.setState({activeTab: key})} tab='Card' key={1}>
                                        <Table bordered dataSource={tableData} columns={tableColumns}/>
                                    </Tabs.TabPane>

                                    <Tabs.TabPane tab='Graph' key={2}>
                                        {!selectedDates.length || !selectedGroups.length || !selectedColumn.length
                                            ? 'Empty data'
                                            : <Charts
                                                periodType={this.state.selectedPeriodType}
                                                benchmarkData={benchmarkData}
                                                selectedGroups={groupsOrganisations.filter(i => selectedGroups.includes(i.id))}
                                                indicators={tableItems}
                                                dates={tableDates}
                                            />
                                        }
                                    </Tabs.TabPane>
                                </Tabs>
                                <div style={{marginTop: 10}}>
                                    <Button
                                        disabled={!selectedDates.length || !selectedGroups.length || !selectedColumn.length}
                                        loading={isBenchmarkLoading}
                                        onClick={() => this.downloadExcel()}
                                    >
                                        Download xlsx
                                    </Button>
                                </div>
                            </Spin>
                        </div>}

                        {activeForm === 5 && customFormula.isResultVisible && <div>
                            <Table bordered dataSource={tableData} scroll={{x: true}} columns={tableColumns}
                                   loading={isBenchmarkLoading || customFormula.isFetchingResult}/>
                        </div>}

                    </div>
                </div>
            </>
        );
    }
}

export default Benchmark
