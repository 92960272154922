import { connect } from "react-redux";

import actions from '../../../../../../store/actions';
import TabUpdateFile from "./TabUpdateFile";

const mapStateToProps = (state) => ({
  user: state.user.data,
});

const mapDispatchToProps = (dispatch) => ({
  updateFile: (params) => dispatch(actions.fileManager.updateFile(params)),
  getFolderFiles: (params) => dispatch(actions.fileManager.getFolderFiles(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabUpdateFile);
