import { connect } from 'react-redux'

import Benchmark from './Benchmark'
import actions from '../../store/actions'

const mapStateToProps = state => ({
  isFetchingOrganisations: state.user.isFetchingOrganisations,
  isFetchingGetBenchmark: state.benchmark.isFetching,
  dataOrganisations: state.user.data,
  benchmark: state.benchmark.benchmark,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  getOrganisations: () => dispatch(actions.organisations.getOrganisations()),
  getBenchmark: (params) => dispatch(actions.benchmark.getBenchmark(params)),
  getBenchmarkList: (params) => dispatch(actions.benchmark.getBenchmarkList(params)),
  getBenchmarkData: (params) => dispatch(actions.benchmark.getBenchmarkData(params)),
  getBenchmarkDataOrg: (params) => dispatch(actions.benchmark.getBenchmarkDataOrg(params)),
  getBenchmarkDataCategory: (params) => dispatch(actions.benchmark.getBenchmarkDataCategory(params)),
  getBenchmarkDataFromExpression: (params) => dispatch(actions.benchmark.getBenchmarkDataFromExpression(params)),
  getBenchmarkOrgDataFromExpression: (params) => dispatch(actions.benchmark.getBenchmarkOrgDataFromExpression(params)),
  getBenchmarkDataForIndicator: (params) => dispatch(actions.benchmark.getBenchmarkDataForIndicator(params)),
  getBenchmarkDataForCategory: (params) => dispatch(actions.benchmark.getBenchmarkDataForCategory(params)),
  getBenchmarkOrgDataForIndicator: (params) => dispatch(actions.benchmark.getBenchmarkOrgDataForIndicator(params)),
  getBenchmarkOrgDataForCategory: (params) => dispatch(actions.benchmark.getBenchmarkOrgDataForCategory(params)),
  getBenchmarkDrillDown: (params) => dispatch(actions.benchmark.getBenchmarkDrillDown(params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Benchmark)
