import { connect } from 'react-redux';
import FavoritesGrid from './FavoritesGrid';
import actions from "../../../../store/actions";

const mapStateToProps = (state) => ({
  settingsBenchmark: state.benchmark.benchmarkSettings,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  getBenchmarkValuesForIndicator: (params) => dispatch(actions.benchmark.getBenchmarkValuesForIndicator(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesGrid);
